import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ProductFAQ = () => {
    const faqData = [
        { question: "What is Auto Article?", answer: "Auto Article is a cutting-edge platform designed to revolutionize content creation for WordPress websites. By leveraging the power of AI, it allows users to automatically generate high-quality, unique articles and directly publish them to their WordPress sites. The platform is built to streamline content management, making it more efficient and less time-consuming." },
        { question: "Who is Auto Article for?", answer: "Auto Article is ideal for bloggers, digital marketers, content creators, and business owners who use WordPress for their websites. It's especially useful for those looking to save time on content creation, enhance their content strategy with AI-powered articles, and maintain a consistent online presence with minimal effort." },
        { question: "How does the WordPress API Integration work with an existing WordPress site?", answer: " Integrating Auto Article with your existing WordPress site is a breeze. Once you connect your WordPress site via API keys, you can seamlessly manage and publish content directly from Auto Article. This integration allows for real-time synchronization, making it easy to control your content workflow and automate publishing without any technical hassles." },
        { question: "What kind of article content can Auto Article's AI algorithms generate?", answer: "Our AI algorithms are capable of generating a wide variety of article content, ranging from blog posts to news articles and more. The content is tailored to your specific requirements, ensuring originality, engagement, and relevance to your target audience. Our advanced algorithms ensure the content is not only high-quality but also unique and SEO-friendly." },
        { question: "How does the article image integration feature source relevant images?", answer: "Auto Article's image integration feature uses advanced algorithms to source and incorporate relevant, high-quality images that complement your article content. This automated process saves you the time and effort of manually searching for suitable images, ensuring that each article is visually appealing and engaging to your readers." },
        { question: "Can I schedule multiple articles to be published at different times?", answer: "Absolutely! With our scheduled publishing feature, you can plan and schedule multiple articles to be published at your desired times. This allows for consistent content delivery, enabling you to maintain an active online presence and engage your audience regularly, even when you're not actively managing your site" },
        { question: "What is the best WordPress article creation software?", answer: "Auto Article stands out as one of the best WordPress article creation software options available today. It combines ease of use, AI-driven content creation, seamless WordPress integration, and a range of powerful features to offer a comprehensive solution for automated content management and publishing." },
        { question: "How much does Auto Article cost?", answer: "Auto Article is free to use alongside various pricing plans to suit different needs and budgets. Our plans are designed to provide flexibility and value, whether you're an individual blogger or a large enterprise. For detailed pricing information and to find the plan that's right for you, please visit our pricing page" },
    ];

    return (
        <div>
          <div style={{ paddingBottom: '40px' }}>
            <Typography variant='customFont'>Frequently Asked Questions</Typography>
          </div>
      
          <div>
            {faqData.map((faq, index) => (
              <Accordion key={index}
                sx={{ 
                  boxShadow: 'none',
                  paddingTop: '15px',
                  paddingBottom: '15px',
                  borderTop: index === 0 ? 'none' : '1px solid #ccc', // No top border for first item
                  borderBottom: index === faqData.length - 1 ? 'none' : '1px solid #ccc', // No bottom border for last item
                  borderLeft: 'none', // Remove left border
                  borderRight: 'none', // Remove right border
                  '&:before': {
                    display: 'none',
                  },
                  '&.Mui-expanded': {
                    margin: 'auto',
                  },
                }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}a-content`}
                  id={`panel${index}a-header`}
                >
                  <Typography>{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant='subtitle1'>{faq.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      );
};

export default ProductFAQ;