import { createContext, useReducer } from "react";

// contex to render the updated data without refresh
// which means all components now have an access to the AI Sitecontext
export const AiConfigsContext = createContext();

export const aiconfigReducer = (state, action) => {
  switch (action.type) {
    case "SET_AICONFIG":
      return {
        aiconfigs: action.payload,
      };
    case "CREATE_AICONFIG":
      return {
        aiconfigs: [action.payload, ...state.aiconfigs],
      };
    case "UPDATE_AICONFIG":
      return {
        aiconfigs: state.aiconfigs.map((aiconfig) =>
          aiconfig._id === action.payload._id ? action.payload : aiconfig
        ),
      };
    case "DELETE_AICONFIG":
      return {
        aiconfigs: state.aiconfigs.filter(
          (aiconfig) => aiconfig._id !== action.payload._id
        ),
      };
    default:
      return state;
  }
};

export const AiConfigsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(aiconfigReducer, {
    aiconfigs: null,
  });
  return (
    <AiConfigsContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AiConfigsContext.Provider>
  );
};