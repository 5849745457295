import { React, useEffect } from "react";
import { useAiConfigContext } from "../hooks/useContext/useAiConfigContext";
import { useAuthContext } from "../hooks/useContext/useAuthContext";
import AiConfigDetails from "../components/AI_Configuration_Page/aiconfigDetails";
import AiConfigForm from "../components/AI_Configuration_Page/AiConfigForm";
import DashboardNavBar from "../components/NavBar/DashboardNavBar";
import Sidebar from "../components/Shared/Sidebar";
import Footer from "../components/Shared/Footer";
import { Grid, Box } from "@mui/material";
// import { Modal, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";


const GridContainerMain = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.common.background,
  paddingLeft: "140px",
  paddingRight: "80px",
  paddingTop: "70px",
  width: "100%",
  minHeight: "100vh",
  "@media (max-width: 960px)": {
    paddingLeft: "80px",
    paddingRight: "15px",
    paddingTop: "35px",
  },
}));

const AiConfigPage = () => {
  const { aiconfigs, dispatch } = useAiConfigContext();
  const { user } = useAuthContext();
  
  useEffect(() => {
    const fetchAiConfigs = async () => {

      const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/aiconfigs", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      const json = await response.json();
      if (response.ok) {
        dispatch({ type: "SET_AICONFIG", payload: json });
      } else {
        window.location.reload();
      }
    };

    if (user) {
      fetchAiConfigs();
    }
  }, [dispatch, user]);

  return (
    <div>
      <DashboardNavBar />
      <Sidebar />

      <GridContainerMain container spacing={{xs:3, sm:3, md:3, lg:4, xl:6}}>
        <Grid item xs={12} sm={12} md={12} lg={7} xl={8}>
          <Grid container spacing={3}>
            {aiconfigs &&
              aiconfigs.map((aiconfig) => (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={4} key={aiconfig._id}>
                  <AiConfigDetails key={aiconfig._id} aiconfig={aiconfig} />
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5} xl={4}>
          <AiConfigForm />
        </Grid>
      </GridContainerMain>

      <Box style={{ paddingLeft: "64px" }}>
        <Footer />
      </Box>

    </div>
  );
};

export default AiConfigPage;