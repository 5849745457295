import React, { useState, useEffect } from "react";
import { useNewBlogArticle } from "../hooks/useNewBlogArticle";
import { useAuthContext } from "../hooks/useContext/useAuthContext";
import BlogBox from "../components/Article_Display_Page/BlogBox";
import Footer from "../components/Shared/Footer";
import Navbar from "../components/NavBar/Navbar";
import ErrorBox from "../components/Shared/ErrorBox";
import { styled } from "@mui/system";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ArticleIcon from "@mui/icons-material/Article";
import {
  Avatar,
  Button,
  TextField,
  Paper,
  Grid,
  Typography,
  CircularProgress,
  Tooltip,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Input,
} from "@mui/material";

const InputContainer = styled(Input)({
  padding: "6px 12px",
  margin: ".8rem",
});

const RootContainer = styled(Grid)(({ theme }) => ({
  minHeight: "100vh",
  height: "100%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundColor: theme.palette.common.white,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const SizeContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
  border: `1px solid ${theme.palette.component.borderColor}`,
  "@media (max-width: 900px)": {
    border: "none",
    borderRadius: "none",
    boxShadow: "none",
  },
}));

const PaperContainer = styled("div")({
  margin: "136px 48px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const AdminOptions = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: "1rem",
  marginBottom: "1rem",
});

const CustomButton = styled(Button)({
  margin: "10px",
});

const StyledForm = styled("form")({
  width: "100%", // Fix IE 11 issue.
  marginTop: "8px",
});

const CenterContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: "0px",
  backgroundColor: theme.palette.secondary.main,
}));

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const NewBlogArticle = () => {
  const [blogTitle, setBlogTitle] = useState("");
  const [blogAuthor, setBlogAuthor] = useState("");
  const [blogContent, setBlogContent] = useState("");
  const [blogPreview, setBlogPreview] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [isLoadingReset, setIsLoadingReset] = useState(false);
  const { submitNewArticle, error, isLoading } = useNewBlogArticle();
  const { user } = useAuthContext();
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [emptyFields, setEmptyFields] = useState(null);
  const [frontEndError, setFrontEndError] = useState(null);
  const [listOfBlogArticles, setListOfBlogArticles] = useState([]);
  const [articleID, setArticleID] = useState("");
  const [fileNameDisplay, setFileNameDisplay] = useState("Image File*");
  const [articleType, setArticleType] = useState("Blog");
  const articleTypeOptions = [
    "Blog",
    "Legal",
    "Terms and Conditions",
    "Privacy Policy",
    "Documentation",
    "About Us",
    "Contact Us",
    "User Manual",
    "Acceptable Use Policy",
  ];

  const fetchBlogArticles = async () => {
    const response = await fetch(
      process.env.REACT_APP_SERVER_URL + "/api/blog",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const json = await response.json();
    if (response.ok) {
      setListOfBlogArticles(json);
    }
  };

  useEffect(() => {
    fetchBlogArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //check if logged in user is admin
  useEffect(() => {
    const fetchUserConfigs = async () => {
      const response = await fetch(
        process.env.REACT_APP_SERVER_URL + "/api/user/status/",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();
      if (response.ok) {
        if (json.isAdmin === true) {
          setUserIsAdmin(true);
        } else {
          setUserIsAdmin(false);
          window.location.href = "/blog";
        }
      }
    };

    fetchUserConfigs();
  }, [user]);

  const removeArticleID = () => {
    setEmptyFields(null);
    setArticleID("");
    setImageFile(null);
    if (articleType !== "Blog") {
      setFileNameDisplay("");
    } else {
      setFileNameDisplay("Image File*");
    }
  };

  //submit new article
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingReset(true);
    setEmptyFields(null);
    setFrontEndError(null);

    let emptyFieldsClientSide = [];
    if (!articleType) {
      emptyFieldsClientSide.push("articleType");
    }
    if (!blogTitle) {
      emptyFieldsClientSide.push("blogTitle");
    }
    if (!blogAuthor) {
      emptyFieldsClientSide.push("blogAuthor");
    }
    if (!blogPreview) {
      emptyFieldsClientSide.push("blogPreview");
    }
    if (!blogContent) {
      emptyFieldsClientSide.push("blogContent");
    }
    if (articleType === "Blog" && !imageFile) {
      emptyFieldsClientSide.push("imageFile");
    }
    if (emptyFieldsClientSide.length > 0) {
      setEmptyFields(emptyFieldsClientSide);
      setFrontEndError("Please fill in all fields");
      setIsLoadingReset(false);
      return;
    }
    await submitNewArticle({
      blogTitle,
      blogAuthor,
      blogPreview,
      blogContent,
      imageFile,
      articleID,
      articleType,
    });
    setIsLoadingReset(false);
    removeArticleID();
    setFileNameDisplay("Image File*");
    fetchBlogArticles();
  };

  //reset fields on success
  useEffect(() => {
    if (error === "success") {
      setBlogTitle("");
      setBlogAuthor("");
      setBlogContent("");
      setBlogPreview("");
      setArticleType("Blog");
      setImageFile(null);
      setEmptyFields(null);
      setFrontEndError(null);
    }
  }, [error]);

  //edit article
  const editArticle = (article) => async (e) => {
    setEmptyFields(null);
    setBlogTitle(article.blogTitle);
    setBlogAuthor(article.blogAuthor);
    setBlogPreview(article.blogPreview);
    setBlogContent(article.blogContent);
    setImageFile(
      article.articleType === "Blog" ? article.blogCoverImage : null
    );
    setArticleID(article._id);
    setArticleType(article.articleType);
    setFileNameDisplay(
      article.articleType === "Blog" ? article.blogCoverImage : null
    );
  };

  //delete article
  const deleteArticle = (article) => async (e) => {
    const response = await fetch(
      process.env.REACT_APP_SERVER_URL + "/api/blog/" + article._id,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const json = await response.json();
    if (response.ok) {
      fetchBlogArticles();
    } else {
      console.log(json);
    }
  };

  return (
    <div>
      <Navbar />
      <RootContainer container component="main">
        {userIsAdmin ? (
          <>
            <SizeContainer
              item
              xs={12}
              sm={8}
              md={8}
              lg={8}
              xl={8}
              component={Paper}
              elevation={1}
              square
            >
              <PaperContainer>
                <Button onClick={removeArticleID}>
                  <StyledAvatar>
                    <Tooltip
                      title={articleID ? "Remove ID" : "Remove Photo Upload"}
                      placement="top"
                    >
                      <ArticleIcon />
                    </Tooltip>
                  </StyledAvatar>
                </Button>

                <Typography component="h1" variant="h5">
                  {articleID
                    ? "Editing article ID: " + articleID
                    : "New Article"}
                </Typography>

                <StyledForm onSubmit={handleSubmit} noValidate>
                  <FormControl
                    required={true}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                  >
                    <InputLabel id="article-type-label">
                      Article Type
                    </InputLabel>
                    <Select
                      onChange={(checkIfPhotoUploadRequired) => {
                        setArticleType(checkIfPhotoUploadRequired.target.value);
                        if (
                          checkIfPhotoUploadRequired.target.value === "Blog"
                        ) {
                          setFileNameDisplay("Image File*");
                        } else {
                          setImageFile(null);
                          setFileNameDisplay("");
                        }
                      }}
                      required
                      id="articleType"
                      labelId="article-type-label"
                      disabled={articleID ? true : false}
                      value={articleType}
                      label="Article Type"
                      error={emptyFields && emptyFields.includes("articleType")}
                    >
                      <MenuItem value="">
                        <em>Article Type:</em>
                      </MenuItem>
                      {articleTypeOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {articleType === "Blog" && (
                    <InputContainer
                      type="file"
                      error={emptyFields && emptyFields.includes("imageFile")}
                      onChange={(event) => {
                        const file = event.target.files[0];
                        if (file) {
                          setImageFile(file);
                          setFileNameDisplay(file.name);
                        }
                      }}
                    />
                  )}

                  {fileNameDisplay && (
                    <Typography
                      component="h2"
                      sx={{
                        color: "#6b6b6b",
                        border: 1,
                        borderColor: "#b3b3b3",
                        borderRadius: "4px",
                        padding: ".8rem",
                      }}
                    >
                      {fileNameDisplay}
                    </Typography>
                  )}

                  <TextField
                    onChange={(event) => setBlogTitle(event.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="title"
                    label="Blog Title"
                    name="title"
                    autoFocus
                    value={blogTitle}
                    error={emptyFields && emptyFields.includes("blogTitle")}
                  />

                  <TextField
                    onChange={(event) => setBlogAuthor(event.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="author"
                    label="Author"
                    name="author"
                    autoFocus
                    value={blogAuthor}
                    error={emptyFields && emptyFields.includes("blogAuthor")}
                  />

                  <TextField
                    onChange={(event) => setBlogPreview(event.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="preview"
                    label="Preview"
                    name="preview"
                    autoFocus
                    value={blogPreview}
                    error={emptyFields && emptyFields.includes("blogPreview")}
                  />

                  <ReactQuill
                    theme="snow"
                    id="content"
                    label="Content"
                    name="content"
                    value={blogContent}
                    onChange={setBlogContent}
                    formats={formats}
                    modules={modules}
                    error={emptyFields && emptyFields.includes("blogContent")}
                  />

                  {isLoadingReset ? (
                    <CenterContainer>
                      <CircularProgress />
                    </CenterContainer>
                  ) : (
                    <></>
                  )}

                  {(frontEndError || error) && (
                    <ErrorBox
                      error={frontEndError || error}
                      color={error === "success" ? "success" : "error"}
                    />
                  )}

                  <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                    <Button
                      sx={{
                        borderRadius: "50px",
                        height: "45px",
                        backgroundColor: "#4829B2",
                        ":hover": { bgcolor: "#321d7c" },
                      }}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={isLoading}
                    >
                      {articleID ? "submit edit" : "submit new article"}
                    </Button>
                  </div>
                </StyledForm>
              </PaperContainer>
            </SizeContainer>
            <Grid container>
              {listOfBlogArticles.map((article, index) => (
                <Grid item xs={6} md={6} lg={4} key={index}>
                  <BlogBox key={index} article={article} />
                  <Typography
                    component="h2"
                    sx={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "20px",
                      marginBottom: "20px",
                      "@media (max-width: 960px)": {
                        width: "100%",
                      },
                    }}
                  >
                    {" "}
                    <b>Article Type:</b> <br /> {article.articleType}{" "}
                  </Typography>
                  <AdminOptions>
                    <CustomButton
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "#4829B2",
                        ":hover": { bgcolor: "#321d7c" },
                      }}
                      variant="contained"
                      onClick={editArticle(article)}
                    >
                      Edit
                    </CustomButton>
                    <CustomButton
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "#4829B2",
                        ":hover": { bgcolor: "#321d7c" },
                      }}
                      variant="contained"
                      onClick={deleteArticle(article)}
                    >
                      Delete
                    </CustomButton>
                  </AdminOptions>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <div>Not authorized</div>
        )}
      </RootContainer>
      <Footer />
    </div>
  );
};

export default NewBlogArticle;
