import { AiConfigsContext } from "../../context/AiConfigContext";
import { useContext } from "react"

export const useAiConfigContext = () => {
  const context = useContext(AiConfigsContext)

  if(!context) {
    throw Error('useAiConfigsContext must be used inside an AiConfigsContextProvider')
  }

  return context
}