import { useState } from "react";

export const useResendEmail = () => {
  const [error, setError] = useState(null);

  const resendEmail = async (email) => {
    try {
      
      const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/user/resendSignup", {
        method: "POST",
        body: JSON.stringify({ email }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();
      if (!response.ok) {
        setError(json.error);
      }
      if (response.ok) {
        setError(json.message);
      }
    } catch (error) {
      setError("An error occurred, please try again or contact support");
    }
  };
  return { resendEmail, error };
};
