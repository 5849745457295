import { createTheme, responsiveFontSizes } from "@mui/material";

// colors
const primary = "#4829B2";
const primarylight = "#5A3EB9";
const primarylighter = "#a798d9";
const primarydark = "#321d7c";
const secondary = "#B3294E";
const black = "#000000";
const offBlack = "#414141";
const grey = "#2F2F2F";
const offGrey = "#6b6a6a";
const white = "#ffffff";
const offWhite = "#efedfa";
const componentBackground = "#f0f0f0";
const background = "#f1f1f1";
const borderColor = "#d3d4d5";
const darkBorderColor = '#ababab';
const LinearProgressText = "#738473";
const drawer = "#343a40";
const drawerHover = "#30353b";
const error = "#d2302c";
const success = "#228B22";

const theme = createTheme({
  palette: {
    primary: {
      main: primary,
      light: primarylight,
      lighter: primarylighter,
      dark: primarydark,
    },
    secondary: { main: secondary },
    common: {
      black,
      offBlack,
      grey,
      offGrey,
      white,
      offWhite,
      background,
      error,
      success,
    },
    component: {
      LinearProgressText,
      drawer,
      drawerHover,
      borderColor,
      darkBorderColor,
      componentBackground,
    }
  },

  typography: {
    fontFamily: [
      '"Inter"',
      '"system-ui"',
      '"Helvetica Neue"',
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    h1: {
      color: offBlack,
      fontSize: "2.125rem",
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h2: {
      color: offBlack,
      fontSize: "1.875rem",
      fontWeight: 700,
      lineHeight: 1.2,
    },
    //feature section box titles
    h3: {
      color: offBlack,
      fontSize: "1.5rem",
      fontWeight: 500,
      lineHeight: 1.2,
    },
    //slogan (Experience Next-Gen Blogging: AI-Enabled WordPress Posts)
    h4: {
      color: offBlack,
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: 1.2,
    },
    // Get Started Free arrow button
    h5: {
      color: offGrey,
      fontSize: "1.125rem",
      fontWeight: 700,
      lineHeight: 1.2,
    },
    //none
    h6: {
      color: offBlack,
      fontSize: "1rem",
      fontWeight: 700,
      lineHeight: 1.2,
    },

    p: {
      color: grey,
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.4,
      letterSpacing: "0",
    },
    //LogoText
    subtitle1: {
      color: offGrey,
      fontSize: ".9rem",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0",
    },
    body2: {
      color: offBlack,
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.4,
      letterSpacing: "0",
    },
    //purple background button
    button1: {
      color: white,
      fontSize: "1.1rem",
    },
    //learn more button
    button: {
      color: primary,
      fontSize: ".9rem",
      "&:hover": { cursor: "pointer" },
      textDecoration: "underline secondary",
      textTransform: "none",
    },
    //Hero Text (AI-Powered WordPress Ghost Writer)
    customFont: {
      color: offBlack,
      fontSize: "4.5rem",
      fontWeight: 900,
      lineHeight: 1.3,
      "@media (max-width: 2000px)": {
        fontSize: "3.5rem",
      },
      "@media (max-width: 1400px)": {
        fontSize: "3.3rem",
      },
      "@media (max-width: 900px)": {
        fontSize: "3rem",
      },
    },
  },

  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "primaryButton" },
          style: {
            border: `1px solid ${primary}`,
            borderRadius: "30px",
            backgroundColor: primary,
            borderColor: primary,
            color: white,
            fontSize: "1rem",
            textTransform: "none",
            "&:hover": {
              borderColor: primarydark,
              backgroundColor: primarydark,
            },
          },
        },
        {
          props: { variant: "secondaryButton" },
          style: {
            border: `1px solid ${primary}`,
            borderRadius: "30px",
            backgroundColor: white,
            color: primary,
            fontSize: "1rem",
            textTransform: "none",
            "&:hover": {
              borderColor: primarydark,
              backgroundColor: offWhite,
            },
          },
        },
      ],
    },
  },

  //   components: {
  //     heroButton: {
  //         variant: "outlined",
  //         borderRadius: '30px',
  //         backgroundColor: primary,
  //         borderColor: primary,
  //         color: white,
  //         fontSize: "1.1rem",
  //         textTransform: 'none',
  //         // mr: mobileView ? 0 : 2,
  //         // height: mobileView ? '60px' : null,
  //         // marginTop: mobileView ? '10px' : '0px',
  //         ':hover': {
  //           borderColor: primarydark,
  //           backgroundColor: primarydark,
  //         },
  //       },

  //     },
});

export default responsiveFontSizes(theme);

// const black = "#343a40";
// const darkBlack = "rgb(36, 40, 44)";
// const background = "#f5f5f5";
// const warningLight = "rgba(253, 200, 69, .3)";
// const warningMain = "rgba(253, 200, 69, .5)";
// const warningDark = "rgba(253, 200, 69, .7)";

// border
// const borderWidth = 2;
// const borderColor = "rgba(0, 0, 0, 0.13)";

// breakpoints
// const xl = 1920;
// const lg = 1280;
// const md = 960;
// const sm = 600;
// const xs = 0;

// // spacing
// const spacing = 8;

// warning: {
//   light: warningLight,
//   main: warningMain,
//   dark: warningDark
// },
// Used to shift a color's luminance by approximately
// two indexes within its tonal palette.
// E.g., shift from Red 500 to Red 300 or Red 700.
// tonalOffset: 0.2,
// background: {
//   default: background
// },
// spacing

//   breakpoints: {
// Define custom breakpoint values.
// These will apply to Material-UI components that use responsive
// breakpoints, such as `Grid` and `Hidden`. You can also use the
// theme breakpoint functions `up`, `down`, and `between` to create
// media queries for these breakpoints
//     values: {
//       xl,
//       lg,
//       md,
//       sm,
//       xs
//     }
//   },
//   border: {
//     borderColor: borderColor,
//     borderWidth: borderWidth
//   },
//   overrides: {
// MuiExpansionPanel: {
//   root: {
//     position: "static"
//   }
// },
// MuiTableCell: {
//   root: {
//     paddingLeft: spacing * 2,
//     paddingRight: spacing * 2,
//     borderBottom: `${borderWidth}px solid ${borderColor}`,
//     [`@media (max-width:  ${sm}px)`]: {
//       paddingLeft: spacing,
//       paddingRight: spacing
//     }
//   }
// },
// MuiDivider: {
//   root: {
//     backgroundColor: borderColor,
//     height: borderWidth
//   }
// },
// MuiPrivateNotchedOutline: {
//   root: {
//     borderWidth: borderWidth
//   }
// },
// MuiListItem: {
//   divider: {
//     borderBottom: `${borderWidth}px solid ${borderColor}`
//   }
// },
// MuiDialog: {
//   paper: {
//     width: "100%",
//     maxWidth: 430,
//     marginLeft: spacing,
//     marginRight: spacing
//   }
// },
// MuiTooltip: {
//   tooltip: {
//     backgroundColor: darkBlack
//   }
// },
// MuiExpansionPanelDetails: {
//   root: {
//     [`@media (max-width:  ${sm}px)`]: {
//       paddingLeft: spacing,
//       paddingRight: spacing
//     }
//   }
// }
//   },

// p: {
//   color: black,
//   fontSize: '.9rem',
//   fontWeight: 400,
//   lineHeight: 1.8,
// },

// main hero text (AI-Powered WordPress Ghost Writer)

// letterSpacing: '0',
// "@media (max-width: 2000px)": {
//   fontSize: "3.5rem",
// },
// "@media (max-width: 1400px)": {
//   fontSize: "2.2rem",
// },
// "@media (max-width: 900px)": {
//   fontSize: "2rem",
// },

// h1: {
//   color: offBlack,
//   fontSize: '4.5rem',
//   fontWeight: 700,
//   lineHeight: 1.5,
//   "@media (max-width: 2000px)": {
//     fontSize: "3.5rem",
//   },
//   "@media (max-width: 1400px)": {
//     fontSize: "2.2rem",
//   },
//   "@media (max-width: 900px)": {
//     fontSize: "2rem",
//   },
// },

// Our Solution For Your Business

// h2: {
//   color: offBlack,
//   fontSize: '2rem',
//   fontWeight: 700,
//   lineHeight: 1.5,
//   "@media (max-width: 2000px)": {
//     fontSize: "2rem",
//   },
//   "@media (max-width: 1400px)": {
//     fontSize: "1.5rem",
//   },
//   "@media (max-width: 900px)": {
//     fontSize: "1.2rem",
//   },
// },

// h3: {
//   color: offBlack,
//   fontSize: '1rem',
//   fontWeight: 500,
//   lineHeight: 1.5,
//   "@media (max-width: 1400px)": {
//     fontSize: ".9rem",
//   },
//   "@media (max-width: 900px)": {
//     fontSize: ".9rem",
//   },
// },

//40-hour work week in 20 minutes
// body1: {
//   color: black,
//   fontSize: '.9rem',
//   fontWeight: 700,
//   // lineHeight: 1.8,
// },

//feature section box section descriptions
// body2: {
//   color: grey,
//   fontSize: '.9rem',
//   "@media (max-width: 1400px)": {
//     fontSize: ".7rem",
//   },
//   "@media (max-width: 900px)": {
//     fontSize: ".7rem",
//   },
// },

// "@media (max-width: 1400px)": {
//   fontSize: ".9rem",
// },
// "@media (max-width: 900px)": {
//   fontSize: ".9rem",
// },

// h4: {
//     color: black,
//     fontSize: '1.2rem',
//   "@media (max-width: 1400px)": {
//     fontSize: "1rem",
//   },
//   "@media (max-width: 900px)": {
//     fontSize: "1rem",
//   },
// },

// h5: {
//   color: grey,
//   fontSize: "1rem",
//   fontWeight: 700,
//   "@media (max-width: 1400px)": {
//     fontSize: ".9rem",
//   },
//   "@media (max-width: 900px)": {
//     fontSize: ".9rem",
//   },
// },
// ... you can also customize other variants like subtitle1, subtitle2, button, caption, overline

//   const SloganClass = styled(Typography)({
//     position: "relative",
//     zIndex: "2",
//     paddingTop: "10%",
//     left: "0%",
//     width: '100%',
//     fontFamily: 'DM Sans, sans-serif',
//     fontSize: '1.2rem',
//     color: '#000000',
//     "@media (max-width: 1400px)": {
//       fontSize: "1rem",
//     },
//     "@media (max-width: 900px)": {
//       width: '100%',
//       height: 'auto',
//       fontSize: "1rem",
//     },
//   });

//   const ArrowText = styled(Typography)({
//     display: "flex",
//     alignItems: "center",
//     position: "relative",
//     paddingTop: "5%",
//     left: "5%",
//     width: "100%",
//     fontSize: "1rem",
//     fontFamily: 'DM Sans, sans-serif',
//     fontWeight: 'bold',
//     color: '#8f8f8f',
//     "@media (max-width: 1400px)": {
//       width: "100%",
//       fontSize: ".9rem",
//     },
//     "@media (max-width: 900px)": {
//       width: '100%',
//       height: 'auto',
//       fontSize: ".9rem",
//     },
//   });

//   const CustomButton = styled(Button)({
//     variant: "outlined",
//     borderRadius: '30px',
//     backgroundColor: '#4829B2',
//     borderColor: '#4829B2',
//     color: '#ffffff',
//     ':hover': {
//       borderColor: '#5A3EB9',
//       backgroundColor: '#5A3EB9',
//     },
//     width: "40%",
//     zIndex: "2",
//     height: "4rem",
//     marginTop: '60px',
//     "@media (max-width: 1400px)": {
//       width: "50%",
//     },
//     "@media (max-width: 900px)": {
//       height: "3rem",
//       marginTop: "30px",
//       width: "50%",
//     },
//   });
