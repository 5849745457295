import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../components/NavBar/Navbar";
import Footer from "../components/Shared/Footer";
import ErrorBox from "../components/Shared/ErrorBox";
import {
  Paper,
  Box,
  Grid,
  Link,
  Typography,
  Button,
  TextField,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { styled } from "@mui/system";

const RootContainer = styled(Grid)(({ theme }) => ({
  minHeight: "100vh",
  height: "100%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundColor: theme.palette.common.white,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const SizeContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
  border: `1px solid ${theme.palette.component.borderColor}`,
  "@media (max-width: 900px)": {
    border: "none",
    borderRadius: "none",
    boxShadow: "none",
  },
}));

const PaperContainer = styled("div")({
  margin: "16px 48px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledForm = styled("form")({
  width: "100%", // Fix IE 11 issue.
  marginTop: "8px",
});

const LogoImage = styled("img")({
  width: "70px",
  height: "70px",
  marginBottom: "10px",
  marginTop: "10px",
});

const CenterContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const ResetPassword = () => {
  const [verificationMessage, setVerificationMessage] = useState("");
  let { resetPasswordToken } = useParams();
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoadingReset, setIsLoadingReset] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const verifyPasswordToken = async (resetPasswordToken, password, error) => {
    const response = await fetch(
      process.env.REACT_APP_SERVER_URL + "/api/user/confirmPassword",
      {
        method: "POST",
        body: JSON.stringify({ resetPasswordToken, password, error }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();

    if (json.error === "User not found") {
      setConfirmed(false);
      setIsDisabled(true);
      setVerificationMessage("User not found");
    } else if (json.message === "User found") {
      setConfirmed(true);
      setIsDisabled(false);
      setVerificationMessage("Enter New Password");
    } else if (json.error === "Password is not strong enough") {
      setConfirmed(true);
      setIsDisabled(false);
      setPasswordError("Password is not strong enough");
    } else if (json.error === "Error changing Password") {
      setConfirmed(false);
      setIsDisabled(true);
      setVerificationMessage(
        "Error changing password, try again later or contact support"
      );
    } else if (json.message === "Successfully Changed Password") {
      setPasswordError(
        "Successfully Changed Password, redirecting to login page"
      );
      setTimeout(() => {
        window.location.href = "/login";
      }, 2500);
    } else {
      setConfirmed(false);
      setIsDisabled(true);
      setVerificationMessage(
        "Error changing password, try again later or contact support"
      );
    }
  };

  useEffect(() => {
    if (resetPasswordToken) {
      verifyPasswordToken(resetPasswordToken);
    }
  }, [resetPasswordToken]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingReset(true);
    setPasswordError("");
    if (password !== repeatPassword) {
      setPasswordError("Passwords do not match");
      setIsLoadingReset(false);
      return;
    }
    await verifyPasswordToken(resetPasswordToken, password);
    setIsLoadingReset(false);
  };

  return (
    <div>
      <Navbar />
      <RootContainer container component="main">
        <SizeContainer
          item
          xs={12}
          sm={8}
          md={5}
          lg={3}
          xl={3}
          component={Paper}
          elevation={1}
          square
        >
          <PaperContainer>
            <LogoImage
              src={
                process.env.REACT_APP_CLIENT_URL +
                "/images/AutoArticle_Logo.png"
              }
              alt="Logo"
            />
            <br />
            <div>
              <Typography component="h1" variant="h5">
                <span style={{ display: "flex", justifyContent: "center" }}>
                  Reset Password
                </span>
              </Typography>
              <Typography component="h5" variant="subtitle1">
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "1.5rem",
                  }}
                >
                  {verificationMessage}
                </span>
              </Typography>

              {confirmed ? (
                <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                  <StyledForm onSubmit={handleSubmit}>
                    <TextField
                      onChange={(event) => setPassword(event.target.value)}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type={showPassword1 ? "text" : "password"}
                      id="password"
                      autoComplete="current-password"
                      value={password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword1}
                            >
                              {showPassword1 ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      onChange={(event) =>
                        setRepeatPassword(event.target.value)
                      }
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="repeatPassword"
                      label="Repeat Password"
                      type={showPassword2 ? "text" : "password"}
                      id="repeatPassword"
                      autoComplete="current-password"
                      value={repeatPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword2}
                            >
                              {showPassword2 ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {isLoadingReset ? (
                      <CenterContainer>
                        <CircularProgress />
                      </CenterContainer>
                    ) : (
                      <></>
                    )}
                    {passwordError && (
                      <ErrorBox
                        error={passwordError}
                        color={
                          passwordError ===
                          "Successfully Changed Password, redirecting to login page"
                            ? "success"
                            : "error"
                        }
                      />
                    )}
                    <Button
                      sx={{
                        borderRadius: "50px",
                        marginTop: "15px",
                        height: "45px",
                        backgroundColor: "#4829B2",
                        ":hover": { bgcolor: "#321d7c" },
                      }}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={isDisabled}
                    >
                      Change Password
                    </Button>
                  </StyledForm>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <Box mt={5}>
              <Typography variant="body2" color="textSecondary" align="center">
                {"Copyright © "}
                <Link color="inherit">AutoArticle</Link>{" "}
                {new Date().getFullYear()}
                {"."}
              </Typography>
            </Box>
          </PaperContainer>
        </SizeContainer>
      </RootContainer>
      <Footer />
    </div>
  );
};

export default ResetPassword;
