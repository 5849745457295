import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Paper, Box, Grid, Link, Typography, Button, TextField, CircularProgress } from '@mui/material';
import { styled } from "@mui/system";
import Navbar from "../components/NavBar/Navbar";
import Footer from "../components/Shared/Footer";
import ErrorBox from "../components/Shared/ErrorBox";

const RootContainer = styled(Grid)(({theme}) => ({
  minHeight: "100vh",
  height: "100%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundColor: theme.palette.common.white, 
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const SizeContainer = styled(Grid)(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
  border: `1px solid ${theme.palette.component.borderColor}`,
  "@media (max-width: 900px)": {
    border: 'none',
    borderRadius: "none",
    boxShadow: 'none',
  },
}));

const PaperContainer = styled('div')({
  margin: "16px 48px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledForm = styled("form")({
  width: "100%", // Fix IE 11 issue.
  marginTop: '8px'
});

const LogoImage = styled("img")({
  width: "70px",
  height: "70px",
  marginBottom: "10px",
  marginTop: "10px",
});

const CenterContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const ResetEmail = () => {
  const [verificationMessage, setVerificationMessage] = useState("");
  let { resetEmailToken } = useParams();
  const [email, setEmail] = useState("");
  const [repeatEmail, setRepeatEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoadingReset, setIsLoadingReset] = useState(false);


  const verifyEmailToken = async (resetEmailToken, email, error) => {
    const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/user/confirmEmailChange", {
        method: "POST",
        body: JSON.stringify({ resetEmailToken, email, error }),
        headers: {
            "Content-Type": "application/json",
        },
    });
    const json = await response.json();
    
    if (json.error === "User not found") {
        setConfirmed(false);
        setIsDisabled(true);
        setVerificationMessage("User not found");
    } else if (json.message === "User found") {
        setConfirmed(true);
        setIsDisabled(false);
        setVerificationMessage("Enter New Email");
    }else if (json.error === "Error changing Email") {
        setConfirmed(false);
        setIsDisabled(true);
        setVerificationMessage("Error changing email, try again later or contact support");
    } else if (json.message === "Successfully Changed Email") {
        setEmailError("Successfully Changed email, redirecting to login page");
        setTimeout(() => {
            localStorage.removeItem("user");
            window.location.href = "/login";
        }, 2500);
    }else {
        setConfirmed(false);
        setIsDisabled(true);
        setVerificationMessage("Error changing email, try again later or contact support");
    }
  };
  

  useEffect(() => {
    if (resetEmailToken) {
      verifyEmailToken(resetEmailToken);
    }
    
  }, [resetEmailToken]);
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingReset(true);
    setEmailError("");
    if (email !== repeatEmail) {
        setEmailError("Emails do not match");
        setIsLoadingReset(false);
        return;
    }
    await verifyEmailToken(resetEmailToken, email);
    setIsLoadingReset(false);
  };


  return (
    <div>
      <Navbar />
      <RootContainer container component="main">
        <SizeContainer
          item
          xs={12}
          sm={8}
          md={5}
          lg={3}
          xl={3}
          component={Paper}
          elevation={1}
          square
        >
          <PaperContainer>
          <LogoImage src={process.env.REACT_APP_CLIENT_URL + '/images/AutoArticle_Logo.png'} alt="Logo" />
          <br />
              <div>
              <Typography component="h1" variant="h5">
                  <span style={{display: 'flex', justifyContent: 'center'}}> 
                  Change Email</span>
              </Typography>
              <Typography component="h5" variant="subtitle1">
              <span style={{display: 'flex', justifyContent: 'center', padding: '1.5rem'}}> 
              {verificationMessage}
              </span>
              </Typography>

              {confirmed ? (
              <div style={{ marginTop: '1.5rem', marginBottom: '1.5rem'}}>
                  <StyledForm onSubmit={handleSubmit}>
                      <TextField
                      onChange={(event) => setEmail(event.target.value)}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="email"
                      label="email"
                      type="email"
                      id="email"
                      autoComplete="username"
                      value={email}
                      />
                      <TextField
                      onChange={(event) => setRepeatEmail(event.target.value)}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="repeatEmail"
                      label="Repeat Email"
                      type="email"
                      id="repeatEmail"
                      autoComplete="username"
                      value={repeatEmail}
                      />
                      {isLoadingReset ? (
                        <CenterContainer>
                          <CircularProgress />
                        </CenterContainer>
                      ) : (<></>)}
                      {emailError && (
                        <ErrorBox error={emailError} color={ emailError === "Successfully Changed email, redirecting to login page" ? 'success' : 'error' } />
                      )}
                      <br />
                      <Button
                          sx={{ borderRadius: '50px', marginTop: '15px', height: '45px', backgroundColor: '#4829B2', ':hover': {bgcolor: '#321d7c'}}}
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={isDisabled}
                          >Change Email
                      </Button>
                  </StyledForm>
              </div>
              ) : (
              <div></div>
              )}
              </div>
              <Box mt={5}>
                <Typography variant="body2" color="textSecondary" align="center">
                  {"Copyright © "}
                  <Link color="inherit">
                    AutoArticle
                  </Link>{" "}
                  {new Date().getFullYear()}
                  {"."}
                </Typography>
              </Box>
          </PaperContainer>
        </SizeContainer>
      </RootContainer>
      <Footer />
    </div>
  );
};

export default ResetEmail;