import React, {useEffect, useState} from "react";
import { useParams, Navigate } from "react-router-dom";
import ShareButton from "../components/Article_Display_Page/ShareButton";
import Footer from "../components/Shared/Footer";
import Navbar from "../components/NavBar/Navbar";
import { Grid, Divider, Typography, Breadcrumbs, Link } from "@mui/material";
import { styled } from "@mui/system";

const GridContainerMain = styled("div")({
  padding: '100px 31% 150px 31%',
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  '& img': {
    maxWidth: '100%',
    height: 'auto',
  },
  "@media (max-width: 1600px)": {
    paddingLeft: "20%",
    paddingRight: "20%",
  },
  "@media (max-width: 960px)": {
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: "80px",
  },
  "@media (max-width: 600px)": {
    paddingLeft: "10%",
    paddingRight: "7%",
    paddingTop: "60px",
  },
});

const LogoImage = styled("img")({
  width: "30px",
  height: "30px",
  marginTop: "10px",
  paddingBottom: '10px',
});


const LogoContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: "space-between",
  width: '100%',
  padding: '30px 0 5px 0',
});

const ImageContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  width: "100%",
});
  
const CustomImage = styled("img")(({theme}) => ({
  width: "100%",
  border: `1px solid ${theme.palette.component.borderColor}`,
  borderRadius: "2px",
  marginTop: "20px",
  marginBottom: "20px",
  "@media (max-width: 960px)": {
    width: "100%",
  },
}));

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
  return formattedDate;
}

const SingleBlogArticle = () => {
  let { blogArticleID } = useParams();
  const [blogArticleDetails, setBlogArticleDetails] = useState({});
  let formattedCreatedAt;

  if (blogArticleDetails) {
    formattedCreatedAt = formatDate(blogArticleDetails.createdAt);
  }

  const getArticleFromID = async (blogArticleID) => {
    try {
      const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/blog/" + blogArticleID);
      const articleDetails = await response.json();
      setBlogArticleDetails(articleDetails);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getArticleFromID(blogArticleID);
  }, [blogArticleID]);

  return (
    <div>
    <Navbar />
    { blogArticleDetails ? (
      <>
        <Grid container>
          <GridContainerMain>
            <Grid item xs={12} sm={12} md={12}>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                  Home
                </Link>
                <Link underline="hover" color="inherit" href="/blog">
                  Blog
                </Link>
                <Typography color="text.primary">{blogArticleDetails.blogTitle}</Typography>
              </Breadcrumbs>

              <Typography variant='h1' component='h1' sx={{paddingTop: '30px'}}>
                {blogArticleDetails.blogTitle}
              </Typography>

              <LogoContainer>
                <LogoImage src={process.env.REACT_APP_CLIENT_URL + '/images/AutoArticle_Logo.png'} alt="Logo" />
                <Typography variant='subtitle1' sx={{marginRight: 'auto', paddingLeft: '20px'}}>
                  {blogArticleDetails.blogAuthor} — {formattedCreatedAt}
                </Typography>
                <ShareButton />
              </LogoContainer>

              <Divider />

              {blogArticleDetails.blogCoverImage !== null ? (
                <ImageContainer>
                  <CustomImage src={process.env.REACT_APP_SERVER_URL + '/' + blogArticleDetails.blogCoverImage} alt={blogArticleDetails.blogTitle} />
                </ImageContainer>
              ) : null}

              <div dangerouslySetInnerHTML={{ __html: blogArticleDetails.blogContent }} />

            </Grid>
          </GridContainerMain>
        </Grid>

        <Footer />

      </>
    ) : <Navigate to="/blog" replace />}
    </div>
  );
};

export default SingleBlogArticle;