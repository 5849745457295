import React, { useEffect, useState, useRef } from "react";
import { useLogout } from "../../hooks/useLogout";
import { Button, Divider, Typography, Menu, MenuItem } from "@mui/material";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { AccountCircle, ExpandMore, SettingsOutlined, Logout, Payment, AdminPanelSettings } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";

export default function DashboardNavButton({ isAdmin, email, theme }) {
  const { logout } = useLogout();
  const buttonRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(null);


  const handleLogOut = () => {
    logout();
  };

  useEffect(() => {
    if (buttonRef.current && window.innerWidth > 600) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, []);

 const menuItemStyle = {
    color: theme.palette.common.grey,
    borderRadius: "10px",
    backgroundColor: theme.palette.common.white,
    padding: "20px",
  }

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <>
          <Button
            ref={buttonRef}
            variant="contained"
            {...bindTrigger(popupState)}
            sx={{
              boxShadow: "none",
              padding: "20px",
              backgroundColor: theme.palette.common.white,
              display: "flex",
              height: "64px",
              "&:focus": { color: theme.palette.common.white },
              "&:hover": {
                backgroundColor: theme.palette.common.white,
                boxShadow: "none",
              },
            }}
          >
            <AccountCircle
              sx={{
                paddingRight: {
                  xs: "2px",
                  sm: "20px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
                color: theme.palette.secondary.main,
                "&:focus": { color: theme.palette.common.white },
                "&:hover": { backgroundColor: theme.palette.common.white },
                fontSize: "40px",
              }}
            />

            <Typography
              variant="p"
              component="p"
              sx={{
                paddingRight: "80px",
                textTransform: "lowercase",
                display: {
                  xs: "none",
                  sm: "block",
                  md: "block",
                  lg: "block",
                  xl: "block",
                },
              }}
            >
              {email}
            </Typography>

            <ExpandMore
              sx={{
                color: theme.palette.common.grey,
                "&:focus": { color: theme.palette.common.white },
                "&:hover": { backgroundColor: theme.palette.common.white },
              }}
            />
          </Button>

          <Menu
            {...bindMenu(popupState)}
            slotProps={{
              paper: {
                style: {
                  width: buttonWidth,
                  border: `1px solid ${theme.palette.component.borderColor}`,
                  borderRadius: "10px",
                },
              },
            }}
          >
            <MenuItem onClick={popupState.close} component={RouterLink} to="/dashboard"
              sx={{
                color: theme.palette.common.white,
                borderRadius: "10px",
                backgroundColor: theme.palette.primary.main,
                padding: "20px",
                ":hover": {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              <AccountCircle sx={{ paddingRight: "20px", color: theme.palette.common.white, fontSize: "50px" }} />
              <Typography variant="p" component="p" noWrap sx={{ color: theme.palette.common.white, }}>
                {email}
              </Typography>
            </MenuItem>

            <Divider />
            {isAdmin && (
              <MenuItem onClick={popupState.close} component={RouterLink} to="/newBlogArticle" sx={menuItemStyle}>
                <AdminPanelSettings sx={{ paddingRight: "20px", color: theme.palette.common.grey, fontSize: "40px" }} />
                <Typography variant="p" component="p" noWrap>
                  Admin Panel
                </Typography>
              </MenuItem>
            )}

            <MenuItem onClick={popupState.close} component={RouterLink} to="/account" sx={menuItemStyle}>
              <SettingsOutlined sx={{ paddingRight: "20px", color: theme.palette.common.grey, fontSize: "40px" }} />
              <Typography variant="p" component="p" noWrap>
                Account Settings
              </Typography>
            </MenuItem>

            <MenuItem onClick={popupState.close} component={RouterLink} to="/billing" sx={menuItemStyle}>
              <Payment sx={{ paddingRight: "20px", color: theme.palette.common.grey, fontSize: "40px" }} />
              <Typography variant="p" component="p" noWrap>
                Billing
              </Typography>
            </MenuItem>

            <MenuItem onClick={handleLogOut} component={RouterLink} sx={menuItemStyle}>
              <Logout sx={{ paddingRight: "20px", color: theme.palette.common.grey, fontSize: "40px" }} />
              <Typography variant="p" component="p" noWrap>
                Sign Out
              </Typography>
            </MenuItem>

          </Menu>
        </>
      )}
    </PopupState>
  );
}
