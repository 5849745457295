export const fetchBillingPortal = async (user, customer) => {
  const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/user/billing", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    body: JSON.stringify({
      customer: customer.id,
    }),
  })
  if (response.ok) {
    const { url } = await response.json();
    window.location.replace(url);
  }
};