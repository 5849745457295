import React, { useState } from "react";
import { useAiConfigContext } from "../../hooks/useContext/useAiConfigContext";
import { useAuthContext } from "../../hooks/useContext/useAuthContext";
import { Edit, Delete, HelpOutline } from "@mui/icons-material";
import ErrorBox from "../Shared/ErrorBox";
import {
  DialogTitle,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Divider,
  FormControl,
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slider,
} from "@mui/material";
import { styled } from "@mui/system";

const Root = styled(Card)({
  height: "100%",
  backgroundColor: "white",
  p: 2,
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  overflowWrap: "break-word",
  position: "relative",
});

const Content = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "4px",
  backgroundColor: "#b1294e",
});

const AiConfigDetails = ({ aiconfig }) => {
  const { dispatch } = useAiConfigContext();
  const { user } = useAuthContext();
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedGptModelEngine, setEditedGptModelEngine] = useState(!aiconfig.gptModelEngine ? "" : aiconfig.gptModelEngine);
  const [editedSectionsStop, setEditedSectionsStop] = useState(!aiconfig.sectionsStop ? "" : aiconfig.sectionsStop);
  // let [editedSectionsTemperature, setEditedSectionsTemperature] = useState(!aiconfig.sectionsTemperature ? 0 : aiconfig.sectionsTemperature);
  // let [editedSectionsMaxToken, setEditedSectionsMaxToken] = useState(!aiconfig.sectionsMaxToken ? 50 : aiconfig.sectionsMaxToken);
  // let [editedSectionsTopP, setEditedSectionsTopP] = useState(!aiconfig.sectionsTopP ? 0 : aiconfig.sectionsTopP);
  // let [editedFrequencyPenalty, setEditedFrequencyPenalty] = useState(!aiconfig.frequencyPenalty ? 0 : aiconfig.frequencyPenalty);
  // let [editedPresencePenalty, setEditedPresencePenalty] = useState(!aiconfig.presencePenalty ? 0 : aiconfig.presencePenalty);
  let editedSectionsTemperature = 0.7;
  let editedSectionsMaxToken = 4095;
  let editedSectionsTopP = 0.9;
  let editedFrequencyPenalty = 0.5;
  let editedPresencePenalty = 0.6;
  const modelOptions = ["gpt-3.5-turbo-1106", "gpt-4-1106-preview"];
  const stopOptions = ["None"];
  const [editedEmptyFields, setEditedEmptyFields] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [error, setError] = useState(null);


  if (!user) {
    return null;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    const response = await fetch(
      process.env.REACT_APP_SERVER_URL + "/api/aiconfigs/" + aiconfig._id,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const json = await response.json();

    if (response.ok) {
      dispatch({ type: "DELETE_AICONFIG", payload: json });
    }
  };

  const handleEditClick = () => {
    if (editMode === true) {
      setEditMode(false);
    } else if (editMode === false) {
      setEditMode(true);
    }
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();

    if (!user) {
      setError("You must be logged in");
      return;
    }

    let emptyFieldsClientSide = [];
    if (!editedGptModelEngine) {
      emptyFieldsClientSide.push("gptModelEngine");
    }
    if (!editedSectionsStop) {
      emptyFieldsClientSide.push("sectionsStop");
    }
    if (!editedSectionsTemperature || editedSectionsTemperature === null || editedSectionsTemperature === undefined) {
      editedSectionsTemperature = 0;
    }
    if (!editedSectionsTopP || editedSectionsTopP === null || editedSectionsTopP === undefined) {
      editedSectionsTopP = 0;
    }
    if (!editedFrequencyPenalty || editedFrequencyPenalty === null || editedFrequencyPenalty === undefined) {
      editedFrequencyPenalty = 0;
    }
    if (!editedPresencePenalty || editedPresencePenalty === null || editedPresencePenalty === undefined) {
      editedPresencePenalty = 0;
    }
    if (emptyFieldsClientSide.length > 0) {
      setEditedEmptyFields(emptyFieldsClientSide);
      setError("Please fill in all fields");
      return;
    }

    setIsSubmitDisabled(true);

    const updatedAiConfig = {
      gptModelEngine: editedGptModelEngine,
      sectionsTemperature: editedSectionsTemperature,
      sectionsMaxToken: editedSectionsMaxToken,
      sectionsTopP: editedSectionsTopP,
      sectionsStop: editedSectionsStop,
      frequencyPenalty: editedFrequencyPenalty,
      presencePenalty: editedPresencePenalty,
    };

    const response = await fetch(
      process.env.REACT_APP_SERVER_URL +
        "/api/aiconfigs/update/" +
        aiconfig._id,
      {
        method: "PUT",
        body: JSON.stringify(updatedAiConfig),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );

    const json = await response.json();
    setIsSubmitDisabled(false);

    if (!response.ok) {
      setError(json.error);
      setEditedEmptyFields(json.emptyFields);
    } else {
      setError(null);
      setEditedEmptyFields([]);
      dispatch({ type: "UPDATE_AICONFIG", payload: json });
      setEditMode(false);
    }
  };

  return (
    <Root variant="outlined">
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton
          size="large"
          aria-label="Edit"
          aria-haspopup="true"
          onClick={handleEditClick}
          color="#343a40"
        >
          <Tooltip title="Edit AI Configuration" placement="top">
            <Edit />
          </Tooltip>
        </IconButton>
        <IconButton
          sx={{ "&:hover": { backgroundColor: "#f2eeef" } }}
          size="large"
          aria-label="Delete"
          aria-haspopup="true"
          ariant="outlined"
          onClick={handleClickOpen}
          color="#343a40"
        >
          <Tooltip title="Delete AI Configuration" placement="top">
            <Delete
              sx={{
                color: "#b2294d",
              }}
            />
          </Tooltip>
        </IconButton>
      </div>
      <CardContent>
        <Content />
        {editMode ? (
          <>
            <FormControl
              fullWidth
              error={
                  editedEmptyFields &&
                  editedEmptyFields.includes("gptModelEngine")
                }
              variant="outlined"
              margin="normal"
              required={true}
            >
              <InputLabel id="gptModelEngine">Choose a Model</InputLabel>
              <Select
                labelId="gptModelEngine-label"
                id="gptModelEngine"
                value={editedGptModelEngine}
                onChange={(e) => setEditedGptModelEngine(e.target.value)}
                label="gptModelEngine"
              >
                <MenuItem value="">
                  <em>Choose Model Engine</em>
                </MenuItem>
                {modelOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              fullWidth
              error={
                  editedEmptyFields &&
                  editedEmptyFields.includes("sectionsStop")
                }
              variant="outlined"
              margin="normal"
              required={true}
            >
              <InputLabel id="sectionsStop">Sections Stop</InputLabel>
              <Select
                labelId="sectionsStoplabel"
                id="sectionsStop"
                value={editedSectionsStop}
                onChange={(e) => setEditedSectionsStop(e.target.value)}
                label="Sections Stop"
              >
                <MenuItem value="">
                  <em>Choose a Sections Stop</em>
                </MenuItem>
                {stopOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography variant="body2" gutterBottom style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{padding: '5px' }}>Temperature </span>
              <span style={{padding: '5px' }}>{editedSectionsTemperature}</span>
            </Typography>
            <Tooltip title="Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive." placement="bottom" arrow disableInteractive>
              <Slider
                defaultValue={0.7}
                aria-label="Temperature"
                valueLabelDisplay="auto"
                min={0}
                max={2}
                step={0.01}
                //onChange={(event, newValue) => setEditedSectionsTemperature(newValue)}
                value={editedSectionsTemperature}
                size="medium"
              />
            </Tooltip>

            <Typography variant="body2" gutterBottom style={{ display: 'flex', justifyContent: 'space-between'}}>
              <span style={{padding: '5px' }}>Max Token </span>
              <span style={{padding: '5px' }}>{editedSectionsMaxToken}</span>
            </Typography>
            <Tooltip title="The Maximum number of tokens to generate shared between the prompt and completion. The exact limit varies by modal. (One token is roughly 4 characters for standard English text)" placement="bottom" arrow disableInteractive>
              <Slider
                defaultValue={4095}
                aria-label="Max Token"
                valueLabelDisplay="auto"
                min={50}
                max={4095}
                step={1}
                //onChange={(event, newValue) => setEditedSectionsMaxToken(newValue)}
                value={editedSectionsMaxToken}
              />
            </Tooltip>

            <Typography variant="body2" gutterBottom style={{ display: 'flex', justifyContent: 'space-between'}}>
              <span style={{padding: '5px' }}>Top P </span>
              <span style={{padding: '5px' }}>{editedSectionsTopP}</span>
            </Typography>
            <Tooltip title="Controls diversity via nucleus sampling: 0.5 means half of all likelihood-weighted options are considered" placement="bottom" arrow disableInteractive>
              <Slider
                defaultValue={0.9}
                aria-label="Top P"
                valueLabelDisplay="auto"
                min={0}
                max={1}
                step={0.01}
                //onChange={(event, newValue) => setEditedSectionsTopP(newValue)}
                value={editedSectionsTopP}
              />
            </Tooltip>

            <Typography variant="body2" gutterBottom style={{ display: 'flex', justifyContent: 'space-between'}}>
              <span style={{padding: '5px' }}>Frequency penalty </span>
              <span style={{padding: '5px' }}>{editedFrequencyPenalty}</span>
            </Typography>
            <Tooltip title="How much to penalize new tokens based on their existing frequency in the text so far. Decreases the model's likelihood to repeat the same line verbatim." placement="bottom" arrow disableInteractive>
              <Slider
                defaultValue={0.5}
                aria-label="Frequency penalty"
                valueLabelDisplay="auto"
                min={0}
                max={2}
                step={0.01}
                //onChange={(event, newValue) => setEditedFrequencyPenalty(newValue)}
                value={editedFrequencyPenalty}
              />
            </Tooltip>

            <Typography variant="body2" gutterBottom style={{ display: 'flex', justifyContent: 'space-between'}}>
              <span style={{padding: '5px' }}>Presence penalty </span>
              <span style={{padding: '5px' }}>{editedPresencePenalty}</span>
            </Typography>
            <Tooltip title="How much to penalize new tokens based on whether they appear in the text so far. Increases the model's likelihood to talk about new topics." placement="bottom" arrow disableInteractive>
              <Slider
                defaultValue={0.6}
                aria-label="Presence penalty"
                valueLabelDisplay="auto"
                min={0}
                max={2}
                step={0.01}
                //onChange={(event, newValue) => setEditedPresencePenalty(newValue)}
                value={editedPresencePenalty}
              />
            </Tooltip>

            {error && <ErrorBox error={error} color="error" />}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  cursor: "pointer",
                  backgroundColor: "#4829B2",
                  ":hover": { bgcolor: "#321d7c" },
                  marginTop: "1rem",
                }}
                onClick={handleSaveClick}
                disabled={isSubmitDisabled}
              >
                Save Changes
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  cursor: "pointer",
                  backgroundColor: "white",
                  color: "black",
                  ":hover": { bgcolor: "#f5f5f5" },
                  marginTop: "1rem",
                  marginLeft: "20px",
                  marginBottom: "0px",
                }}
                onClick={handleEditClick}
              >
                Cancel
              </Button>
              <Tooltip
                title="To edit AI API keys, delete configuration and re-enter"
                placement="right"
              >
                <HelpOutline
                  sx={{
                    color: "#3f51b5",
                    fontSize: 20,
                    marginLeft: "20px",
                    marginTop: "25px",
                    '&:hover': {
                      cursor: 'pointer',
                    }
                  }}
                />
              </Tooltip>
            </div>
          </>
        ) : (
          <>
            <Typography variant="body2" component="p" sx={{ display: 'flex', justifyContent: 'space-between'}}>
              <span style={{padding: '5px' }}> <strong>Model </strong> </span>
              <span style={{padding: '5px' }}> {aiconfig.gptModelEngine} </span>
            </Typography>
            <Divider sx={{ margin: "10px" }} variant="middle" />
            <Typography variant="body2" component="p" sx={{ display: 'flex', justifyContent: 'space-between'}}>
              <span style={{padding: '5px' }}> <strong>Stop </strong> </span>
              <span style={{padding: '5px' }}> {aiconfig.sectionsStop} </span>
            </Typography>
            <Divider sx={{ margin: "10px" }} variant="middle" />
            <Typography variant="body2" component="p" sx={{ display: 'flex', justifyContent: 'space-between'}}>
              <span style={{padding: '5px' }}> <strong>Temperature </strong> </span>
              <span style={{padding: '5px' }}> {aiconfig.sectionsTemperature} </span>
            </Typography>
            <Divider sx={{ margin: "10px" }} variant="middle" />
            <Typography variant="body2" component="p" sx={{ display: 'flex', justifyContent: 'space-between'}}>
              <span style={{padding: '5px' }}> <strong>Max Token </strong> </span>
              <span style={{padding: '5px' }}> {aiconfig.sectionsMaxToken} </span>
            </Typography>
            <Divider sx={{ margin: "10px" }} variant="middle" />
            <Typography variant="body2" component="p" sx={{ display: 'flex', justifyContent: 'space-between'}}>
              <span style={{padding: '5px' }}> <strong>Top P </strong> </span>
              <span style={{padding: '5px' }}> {aiconfig.sectionsTopP} </span>
            </Typography>
            <Divider sx={{ margin: "10px" }} variant="middle" />
            <Typography variant="body2" component="p" sx={{ display: 'flex', justifyContent: 'space-between'}}>
              <span style={{padding: '5px' }}> <strong>Frequency Penalty </strong> </span>
              <span style={{padding: '5px' }}> {aiconfig.frequencyPenalty} </span>
            </Typography>
            <Divider sx={{ margin: "10px" }} variant="middle" />
            <Typography variant="body2" component="p" sx={{ display: 'flex', justifyContent: 'space-between'}}>
              <span style={{padding: '5px' }}> <strong>Presence Penalty </strong> </span>
              <span style={{padding: '5px' }}> {aiconfig.presencePenalty} </span>
            </Typography>
          </>
        )}
      </CardContent>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete AI Configuration
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleting your AI configuration with 'No Plan' or the 'Standard Plan' will prevent your active campaigns from running
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              cursor: "pointer",
              backgroundColor: "#4829B2",
              color: "white",
              ":hover": { bgcolor: "#321d7c" },
            }}
            onClick={handleDelete}
            autoFocus
          >
            Delete
          </Button>
          <Button
            sx={{
              cursor: "pointer",
              backgroundColor: "white",
              color: "black",
              ":hover": { bgcolor: "#f5f5f5" },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  );
};

export default AiConfigDetails;
