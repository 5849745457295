import { useState } from "react";
import { useAuthContext } from "./useContext/useAuthContext";

export const useNewBlogArticle = () => {
  const [error, setError] = useState(null);
  const { user } = useAuthContext();

  const submitNewArticle = async ({blogTitle, blogAuthor, blogPreview, blogContent, imageFile, articleID, articleType}) => {

    try {
      const formatTitleForURL = (title) => {
        return title.toLowerCase().replace(/\s+/g, '_');
      }

      const formattedTitle = formatTitleForURL(blogTitle);
      const data = new FormData();
      data.append('file', imageFile)
      data.append('blogTitle', blogTitle)
      data.append('blogAuthor', blogAuthor)
      data.append('blogPreview', blogPreview)
      data.append('blogContent', blogContent)
      data.append('formattedTitle', formattedTitle)
      data.append('articleType', articleType)
      
      let method = "POST";
      let url = process.env.REACT_APP_SERVER_URL + "/api/blog";

      if (articleID) {
        method = "PUT";
        url = process.env.REACT_APP_SERVER_URL + "/api/blog/edit/" + articleID;
      }
      const response = await fetch(url, {
        method: method,
        body: data,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      const json = await response.json();
      if (!response.ok) {
        setError(json.error);
        console.log(json.error);
      }
      if (response.ok) {
        setError(json);
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
    } catch (error) {
      setError("An error occurred, please try again");
    }
  };
  return { submitNewArticle, error };
};