import { WpConfigsContext } from "../../context/WpConfigContext"
import { useContext } from "react"

export const useWpConfigContext = () => {
  const context = useContext(WpConfigsContext)

  if(!context) {
    throw Error('useWpConfigContext must be used inside an WpConfigsContextProvider')
  }

  return context
}