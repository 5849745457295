import { createContext, useReducer } from "react";

// contex to render the updated data without refresh
// which means all components now have an access to the WP Sitecontext
export const UsersContext = createContext();

export const usersReducer = (state, action) => {
  switch (action.type) {
    case "SET_USERCONFIG":
      return {
        usersArticles: action.payload.map((item, index) => {
          return {
            id: index,
            link: item.link,
            createdAt: item.createdAt,
          };
        }),
      };
    default:
      return state;
  }
};

export const UsersContextProvider = ({ children }) => {
  const [state, usersDispatch] = useReducer(usersReducer, {
    usersArticles: null,
  });
  return (
    <UsersContext.Provider value={{ ...state, usersDispatch }}>
      {children}
    </UsersContext.Provider>
  );
};