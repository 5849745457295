import { useState, useEffect } from 'react';
import { loadStripe } from "@stripe/stripe-js";

export const useStripeKey = (user) => {
  const [key, setKey] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    const getKey = async () => {
      if (user && !key) {
        const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/user/stripePubKey", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        if (response.ok) {
          const json = await response.json();
          setKey(json.key);
        } else {
          setKey(null);
        }
      }
    };

    getKey();
  }, [user, key]);

  useEffect(() => {
    if (key) {
      const stripe = loadStripe(key);
      setStripePromise(stripe);
    }
  }, [key]);

  return stripePromise;
};