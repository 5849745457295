import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import Chip from '@mui/material/Chip';

const DraggableChip = ({ id, text, index, moveChip }) => {
  const ref = React.useRef(null);

  const [, drop] = useDrop({
    accept: 'chip',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveChip(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'chip',
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Chip
      ref={ref}
      label={text}
      style={{ opacity: isDragging ? 0 : 1 }}
    />
  );
};

export default DraggableChip;