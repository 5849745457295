export const useEmail = () => {
  const sendEmail = async (email, message) => {
    try {
      await fetch(process.env.REACT_APP_SERVER_URL + "/api/user/sendemail", {
        method: "POST",
        body: JSON.stringify({ email, message }),
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
    }
  };
  
  return { sendEmail };
};
