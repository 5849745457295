import { useState, useEffect } from 'react';

export const useUserPlanInfo = (user) => {
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const getUserPlanInfo = async () => {
      if (user) {
        const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/user/getUserInfo", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        if (response.ok) {
          const json = await response.json();
          setUserInfo(json);
        } else {
          setUserInfo(null);
        }
      }
    };

    getUserPlanInfo();
  }, [user]);

  return userInfo;
};