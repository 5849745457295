import React, { useState } from "react";
import { useAuthContext } from "../../hooks/useContext/useAuthContext";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const TruncatedTypography = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  cursor: "pointer",
  "@media (max-width: 600px)": {
    maxWidth: "10ch",
  },
});

const DashboardController = ({ topic }) => {
  const theme = useTheme();
  const { user } = useAuthContext();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const isMobile = useMediaQuery("(max-width:600px)");

  if (!user) {
    return null;
  }

  const progress = Math.round(
    ((topic.InitialSubjectAmount - topic.subject.length) /
      topic.InitialSubjectAmount) *
      100
  );

  const handleTooltipClick = () => {
    if (isMobile) {
      setIsTooltipVisible(true);
      setTimeout(() => {
        setIsTooltipVisible(false);
      }, 2500);
    } else {
      setIsTooltipVisible(!isTooltipVisible);
    }
  };

  const handleTooltipHover = () => {
    if (!isMobile) {
      setIsTooltipVisible(!isTooltipVisible);
    }
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false); // Hide all tooltips when mouse leaves the container
  };

  return (
    <Box
      color={theme.palette.common.white}
      backgroundColor={theme.palette.common.background}
      p={1}
      borderBottom={5}
      key={topic.id}
      component="div"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderRadius={16}
      onMouseLeave={handleMouseLeave}
    >
      <Box mr={2} sx={{ paddingLeft: "10px" }}>
        <Tooltip title={topic.title} placement="top">
          <TruncatedTypography variant="body2" component="p">
            {topic.title}
          </TruncatedTypography>
        </Tooltip>
      </Box>
      <Tooltip
        title={`${Math.round(
          topic.InitialSubjectAmount - topic.subject.length
        ).toString()}
        /
        ${Math.round(
          topic.InitialSubjectAmount
        ).toString()} subjects completed`}
        placement="top"
        open={isTooltipVisible}
        onClick={handleTooltipClick}
        onMouseEnter={handleTooltipHover}
        onMouseLeave={handleTooltipHover}
      >
        <Box flexGrow={1} mx={2}>
          <LinearProgress
            variant="determinate"
            color="success"
            value={progress}
            sx={{
              height: "16px",
              borderRadius: "16px",
              cursor: "pointer",
            }}
          />
        </Box>
      </Tooltip>
      <Tooltip title="Running" placement="top">
        <Typography
          variant="body2"
          component="p"
          sx={{
            cursor: "pointer",
            color: theme.palette.component.LinearProgressText,
            fontWeight: "1000",
            fontSize: ".7rem",
            paddingRight: "10px",
          }}
        >
          {progress}%
        </Typography>
      </Tooltip>
    </Box>
  );
};

export { DashboardController };
