import { createContext, useReducer } from "react";

// contex to render the updated data without refresh
// which means all components now have an access to the topic context
export const TopicsContext = createContext();

export const topicReducer = (state, action) => {
  switch (action.type) {
    case "SET_TOPICS":
      return {
        topics: action.payload,
      };
    case "CREATE_TOPIC":
      return {
        topics: [action.payload, ...state.topics],
      };
    case "DELETE_TOPIC":
      return {
        topics: state.topics.filter(
          (topic) => topic._id !== action.payload._id
        ),
      };
    case "UPDATE_TOPIC":
      return {
        topics: state.topics.map((topic) =>
          topic._id === action.payload._id ? action.payload : topic
        ),
      };
      // case "RUN_TOPIC":
      //   return {
      //     topics: state.topics.filter(
      //       (topic) => topic._id !== action.payload._id
      //     ),
      //   };
    default:
      return state;
  }
};

export const TopicsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(topicReducer, {
    topics: null,
  });
  return (
    <TopicsContext.Provider value={{ ...state, dispatch }}>
      {children}
    </TopicsContext.Provider>
  );
};
