import React, {useEffect, useState} from "react";
import { useAuthContext } from "../hooks/useContext/useAuthContext";
import { useResetPassword } from "../hooks/useResetPassword";
import { useResetEmail } from "../hooks/useResetEmail";
import Footer from "../components/Shared/Footer";
import Navbar from "../components/NavBar/Navbar";
import ErrorBox from "../components/Shared/ErrorBox";
import { Box, Button, Typography, CircularProgress, Divider, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';

const GridContainerMain = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.common.componentBackground,
    minHeight: "100vh",
    paddingLeft:'10%',
    paddingRight: '10%',
    paddingTop: '50px',
  }));

const Account = () => {
  const theme = useTheme();
  const { user } = useAuthContext();
  const {resetPassword, error} = useResetPassword();
  const {resetEmail, emailError} = useResetEmail();
  const [verificationMessage, setVerificationMessage] = useState("");
  const [emailVerificationMessage, setEmailVerificationMessage] = useState("");
  const [isLoadingReset, setIsLoadingReset] = useState(false);
  const [isEmailLoadingReset, setIsEmailLoadingReset] = useState(false);

  if (!user) {
    window.location.href = "/login";
  }

  const changeEmailLink = async () => {
    setIsEmailLoadingReset(true);
    const email = user.email;
    await resetEmail(email, emailError);
    setIsEmailLoadingReset(false);
  };

  const sendPasswordLink = async () => {
    setIsLoadingReset(true);
    const email = user.email;
    await resetPassword(email, error);
    setIsLoadingReset(false);
  };

  useEffect(() => {
    if (emailError === "We have emailed you a link to change your email") {
      setEmailVerificationMessage("We have emailed you a link to change your email");
    } else if (emailError) {
      setEmailVerificationMessage("An error occurred, please try again");
    } else {
      setEmailVerificationMessage("");
    }
  }, [emailError]);

  useEffect(() => {
    if (error === "We have emailed you a link to reset your password") {
      setVerificationMessage("We have emailed you a link to reset your password");
    } else if (error) {
      setVerificationMessage("An error occurred, please try again");
    } else {
      setVerificationMessage("");
    }
  }, [error]);


  return (
    <div>
      <Navbar />
      <GridContainerMain container spacing={0} 
        sx={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: theme.palette.common.white,
        }}
      >
        <Grid item xs={12} sm={11} md={10} lg={9} xl={9}>
            <Typography variant="h1" component="h1" paddingBottom="50px">
                Account Settings
            </Typography>
            <Box sx={{ border: '1px solid #becde2', padding:'40px', marginBottom:'50px', borderRadius: '10px' }}>
                <Typography variant="h6" component="p" paddingBottom="15px">
                    Account
                </Typography>

                <Divider color='#becde2'/>

                <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: '50px', paddingBottom: '50px' }}>
                    <Typography sx={{paddingRight: {xs: "20px", sm: "30px", md: "40px", lg: "50px", xl: "50px"}}} component="p" color='#8f8f8f'>
                        Email
                    </Typography>
                    <Typography paddingRight="50px" component="p" color='#000000'>
                        {user.email}
                    </Typography>
                </Box>
                {isEmailLoadingReset ? (<CircularProgress />) : (
                <Button
                    variant="outlined"
                    onClick={changeEmailLink}
                    sx={{
                        borderRadius: '30px',
                        backgroundColor: '#4829B2',
                        borderColor: '#4829B2',
                        color: '#ffffff',
                        textTransform: 'none',
                        ':hover': { 
                            borderColor: '#5A3EB9',
                            backgroundColor: '#5A3EB9',
                        },
                    }}
                >
                    Update Email
                </Button>)}

                {emailError && (
                  <ErrorBox error={emailError} color={emailVerificationMessage === "We have emailed you a link to change your email" ? 'success' : 'error'} />
                )}

            </Box>
            <Box sx={{ border: '1px solid #becde2', padding:'40px', marginBottom:'50px', borderRadius: '10px' }}>
                <Typography variant="h6" component="p" paddingBottom="15px">
                    Security
                </Typography>

                <Divider color='#becde2'/>

                <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: '50px', paddingBottom: '50px' }}>
                    <Typography component="p" color='#8f8f8f' paddingRight="50px">
                        Password
                    </Typography>
                </Box>
                {isLoadingReset ? (<CircularProgress />) : (
                <Button
                    variant="outlined"
                    onClick={sendPasswordLink}
                    sx={{
                        borderRadius: '30px',
                        backgroundColor: '#4829B2',
                        borderColor: '#4829B2',
                        color: '#ffffff',
                        textTransform: 'none',
                        ':hover': { 
                            borderColor: '#5A3EB9',
                            backgroundColor: '#5A3EB9',
                        },
                    }}
                >
                Update Password
                </Button>)}
                {error && (
                  <ErrorBox error={error} color={ verificationMessage === "We have emailed you a link to reset your password" ? 'success' : 'error' } />
                )}
            </Box>
        </Grid>
      </GridContainerMain>
      <Footer />
    </div>
  );
};

export default Account;