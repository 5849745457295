import { useState, useEffect } from 'react';

export const useAccountInfo = (user) => {
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    const getAccountInfo = async () => {
      if (user) {
        const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/user/billing", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

        if (response.ok) {
          const json = await response.json();
          setCustomer(json.customer);
        } else {
          setCustomer(null);
        }
      }
    };

    getAccountInfo();
  }, [user]);

  return customer;
};