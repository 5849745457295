import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";

const GridContainerMain = styled("div")({
  padding: "100px 31% 30px 31%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  "@media (max-width: 1600px)": {
    paddingLeft: "20%",
    paddingRight: "20%",
  },
  "@media (max-width: 960px)": {
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  "@media (max-width: 600px)": {
    paddingLeft: "10%",
    paddingRight: "7%",
    paddingTop: "60px",
  },
});

const BreadcrumbsLegal = ({ title }) => {
  const theme = useTheme();

  return (
    <Grid container>
      <GridContainerMain>
        <Grid item xs={12} sm={12} md={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link variant="subtitle1" href="/" underline="hover" sx={{color: theme.palette.common.offBlack}}>
              Home
            </Link>
            <Typography variant="subtitle1">
              {title}
            </Typography>
          </Breadcrumbs>
        </Grid>
      </GridContainerMain>
    </Grid>
  );
};

export default BreadcrumbsLegal;
