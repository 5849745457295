import React from "react";
import { Typography, Box } from "@mui/material";

function ProductCard(props) {
  const { Icon, color, headline, text } = props;

  return (
    <div>
      <Box
        style={{
          color: color,
          display: "flex",
          alignItems: "center",
          padding: "1rem",
        }}
      >
        {Icon}
      </Box>
      <Typography variant="h4" gutterBottom>
        {headline}
      </Typography>
      <Typography variant="subtitle1">{text}</Typography>
    </div>
  );
}

export default ProductCard;