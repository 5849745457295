import React, { useState, useEffect, useMemo } from "react";
import { useTopicContext } from "../../hooks/useContext/useTopicContext";
import { useAuthContext } from "../../hooks/useContext/useAuthContext";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { FormControl, FormControlLabel, Checkbox, Grid } from '@mui/material';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { MuiChipsInput } from 'mui-chips-input'
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import { Link } from "react-router-dom";
import { styled } from '@mui/system';
import { CircularProgress } from "@mui/material";
import { Typography } from "@mui/material";
import ErrorBox from "../Shared/ErrorBox";
import useUserSubscribed from "../../hooks/useUserSubscribed";
import DraggableChip from "./DragChip";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Tabs, Tab } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';

const CenteredDiv = styled('div')({
  display: "flex",
  justifyContent: "center"
});

const TopicForm = () => {
  const { dispatch } = useTopicContext();
  const { user } = useAuthContext();
  const [wpsite, setWpSite] = useState("");
  const [wpMultiSites, setWpMultiSites] = useState([]);
  const [wpSitesOptions, setWpSitesOptions] = useState([]);
  const [wpCategoriesOptions, setWpCategoriesOptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [title, setTitle] = useState("");
  const [subheadingCount, setSubheadingCount] = useState("");
  let [photoCount, setPhotoCount] = useState("");
  const [imgSource, setImgSource] = useState("");
  const [status, setStatus] = useState("");
  const [interval, setTheInterval] = useState("");
  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);
  const [subject, setSubject] = useState([]);
  const [articleKeyword, setArticleKeyword] = useState([]);
  const subheadingOptions = useMemo(() => ["0", "2", "3", "4"], []);
  const photoOptions = useMemo(() => ["0", "1", "2", "3"], [])
  const statusOptions = useMemo(() => ["draft", "publish"], []);
  const intervalOptions = useMemo(() => [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 48, 72], []);
  const [loading, setLoading] = useState(false);
  const [subjectCount, setSubjectCount] = useState('');
  const subjectCountOptions = [1, 2, 3, 5, 10, 20];
  const [isSaveTemplateChecked, setIsSaveTemplateChecked] = useState(false);
  const [savedTemplate, setSavedTemplate] = useState({});
  const [userChangedWpSite, setUserChangedWpSite] = useState(false);
  const [userCheckedSaveTemplate, setUserCheckedSaveTemplate] = useState(false);
  const userSubscribed = useUserSubscribed();
  const [isReordering, setIsReordering] = useState(false);
  const [campaignType, setCampaignType] = useState('single');

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setWpMultiSites(prev => checked
      ? [...prev, name]
      : prev.filter(site => site !== name));
  };

  const handleImgSourceChange = (event) => {
      setImgSource(event.target.value);
    };

  const toggleReorder = () => {
    setIsReordering(!isReordering);
  };

  //change campaign type between single to multi
  const handleCampaignChange = (event, newValue) => {
    setCampaignType(newValue);

    if (newValue === 'multi') {
      // setWpSite('');
      setCategories([]);
    } else if (newValue === 'single' && savedTemplate.wpsite === wpsite) {
      if (wpCategoriesOptions.length > 0 && savedTemplate.categories) {
        const updatedCategories = [];
        wpCategoriesOptions.forEach((category) => {
            if (savedTemplate.categories.includes(category.id)) {
                updatedCategories.push(category.name);
            }
        });
        setCategories(updatedCategories);
    } else {
      setCategories(savedTemplate.categories && savedTemplate.wpSite === wpsite ? savedTemplate.categories : []);
    }
    }
  };

  //prevent re-rendering of savedTemplate
  const handleWpSiteChange = (e) => {
    setWpSite(e.target.value);
    setUserChangedWpSite(true);
    setCategories([]);
  };

  //prevent re-rendering of savedTemplate
  const handleSaveTemplateToggleTrue = () => {
    setIsSaveTemplateChecked(!isSaveTemplateChecked);
    setUserCheckedSaveTemplate(true);
  };

  //handle subject change for subject chips
  const handleSubjectChange = (newSubjectChips) => {
    const uniqueChips = Array.from(new Set(newSubjectChips));
    setSubject(uniqueChips);
  };

  const moveChip = (dragIndex, hoverIndex) => {
    setSubject((prevSubjects) => {
      const newSubjects = [...prevSubjects];
      const dragChip = newSubjects[dragIndex];
      newSubjects.splice(dragIndex, 1);
      newSubjects.splice(hoverIndex, 0, dragChip);
      return newSubjects;
    });
  };

  //handle article Keywords change for keyword chips
  const handleArticleKeywordChange = (newArticleKeywordChips) => {
    setArticleKeyword(newArticleKeywordChips);
  };

  //replace display html to text for & 
  const replaceAmpersand = (text) => {
    if (typeof text === 'string') {
      return text.replace(/&amp;/g, "&");
    } else {
      return text;
    }
  };

  //handle change for categories
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCategories(
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  //get wp categories
  useEffect(() => {
    if (!wpsite) {
      setWpCategoriesOptions([]);
      setCategories([]);
    }

    const fetchWpConfigs = async () => {
      const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/wpconfigs", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (response.ok) {
        const json = await response.json();
        const sites = json.map((item) => ({
          url: item.WpUrl,
          categories: item.siteCategories,
        }));
        setWpSitesOptions(sites.map((site) => site.url));

        if (wpsite) {
          setCategories([]);
          const selectedWpSite = sites.find((site) => site.url === wpsite);
          setWpCategoriesOptions(selectedWpSite.categories);
        }

        // if (wpsite && campaignType !== 'multi') {
        //   if (userChangedWpSite === false && savedTemplate.wpsite === wpsite) {
        //     setCategories(savedTemplate.categories);
        //     const selectedWpSite = sites.find((site) => site.url === wpsite);
        //     setWpCategoriesOptions(selectedWpSite.categories);
        //   }
        // }
      }

    };

    if (user) {
      fetchWpConfigs();
    }
  }, [dispatch, user, wpsite, campaignType, savedTemplate.categories, userChangedWpSite, savedTemplate.wpsite]);


  //get userTemplate
  useEffect(() => {
    const fetchUserConfigs = async () => {
      const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/user/savedTemplate", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.ok) {
        const json = await response.json();
        const savedTemplate = (json.savedTemplate);
        const templateStatus = (json.templateStatus);
        setSavedTemplate(savedTemplate);
        setIsSaveTemplateChecked(templateStatus);
      };
    }
    
    if (user) {
      fetchUserConfigs();
    }
  }, [user]);



  //set saved userTemplate in topicForm
  useEffect(() => {
    const setTemplate = async () => {
      if (savedTemplate.interval && !userChangedWpSite) {
        const matchingSite = wpSitesOptions.find((option) => option === savedTemplate.wpsite);
        if (matchingSite) {
          setWpSite(matchingSite);
        }
      
        const matchingSubheading = subheadingOptions.find((option) => option === savedTemplate.subheadingCount);
        setSubheadingCount(matchingSubheading);

        const matchingPhotoCount = photoOptions.find((option) => option === savedTemplate.photoCount);
        setPhotoCount(matchingPhotoCount);

        const matchingImgSource = savedTemplate.imgSource || '';
        setImgSource(matchingImgSource);

        const matchingStatus = statusOptions.find((option) => option === savedTemplate.status);
        setStatus(matchingStatus);

        const matchingInterval = intervalOptions.find((option) => option === savedTemplate.interval);
        setTheInterval(matchingInterval);

        const matchingMultiWpSites = wpSitesOptions.filter(option => savedTemplate.wpMultiSites.includes(option));
        setWpMultiSites(matchingMultiWpSites);

      }
    }

    if (isSaveTemplateChecked && !userCheckedSaveTemplate) {
      setTemplate();
    }
  }, [isSaveTemplateChecked, userChangedWpSite, savedTemplate, wpSitesOptions, subheadingOptions, photoOptions, statusOptions, intervalOptions, userCheckedSaveTemplate]);



  //set saved userTemplate categories in topicForm
  useEffect(() => {
    if (wpCategoriesOptions.length > 0) {
      const updatedCategories = [];
      wpCategoriesOptions.forEach((category) => {
        if (savedTemplate.categories.includes(category.id)) {
          updatedCategories.push(category.name);
        }
      });
      
      if (!userChangedWpSite && savedTemplate.wpsite === wpsite) {
        setCategories(updatedCategories);
      }
    }
  }, [savedTemplate, wpCategoriesOptions, userChangedWpSite, wpsite]);


  //handle submit new campaign
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      setError("You must be logged in");
      return;
    }

    let emptyFieldsClientSide = [];
    if (!wpsite && campaignType === 'single') {
      emptyFieldsClientSide.push("wpsite");
    }
    if (!title) {
      emptyFieldsClientSide.push("title");
    }
    if (!subheadingCount) {
      emptyFieldsClientSide.push("subheadingCount");
    }
    // if (!photoCount) {
    //   emptyFieldsClientSide.push("photoCount");
    // }
    if (subject.length === 0) {
      emptyFieldsClientSide.push("subject");
    }
    if (!interval) {
      emptyFieldsClientSide.push("interval");
    }
    if (!status) {
      emptyFieldsClientSide.push("status");
    }
    if (photoCount && !imgSource && photoCount !== '0') {
      emptyFieldsClientSide.push("imgSource");
    }
    if (emptyFieldsClientSide.length > 0) {
      setEmptyFields(emptyFieldsClientSide);
      setError("Please fill in all fields");
      return;
    }

    const categoryIds = categories.map((category) => {
      const foundCategory = wpCategoriesOptions.find((option) => option.name === category);
      return foundCategory ? foundCategory.id : null;
    }).filter(Boolean);

    if (!photoCount || photoCount === "") {
      photoCount = '0';
    }
    
    const topic = { wpsite, wpMultiSites, campaignType, title, subheadingCount, photoCount, imgSource, subject, articleKeyword, categories: categoryIds, interval, status, isSaveTemplateChecked };
    setLoading(true);
    const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/topics/", {
      method: "POST",
      body: JSON.stringify(topic),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();
    setLoading(false);
    if (!response.ok) {
      setError(json.error);
      setEmptyFields(json.emptyFields);
    }
    if (response.ok) {
      if (isSaveTemplateChecked) {
        setTitle("");
        setSubject([]);
        setError(null);
        setEmptyFields([]);
        dispatch({ type: "CREATE_TOPIC", payload: json });
        setIsReordering(false);

      } else {
        setWpSite("");
        setTitle("");
        setSubheadingCount("");
        setPhotoCount("");
        setImgSource("");
        setSubject([]);
        setArticleKeyword([]);
        setCategories([]);
        setStatus("");
        setTheInterval("");
        setSubjectCount("");
        setError(null);
        setEmptyFields([]);
        setWpCategoriesOptions([]);
        dispatch({ type: "CREATE_TOPIC", payload: json });
        setIsReordering(false);
      }
    }
  };

  //submit subject generator api call
  const submitSubjects = async (e) => {
    e.preventDefault();
    if (subjectCount === '') {
      setError("Please select a subject count");
      return;
    } else if (title === '') {
      setError("Please enter a title");
      return;
    } else {
      setError(null);
      setLoading(true);
      const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/aiconfigs/subjectGenerator", {
        method: "POST",
        body: JSON.stringify({ subjectCount: subjectCount, title: title }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      
      const json = await response.json();
      setLoading(false);

      if (!response.ok) {
        setError(json.error);
      }
      if (response.ok) {
        setSubjectCount('');
        let nonEmptySubjects = json.subjects.filter(subject => subject !== "");
        setSubject([...subject, ...nonEmptySubjects]);
      }
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box
        component="form"
        sx={{
          "& > :not(style)": {
            m: 1,
            width: "100%",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
          p: 2,
          borderRadius: "8px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >

      <Tabs
        value={campaignType}
        onChange={handleCampaignChange}
      >
        <Tab value="single" label="Single Site Campaign" wrapped />
        <Tab value="multi" label="Multi Site Campaign" wrapped />
      </Tabs>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6" component="h3">
          Create Campaign
        </Typography>
        <Link to="/user_manual" target="_blank" rel="noopener noreferrer">
          <Tooltip title="User Manual" placement="top">
            <HelpOutlineIcon sx={{ color: 'action.active' }} />
          </Tooltip>
        </Link>
      </div>

      {campaignType === 'single' ? (
        <FormControl disabled={campaignType === 'multi'} required={campaignType === 'single'} error={emptyFields && emptyFields.includes("wpsite")} fullWidth variant="outlined" margin="normal">
          <InputLabel id="wp-site-label">Site</InputLabel>
          <Select
            labelId="wp-site-label"
            id="wp-site"
            value={campaignType === 'multi' ? "" : wpsite}
            onChange={handleWpSiteChange}
            label="Site"
          >
            {wpSitesOptions.length === 0 ? (
              <MenuItem value="">
                <Link to="/wp_configuration" rel="noopener noreferrer">
                    <em>Add WordPress Site</em>
                </Link>
              </MenuItem>
            ) : (
              <MenuItem value="">
                <em>Choose WordPress Site</em>
              </MenuItem>
            )}
            {[...wpSitesOptions].sort().map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <FormControl component="fieldset" sx={{ m: 3 }} variant="standard">
          <FormGroup>
            <Grid container spacing={2}>
                {wpSitesOptions.sort().map((option, index) => (
                  <Grid item xs={6} key={option}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={wpMultiSites.includes(option)}
                          onChange={handleCheckboxChange}
                          name={option}
                        />
                      }
                      label={option}
                    />
                  </Grid>
                ))}
            </Grid>
          </FormGroup>
        </FormControl>
      )}

      <TextField
        required={true}
        id="topic-title"
        label="Campaign Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            document.getElementById('subheading-count').focus();
          }
        }}
        variant="outlined"
        fullWidth
        margin="normal"
        error={emptyFields && emptyFields.includes("title")}
      />

      <FormControl error={emptyFields && emptyFields.includes("subheadingCount")} required={true} fullWidth variant="outlined" margin="normal">
        <InputLabel id="subheading-count-label">Subheading Count</InputLabel>
        <Select
          labelId="subheading-count-label"
          id="subheading-count"
          value={subheadingCount}
          onChange={(e) => setSubheadingCount(e.target.value)}
          label="Subheading Count"
        >
          <MenuItem value="">
            <em>Choose Subheading Count</em>
          </MenuItem>
          {subheadingOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl required={false} fullWidth variant="outlined" margin="normal" disabled={!userSubscribed}>
        <InputLabel id="photo-count-label">Photo Count</InputLabel>
        <Select
          labelId="photo-count-label"
          id="photo-count"
          value={photoCount}
          onChange={(e) => setPhotoCount(e.target.value)}
          label="Photo Count"
        >
          <MenuItem value="">
            <em>Choose Photo Count</em>
          </MenuItem>
          {photoOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl error={emptyFields && emptyFields.includes("imgSource")} disabled={!userSubscribed}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FormLabel id="image-source-group">Image Source</FormLabel>
          <Tooltip title="'Stock Images' uses the Openverse and Unsplash database, all protected under a Creative Commons license" placement="right">
            <HelpOutlineIcon
              sx={{
                color: 'action.active',
                marginLeft: 1,
                '&:hover': {
                  cursor: 'pointer',
                }
              }}
            />
          </Tooltip>
        </div>
        <RadioGroup
          row
          aria-labelledby="image-source-group"
          name="controlled-image-source-group"
          value={imgSource}
          onChange={handleImgSourceChange}
        >
          <FormControlLabel value="Stock" control={<Radio />} label="Stock Images" />
          <FormControlLabel value="Google" control={<Radio />} label="Google Images" />
        </RadioGroup>
      </FormControl>

      <FormControl error={emptyFields && emptyFields.includes("status")} required={true} fullWidth variant="outlined" margin="normal">
        <InputLabel id="status-label">Publishing Status</InputLabel>
        <Select
          labelId="status-label"
          id="status"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          label="Publishing Status"
        >
          <MenuItem value="">
            <em>Choose a Status</em>
          </MenuItem>
          {statusOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box display="flex" justifyContent="space-between" alignItems="center">

        <FormControl variant="outlined" sx={{ flexGrow: 1, marginRight: 1 }} disabled={!userSubscribed}>
          <InputLabel id="subject-count-label">Subject Count</InputLabel>
          <Select
            labelId="subject-count-label"
            id="subject-count"
            value={subjectCount}
            onChange={(e) => setSubjectCount(e.target.value)}
            label="Subject Count"
          >
            <MenuItem value="">
              <em>Choose amount of subject to generate</em>
            </MenuItem>
            {subjectCountOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button 
          sx={{backgroundColor: '#4829B2', ':hover': {bgcolor: '#321d7c'}, cursor: "pointer"}} 
          variant="contained" 
          onClick={submitSubjects}
          disabled={loading || !userSubscribed}
        >
          Generate Subjects
        </Button>
      </Box>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
      {!isReordering && (
        <MuiChipsInput
          key='subjectInput'
          fullWidth
          variant="outlined"
          id="subjects"
          name="subjects"
          placeholder="Enter key to add subjects"
          label="Subjects"
          error={emptyFields && emptyFields.includes("subject")}
          required={true}
          value={subject}
          onChange={handleSubjectChange}
          onPaste={(event) => {
            const pastedContent = event.clipboardData.getData("text");
            const pastedSubjects = pastedContent.split(/\r?\n/);
            setSubject([...subject, ...pastedSubjects]);
            event.preventDefault();
          }}
        />
      )}
      {isReordering && (
        <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px', border: '1px solid #ccc', borderRadius: '5px', padding: '10px' }}>
          {subject.map((chip, index) => (
            <DraggableChip
              key={`${chip}-${index}`} 
              id={chip}
              text={chip}
              index={index}
              moveChip={moveChip}
            />
          ))}
        </Box>
      )}
        <Typography variant='body2' onClick={toggleReorder} style={{cursor: 'pointer'}}>
          {isReordering ? 'Close' : 'Rearrange Subjects'}
        </Typography>
      </div>
      <MuiChipsInput
        key='articleKeywordInput'
        fullWidth
        variant="outlined"
        id="articleKeyword"
        name="articleKeyword"
        placeholder="Enter key to add keyword"
        label="Article Keywords"
        value={articleKeyword}
        onChange={handleArticleKeywordChange}
        disabled={!userSubscribed}
        onPaste={(event) => {
          const pastedArticleKeywordContent = event.clipboardData.getData("text");
          const pastedArticleKeywords = pastedArticleKeywordContent.split(/\r?\n/);
          setArticleKeyword([...articleKeyword, ...pastedArticleKeywords]);
          event.preventDefault();
        }}
      />
      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel id="category-chip-label">Categories</InputLabel>
        <Select
          labelId="category-chip-label"
          id="categories"
          multiple
          disabled={campaignType === 'multi'}
          value={campaignType === 'multi' ? [] : categories}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="categories-chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={replaceAmpersand(value)} />
              ))}
            </Box>
          )}
        >
         <MenuItem disabled value="">
            <em>Choose a site to view categories</em>
          </MenuItem>
          {wpCategoriesOptions.map((name) => (
            <MenuItem
              key={name.id}
              value={name.name}
            >
              {replaceAmpersand(name.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl error={emptyFields && emptyFields.includes("interval")} required={true} fullWidth variant="outlined" margin="normal">
        <InputLabel id="interval-label">Publishing Interval</InputLabel>
        <Select
          labelId="interval-label"
          id="interval"
          value={interval}
          onChange={(e) => setTheInterval(e.target.value)}
          label="Publishing Interval"
        >
          <MenuItem value="">
            <em>Hours:</em>
            </MenuItem>
          {intervalOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {'every ' + option + ' hours'}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl component="fieldset">
        <FormControlLabel
          control={
            <Checkbox
              checked={isSaveTemplateChecked}
              onChange={handleSaveTemplateToggleTrue}
              name="save-template-checkbox"
              color="primary"
              value={isSaveTemplateChecked}
            />
          }
          label="Save Input"
        />
      </FormControl>

      <CenteredDiv>
        {loading ? <CircularProgress /> : null}
      </CenteredDiv>
      {error && <ErrorBox error={error} color='error'/>}
      <Button
        sx={{backgroundColor: '#4829B2', ':hover': {bgcolor: '#321d7c'}, cursor: "pointer"}}
        variant="contained"
        type="submit"
        disabled={loading}
      >
        Add Campaign
      </Button>

    </Box>
    </DndProvider>
  );
};

export default TopicForm;