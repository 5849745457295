import React from "react";
import { Button } from "@mui/material";

export default function HomeNavDashButton({ mobileView }) {
  return (
    <Button
      href="/Dashboard"
      variant="primaryButton"
      sx={{
        marginRight: mobileView ? 0 : 2,
        height: mobileView ? "60px" : null,
        marginTop: mobileView ? "10px" : "0px",
      }}
    >
      Dashboard
    </Button>
  );
}