import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useResendEmail } from "../hooks/useResendEmail";
import Navbar from "../components/NavBar/Navbar";
import Footer from "../components/Shared/Footer";
import ErrorBox from "../components/Shared/ErrorBox";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  CircularProgress,
  Paper,
  Box,
  Grid,
  Link,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";

const RootContainer = styled(Grid)(({ theme }) => ({
  height: "100vh",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundColor: theme.palette.common.white,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const SizeContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
  border: `1px solid ${theme.palette.component.borderColor}`,
}));

const PaperContainer = styled("div")({
  margin: "16px 48px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledForm = styled("form")({
  width: "100%", // Fix IE 11 issue.
  marginTop: "8px",
});

const LogoImage = styled("img")({
  width: "50px",
  height: "50px",
  marginBottom: "10px",
  marginTop: "10px",
  display: "block",
  margin: "0 auto",
});

const VerificationPage = () => {
  const [verificationMessage, setVerificationMessage] = useState("");
  let { emailToken } = useParams();
  const [confirmed, setConfirmed] = useState(false);
  const [email, setEmail] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const { resendEmail, error } = useResendEmail();

  const verifyEmailToken = async (emailToken) => {
    const response = await fetch(
      process.env.REACT_APP_SERVER_URL + "/api/user/confirmation",
      {
        method: "POST",
        body: JSON.stringify({ emailToken }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const json = await response.json();
    if (response.ok) {
      setVerificationMessage(json.message);
      setConfirmed(true);
    } else if (!response.ok) {
      setVerificationMessage("Failed");
      setConfirmed(false);
    }
  };

  useEffect(() => {
    if (emailToken) {
      verifyEmailToken(emailToken);
    }
  }, [emailToken]);

  useEffect(() => {
    if (!verificationMessage) {
      setVerificationMessage("Loading...");
    } else if (verificationMessage === "Email Confirmed") {
      setTimeout(() => {
        window.location.href = "/login";
      }, 1500);
    }

    if (
      error === "Email already confirmed" ||
      error === "Email re-sent, check spam if missing"
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [verificationMessage, error]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await resendEmail(email, error);
  };

  return (
    <div>
      <Navbar />
      <RootContainer container component="main">
        <SizeContainer
          item
          xs={12}
          sm={8}
          md={5}
          lg={3}
          xl={3}
          component={Paper}
          elevation={1}
          square
        >
          <PaperContainer>
            <br />

            {confirmed ? (
              verificationMessage === "Loading..." ? (
                <div>
                  <Typography component="h1" variant="h5">
                    <span>{verificationMessage}</span>
                  </Typography>
                  <div
                    style={{
                      marginTop: "1.5rem",
                      marginBottom: "1.5rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <br />
                    <CircularProgress />
                  </div>
                </div>
              ) : (
                <div>
                  <Typography component="h1" variant="h5">
                    <span>{verificationMessage}</span>
                  </Typography>
                  <div
                    style={{
                      marginTop: "1.5rem",
                      marginBottom: "1.5rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <br />
                    <CheckCircleOutlineIcon sx={{ color: "#1EB614" }} />
                  </div>
                </div>
              )
            ) : (
              <div>
                <LogoImage
                  src={
                    process.env.REACT_APP_CLIENT_URL +
                    "/images/AutoArticle_Logo.png"
                  }
                  alt="Logo"
                />
                <Typography component="h1" variant="h5">
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "1rem",
                    }}
                  >
                    Email Confirmation Failed
                  </span>
                </Typography>
                <br />
                <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                  <StyledForm onSubmit={handleSubmit}>
                    <TextField
                      onChange={(event) => setEmail(event.target.value)}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      value={email}
                      autoFocus
                      autoComplete="username"
                    />
                    <br />
                    {error && (
                      <ErrorBox
                        error={error}
                        color={`${
                          error === "Email re-sent, check spam if missing"
                            ? "success"
                            : "error"
                        }`}
                      />
                    )}
                    <br />
                    <Button
                      sx={{
                        borderRadius: "50px",
                        height: "45px",
                        backgroundColor: "#4829B2",
                        ":hover": { bgcolor: "#321d7c" },
                      }}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={isDisabled}
                    >
                      Re-send Email Confirmation
                    </Button>
                  </StyledForm>
                </div>
              </div>
            )}
            <Box mt={5}>
              <Typography variant="body2" color="textSecondary" align="center">
                {"Copyright © "}
                <Link color="inherit">AutoArticle</Link>{" "}
                {new Date().getFullYear()}
                {"."}
              </Typography>
            </Box>
          </PaperContainer>
        </SizeContainer>
      </RootContainer>
      <Footer />
    </div>
  );
};

export default VerificationPage;
