import { createContext, useReducer } from "react";

// contex to render the updated data without refresh
// which means all components now have an access to the topic context
export const ActiveTopicsContext = createContext();

export const activeTopicsReducer = (state, action) => {
  switch (action.type) {
    case "SET_ACTIVE_TOPICS":
      return {
        filteredTopics: action.payload.filter(
            (topic) => topic.runningScript === true && !topic.campaign_completed
          ),
        };
    case "ADD_ACTIVE_TOPIC":
      return {
        filteredTopics: [action.payload, ...state.filteredTopics],
      };
    case "REMOVE_ACTIVE_TOPIC":
      return {
        filteredTopics: state.filteredTopics.filter(
          (topic) => topic._id !== action.payload._id
        ),
      };
    default:
      return state;
  }
};

export const ActiveTopicsContextProvider = ({ children }) => {
  const [state, activeTopicsDispatch] = useReducer(activeTopicsReducer, {
    filteredTopics: null,
  });
  return (
    <ActiveTopicsContext.Provider value={{ ...state, activeTopicsDispatch }}>
      {children}
    </ActiveTopicsContext.Provider>
  );
};
