import React, { useState } from "react";
import { useAiConfigContext } from "../../hooks/useContext/useAiConfigContext";
import { useAuthContext } from "../../hooks/useContext/useAuthContext";
import ErrorBox from "../Shared/ErrorBox";
// import { Link } from "react-router-dom";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Tooltip,
  Typography,
  CircularProgress,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Slider,
} from "@mui/material";
import { styled } from "@mui/system";

const CenteredDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const AiConfigForm = () => {
  const { dispatch } = useAiConfigContext();
  const { user } = useAuthContext();
  const [gptApiKeys, setGptApiKeys] = useState("");
  const [gptModelEngine, setGptModelEngine] = useState("gpt-3.5-turbo-1106");
  const [sectionsStop, setSectionsStop] = useState("None");

  let [sectionsTemperature, setSectionsTemperature] = useState(0.7);
  let [sectionsMaxToken, setSectionsMaxToken] = useState(4095);
  let [sectionsTopP, setSectionsTopP] = useState(0.9);
  let [frequencyPenalty, setFrequencyPenalty] = useState(0.5);
  let [presencePenalty, setPresencePenalty] = useState(0.6);

  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);
  const modelOptions = ["gpt-3.5-turbo-1106", "gpt-4-1106-preview"];
  const stopOptions = ["None"];
  const [loading, setLoading] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      setError("You must be logged in");
      return;
    }
    let emptyFieldsClientSide = [];
    if (!gptApiKeys) {
      emptyFieldsClientSide.push("gptApiKeys");
    }
    if (!gptModelEngine) {
      emptyFieldsClientSide.push("gptModelEngine");
    }
    if (!sectionsStop) {
      emptyFieldsClientSide.push("sectionsStop");
    }
    if (!sectionsTemperature || sectionsTemperature === null || sectionsTemperature === undefined) {
      sectionsTemperature = 0.7;
    }
    if (!sectionsTopP || sectionsTopP === null || sectionsTopP === undefined) {
      sectionsTopP = 0.9;
    }
    if (!frequencyPenalty || frequencyPenalty === null || frequencyPenalty === undefined) {
      frequencyPenalty = 0.5;
    }
    if (!presencePenalty || presencePenalty === null || presencePenalty === undefined) {
      presencePenalty = 0.6;
    }
    if (emptyFieldsClientSide.length > 0) {
      setEmptyFields(emptyFieldsClientSide);
      setError("Please fill in all fields");
      return;
    }
    setIsSubmitDisabled(true);
    const aiconfig = {
      gptApiKeys,
      gptModelEngine,
      sectionsStop,
      sectionsTemperature,
      sectionsMaxToken,
      sectionsTopP,
      frequencyPenalty,
      presencePenalty,
    };
    setLoading(true);
    const response = await fetch(
      process.env.REACT_APP_SERVER_URL + "/api/aiconfigs",
      {
        method: "POST",
        body: JSON.stringify(aiconfig),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const json = await response.json();

    setLoading(false);
    setIsSubmitDisabled(false);

    if (!response.ok) {
      setError(json.error);
      setEmptyFields(json.emptyFields);
    } else {
      setGptApiKeys("");
      setGptModelEngine("gpt-3.5-turbo-1106");
      setSectionsTemperature(0.7);
      setSectionsMaxToken(4095);
      setSectionsTopP(0.9);
      setSectionsStop("None");
      setFrequencyPenalty(0.5);
      setPresencePenalty(0.6);
      setError(null);
      setEmptyFields([]);
      dispatch({ type: "CREATE_AICONFIG", payload: json });
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "white",
        p: 2,
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        "& > :not(style)": {
          m: 1,
          width: "100%",
        },
      }}
    >

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="h3">
          {"OpenAI Configuration"}
        </Typography>
        {/* <Link to="/user_manual" target="_blank" rel="noopener noreferrer"> */}
          <Tooltip title="Temperature, Max Token, Top P, Frequency Penalty, and Presence Penalty has been diasbled from modifying" placement="top">
            <HelpOutlineIcon sx={{ color: "action.active", '&:hover': { cursor: 'pointer' } }} />
          </Tooltip>
        {/* </Link> */}
      </div>

      <TextField
        id="GptApiKeys"
        label="Gpt Api Keys"
        value={gptApiKeys}
        onChange={(e) => setGptApiKeys(e.target.value)}
        variant="outlined"
        fullWidth
        margin="normal"
        required={true}
        error={emptyFields && emptyFields.includes("gptApiKeys")}
      />

      <FormControl fullWidth error={emptyFields && emptyFields.includes("gptModelEngine")} variant="outlined" margin="normal" required={true}>
        <InputLabel id="gptModelEngine">Model</InputLabel>
        <Select
          labelId="gptModelEngine-label"
          id="gptModelEngine"
          value={gptModelEngine}
          onChange={(e) => setGptModelEngine(e.target.value)}
          label="gptModelEngine"
        >
          <MenuItem value="">
            <em>Model</em>
          </MenuItem>
          {modelOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth error={emptyFields && emptyFields.includes("sectionsStop")} variant="outlined" margin="normal" required={true}>
        <InputLabel id="sectionsStop">Stop</InputLabel>
        <Select
          labelId="sectionsStoplabel"
          id="sectionsStop"
          value={sectionsStop}
          onChange={(e) => setSectionsStop(e.target.value)}
          label="Sections Stop"
        >
          <MenuItem value="">
            <em>Stop</em>
          </MenuItem>
          {stopOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography variant="body2" gutterBottom style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span style={{padding: '5px' }}>Temperature </span>
        <span style={{padding: '5px' }}>{sectionsTemperature}</span>
      </Typography>
      <Tooltip title="Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive." placement="bottom" arrow disableInteractive>
        <Slider
          defaultValue={0.7}
          aria-label="Temperature"
          valueLabelDisplay="auto"
          min={0}
          max={2}
          step={0.01}
          // onChange={(event, newValue) => setSectionsTemperature(newValue)}
          value={sectionsTemperature}
        />
      </Tooltip>

      <Typography variant="body2" gutterBottom style={{ display: 'flex', justifyContent: 'space-between'}}>
        <span style={{padding: '5px' }}>Max Token </span>
        <span style={{padding: '5px' }}>{sectionsMaxToken}</span>
      </Typography>
      <Tooltip title="The Maximum number of tokens to generate shared between the prompt and completion. The exact limit varies by modal. (One token is roughly 4 characters for standard English text)" placement="bottom"  arrow disableInteractive>
        <Slider
          defaultValue={4095}
          aria-label="Max Token"
          valueLabelDisplay="auto"
          min={50}
          max={4095}
          step={1}
          //  onChange={(event, newValue) => setSectionsMaxToken(newValue)}
          value={sectionsMaxToken}
        />
      </Tooltip>

      <Typography variant="body2" gutterBottom style={{ display: 'flex', justifyContent: 'space-between'}}>
        <span style={{padding: '5px' }}>Top P </span>
        <span style={{padding: '5px' }}>{sectionsTopP}</span>
      </Typography>
      <Tooltip title="Controls diversity via nucleus sampling: 0.5 means half of all likelihood-weighted options are considered" placement="bottom" arrow disableInteractive>
        <Slider
          defaultValue={0.9}
          aria-label="Top P"
          valueLabelDisplay="auto"
          min={0}
          max={1}
          step={0.01}
          // onChange={(event, newValue) => setSectionsTopP(newValue)}
          value={sectionsTopP}
        />
      </Tooltip>

      <Typography variant="body2" gutterBottom style={{ display: 'flex', justifyContent: 'space-between'}}>
        <span style={{padding: '5px' }}>Frequency Penalty </span>
        <span style={{padding: '5px' }}>{frequencyPenalty}</span>
      </Typography>
      <Tooltip title="How much to penalize new tokens based on their existing frequency in the text so far. Decreases the model's likelihood to repeat the same line verbatim." placement="bottom" arrow disableInteractive>
        <Slider
          defaultValue={0.5}
          aria-label="Frequency penalty"
          valueLabelDisplay="auto"
          min={0}
          max={2}
          step={0.01}
          // onChange={(event, newValue) => setFrequencyPenalty(newValue)}
          value={frequencyPenalty}
        />
      </Tooltip>

      <Typography variant="body2" gutterBottom style={{ display: 'flex', justifyContent: 'space-between'}}>
        <span style={{padding: '5px' }}>Presence Penalty </span>
        <span style={{padding: '5px' }}>{presencePenalty}</span>
      </Typography>
      <Tooltip title="How much to penalize new tokens based on whether they appear in the text so far. Increases the model's likelihood to talk about new topics." placement="bottom" arrow disableInteractive>
        <Slider
          defaultValue={0.6}
          aria-label="Presence penalty"
          valueLabelDisplay="auto"
          min={0}
          max={2}
          step={0.01}
          // onChange={(event, newValue) => setPresencePenalty(newValue)}
          value={presencePenalty}
        />
      </Tooltip>

      <CenteredDiv>{loading ? <CircularProgress /> : null}</CenteredDiv>

      {error && <ErrorBox error={error} color="error" />}

      <Button
        sx={{
          cursor: "pointer",
          backgroundColor: "#4829B2",
          ":hover": { bgcolor: "#321d7c" },
        }}
        variant="contained"
        onClick={handleSubmit}
        disabled={isSubmitDisabled}
      >
        Add AI Configuration
      </Button>
    </Box>
  );
};

export default AiConfigForm;
