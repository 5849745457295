import React, {useEffect, useState} from "react";
import Footer from "../components/Shared/Footer";
import ArticleView from "../components/Article_Display_Page/ArticleView";
import Breadcrumbs from "../components/Article_Display_Page/Breadcrumbs";
import Navbar from "../components/NavBar/Navbar";

const PrivacyPolicy = () => {
  const [blogArticleDetails, setBlogArticleDetails] = useState({});

  const getArticleFromID = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/blog/privacyPolicy");
      const articleDetails = await response.json();
      setBlogArticleDetails(articleDetails);
    } catch (err) {
      console.error(err.message);
    }
  }
  
  useEffect(() => {
      getArticleFromID();
  }, []);

  return (
    <div>
      <Navbar />
      <Breadcrumbs title={blogArticleDetails.blogTitle} />
      <ArticleView blogArticleDetails={blogArticleDetails} />
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;