import { React, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuthContext } from "./hooks/useContext/useAuthContext";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import WpConfigPage from "./pages/WpConfigPage";
import AiConfigPage from "./pages/AiConfigPage";
import Billing from "./pages/Billing";
import Articles from "./pages/Articles";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import VerificationPage from "./pages/Verification";
import PasswordRecovery from "./pages/PasswordRecovery";
import ResetPassword from "./pages/ResetPassword";
import ResetEmail from "./pages/ResetEmail";
import NewBlogArticle from "./pages/NewBlogArticle";
import Manual from "./pages/Manual";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AcceptableUsePolicy from "./pages/AcceptableUsePolicy";
import SingleBlogArticle from "./pages/SingleBlogArticle";
import Account from "./pages/Account";
import socket from './socket';
import Pricing from "./pages/Pricing";
import Product from "./pages/Product";
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './theme';

function App() {
  const { user } = useAuthContext();

  useEffect(() => {
    function handleConnect() {
      socket.connected = true;
      // setSocketConnected(true);
    }

    function handleDisconnect() {
      socket.connected = false;
      // setSocketConnected(false);
    }

    socket.on('connect', handleConnect);
    socket.on('disconnect', handleDisconnect);

    return () => {
      socket.off('connect', handleConnect);
      socket.off('disconnect', handleDisconnect);
    };
  }, []);


  return (
    <div className="sticky-footer">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={user ? <Dashboard socket={socket} /> : <Navigate to="/login" />} />
          <Route path="/login" element={!user ? <Login /> : <Navigate to="/dashboard" />} />          <Route path="/signup" element={!user ? <Signup /> : <Navigate to="/dashboard" />} />
          <Route path="/wp_configuration" element={user ? <WpConfigPage /> : <Login />} />
          <Route path="/ai_configuration" element={user ? <AiConfigPage /> : <Login />} />
          <Route path="/articles" element={user ? <Articles socket={socket} /> : <Login />} />
          <Route path="/billing" element={user ? <Billing /> : <Login />} />
          <Route path="/password_recovery" element={<PasswordRecovery />} />
          <Route path="/resetpassword/:resetPasswordToken" element={<ResetPassword />} />
          <Route path="/resetemail/:resetEmailToken" element={<ResetEmail />} />
          <Route path="/confirmation/:emailToken" element={<VerificationPage />} />
          <Route path="/confirmation" element={<VerificationPage />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:blogArticleID" element={<SingleBlogArticle />} />
          <Route path="/newblogarticle" element={user ? <NewBlogArticle /> : <Blog />} />
          <Route path="/user_manual" element={<Manual />} />
          <Route path="/terms_and_conditions" element={<TermsAndConditions />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/acceptable_use_policy" element={<AcceptableUsePolicy />} />
          <Route path='/account' element={user ? <Account /> : <Login />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path="/product" element={<Product />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;