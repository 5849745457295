import { useState } from "react";

export const useSignup = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const signup = async (email, password, repeatPassword) => {
    try {
      if (password !== repeatPassword) {
        setError("Passwords do not match");
        return;
      }
      setIsLoading(true);
      setError(null);

      const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/user/signup", {
        method: "POST",
        body: JSON.stringify({ email, password }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();
      if (!response.ok) {
        setIsLoading(false);
        setError(json.error);
      }
      if (response.ok) {
        setIsLoading(false);
        setError(json.message);
      }
    } catch (error) {
      setIsLoading(false);
      setError("An error occurred, please try again");
      console.error(error);
    }
  };
  return { signup, isLoading, error };
};
