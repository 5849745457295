import React from "react";
import { Grid, Typography, Box, useMediaQuery } from "@mui/material";
import PriceCard from "./PriceCard";
import { useTheme } from "@mui/material/styles";
import MySvgIcons from '../Shared/paymentMethods';

const h1Title = "Pricing Plans";
const subtitle = "Try Auto Article Premium+ with a 7 day trial, no API keys required. Pay Securely online and manage your account with ease.";

function PricingSection() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery('(max-width:899px)');
  const pricePlans = [
    {
      title: "Free",
      subheading: "Ideal for Starters",
      textColor: theme.palette.primary.dark,
      pricing: "$0",
      backgroundColor: "",
      features: [
        "Use your own OpenAi API keys",
        "Unlimited campaigns",
        { text: "Unlimited WP site configurations", style: { textDecoration: "line-through" } },
        { text: "SEO optimized articles", style: { textDecoration: "line-through" } },
        { text: "Internal & external link insertions", style: { textDecoration: "line-through" } },
        { text: "Articles with images", style: { textDecoration: "line-through" } },
        { text: "Subject generation feature", style: { textDecoration: "line-through" } },
      ],
    },
    {
      title: "Standard",
      subheading: "Perfect for Growing Businesses",
      textColor: theme.palette.common.white,
      pricing: "$10",
      backgroundColor: theme.palette.primary.dark,
      features: [
        "Use your own OpenAi API keys",
        "Unlimited campaigns",
        "Unlimited WP site configurations",
        "SEO optimized articles",
        "Internal & external link insertions",
        "Articles with images",
        "Subject generation feature",
      ],
    },
    {
      title: "Premium+",
      subheading: "Comprehensive Solution for Professionals",
      textColor: theme.palette.primary.dark,
      pricing: "$30",
      backgroundColor: "",
      features: [
        "No OpenAI API keys required",
        "Unlimited campaigns",
        "Unlimited WP site configurations",
        "SEO optimized articles",
        "Internal & external link insertions",
        "Articles with images",
        "Subject generation feature",
      ],
    },
  ];

  return (
    <Grid container  sx={{
      width: "90%",
      margin: "0 auto",
      display: "flex",
      justifyContent: "center",
      padding: "20px 0px",
    }}>
      <Grid item xs={12}>
        <Grid
          container
          sx={{
            width: isSmallScreen ? "100%" : "90%",
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
            padding: "100px 0px",
          }}
        >
          <Grid item xs={11} sm={11} md={11} lg={11} xl={9.1}>
            <Box sx={{ paddingBottom: '2rem' }}>
            <Typography variant="customFont" gutterBottom>
              {h1Title}
            </Typography>
            </Box>
          </Grid>

          <Grid item xs={11} sm={11} md={11} lg={11} xl={9.1}>
            <Typography variant="subtitle1" gutterBottom>
              {subtitle}
            </Typography>
          </Grid>

          <Grid item xs={11} sm={11} md={11} lg={11} xl={9.1} sx={{paddingBottom: '3rem'}}>
            <MySvgIcons />
          </Grid>

          {pricePlans.map((eachFeature, index) => (
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3.1} key={index} sx={{padding: '15px'}}>
              <PriceCard
                title={eachFeature.title}
                subheading={eachFeature.subheading}
                pricing={eachFeature.pricing}
                features={eachFeature.features}
                backgroundColor={eachFeature.backgroundColor}
                textColor={eachFeature.textColor}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
export default PricingSection;
