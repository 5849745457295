import React from "react";
import PriceSection from "../components/Pricing_Page/PriceSection";
import Footer from "../components/Shared/Footer";
import Navbar from "../components/NavBar/Navbar";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Pricing() {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Pricing | AutoArticle</title>
          <meta name="description" content="Discover our flexible pricing plans tailored for every need, from individual bloggers to large enterprises." />
        </Helmet>
        <Navbar />
        <PriceSection />
        <Footer />
      </div>
    </HelmetProvider>
  );
}

export default Pricing;
