import React, { useState } from "react";
import { useAuthContext } from "../hooks/useContext/useAuthContext";
import { useEmail } from "../hooks/useEmail";
import { useAccountInfo } from "../hooks/useBilling/useAccountInfo";
import { useUserPlanInfo } from "../hooks/useBilling/useUserPlanInfo";
import { useStripeKey } from "../hooks/useBilling/useInitializeStripe";
import { fetchBillingPortal } from "../hooks/useBilling/useFetchBillingPortal";
import { 
  Button,
  TextField,
  Box, Grid,
  CircularProgress,
  Typography, Divider,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  useMediaQuery,
} from "@mui/material";
import { styled } from '@mui/system';
import Navbar from "../components/NavBar/Navbar";
import Footer from "../components/Shared/Footer";
import { useTheme } from '@mui/material/styles';
import MySvgIcons from '../components/Shared/paymentMethods';

const GridContainerMain = styled(Grid)(({theme}) => ({
  minHeight: "100vh",
  paddingLeft:'10%',
  paddingRight: '10%',
  paddingTop: '50px',
  backgroundColor: theme.palette.component.componentBackground,
}));

const Center = styled('div')({
    display: "flex",
    paddingTop: "105px",
    paddingBottom: "105px",
    justifyContent: "center",
});


const Billing = () => {
  const theme = useTheme();
  const { user } = useAuthContext();
  const customer = useAccountInfo(user);
  const userInfo = useUserPlanInfo(user);
  const stripePromise = useStripeKey(user);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [message, setMessage] = useState("");
  const { sendEmail } = useEmail();
  const [buttonText, setButtonText] = useState("Send Email");
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:899px)');

  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  const handleSubmit = async (e) => {
    const email = user.email;
    e.preventDefault();
    setLoading(true);
    await sendEmail( email, message);
    setLoading(false);
    setMessage("");
    setButtonText("Email Sent");
    setIsDisabled(true);
  };

  const handleBuyNow = async () => {
    if (selectedPlan) {
      const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/user/checkout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          selectedPlan,
          customerID: customer.id,
        }),
      })
      if (response.ok) {
        const { sessionId } = await response.json();
        const stripe = await stripePromise;
        stripe.redirectToCheckout({
          sessionId,
        });
      }
    };
  };


  if (!customer) {
    return <Center>Loading...</Center>;
  }

  return (
    <div>
      <Navbar />
      <GridContainerMain container spacing={0} 
        sx={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: theme.palette.common.white,
        }}
      >
        <Grid item xs={12} sm={11} md={10} lg={9} xl={9}>
            <Typography variant="h1" component="h1" paddingBottom="50px">
                Billing
            </Typography>
            <Box sx={{ border: '1px solid #becde2', padding:'40px', marginBottom:'50px', borderRadius: '10px' }}>
              <Typography variant="h6" component="p" paddingBottom="15px">
                Plan Details
              </Typography>

              <Divider color='#becde2'/>
              
              {/* Current Plan */}
              <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: '50px', paddingBottom: '50px' }}>
                <Typography sx={{ minWidth: '180px', color: '#8f8f8f' }} component="p" >
                  Current Plan
                </Typography>
                <Typography component="p" color='#000000'>
                  {userInfo?.plan === 'none' ? (userInfo?.freeTrial ? ('Free Trial' ) : ('No Plan')): (userInfo?.plan)}
                </Typography>
              </Box>
              
              {/* Trial End Date / Last Billing Cycle */}
              <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: '50px' }}>
                <Typography sx={{ minWidth: '180px', color: '#8f8f8f' }} component="p">
                  {userInfo?.plan === 'none' ? (userInfo?.freeTrial ? ('Trial End Date') : ('Last Billing Cycle')) : ('Next Billing Cycle')}
                </Typography>
                <Typography component="p" color='#000000'>
                  {userInfo?.plan === 'none' ? (userInfo?.freeTrial ? (new Date(userInfo.freeTrialEndDate).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })) : (new Date(userInfo?.endDate).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" }))) : (new Date(userInfo?.endDate).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" }))}
                </Typography>
              </Box>

               {/* Plan Options */}
              {userInfo?.plan === 'none' ? ( <>
              <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: '50px' }}>
                <FormControl component="fieldset" sx={{ flexDirection: isSmallScreen ? 'column' : 'row' }}>
                  <FormLabel id="plan-options-label" >
                    <Typography sx={{ minWidth: '180px', color: '#8f8f8f' }} component="p">
                      Plan Options
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="plan-options-group-label"
                    defaultValue="standard"
                    name="subscriptions"
                  >
                    <FormControlLabel value="STANDARD" control={<Radio sx={{ color: '#8f8f8f', '&.Mui-checked': { color: '#4829B2'} }} checked={selectedPlan === 'STANDARD'} onChange={handlePlanChange} />} label="Standard - $10 / month" />
                    <FormControlLabel value="PREMIUM" control={<Radio sx={{ color: '#8f8f8f', '&.Mui-checked': { color: '#4829B2'} }} checked={selectedPlan === 'PREMIUM'} onChange={handlePlanChange} />} label="Premium - $30 / month" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Button
                variant="outlined"
                type="submit"
                onClick={handleBuyNow}
                sx={{
                  borderRadius: '30px',
                  backgroundColor: '#4829B2',
                  borderColor: '#4829B2',
                  color: '#ffffff',
                  textTransform: 'none',
                  ':hover': { 
                    borderColor: '#5A3EB9',
                    backgroundColor: '#5A3EB9',
                  },
                }}
              >
                Update Plan
              </Button>
              </> ) : (<> </>)}
            </Box>



            {/* Billing Information */}
            <Box sx={{ border: '1px solid #becde2', padding:'40px', marginBottom:'50px', borderRadius: '10px' }}>
                <Typography variant="h6" component="p" paddingBottom="15px">
                    Billing Information
                </Typography>

                <Divider color='#becde2'/>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingTop: '50px', paddingBottom: '50px' }}>
                    <Typography component="p" color='#8f8f8f' paddingRight="50px">
                      Not happy with your current plan? Cancel or Upgrade by clicking the button below. 
                    </Typography>
                    <MySvgIcons />
                </Box>
                <Button
                  variant="outlined"
                  type="submit"
                  id="manage-billing-button"
                  onClick={() => fetchBillingPortal(user, customer)}
                  sx={{
                    borderRadius: '30px',
                    backgroundColor: '#4829B2',
                    borderColor: '#4829B2',
                    color: '#ffffff',
                    textTransform: 'none',
                    ':hover': { 
                      borderColor: '#5A3EB9',
                      backgroundColor: '#5A3EB9',
                    },
                  }}
                >
                  Manage Billing
                </Button>
            </Box>

            {/* Contact Us */}
            <Box sx={{ border: '1px solid #becde2', padding:'40px', marginBottom:'50px', borderRadius: '10px' }}>
                <Typography variant="h6" component="p" paddingBottom="15px">
                    Contact Us
                </Typography>

                <Divider color='#becde2'/>

                  <form onSubmit={handleSubmit}>
                    {loading ? 
                    <>
                      <Center>
                        <CircularProgress />
                      </Center>
                    </> : <>
                    <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: '50px', paddingBottom: '50px' }}>
                      <TextField
                        onChange={(event) => setMessage(event.target.value)}
                        variant="outlined"
                        multiline
                        placeholder="Get in touch with us"
                        InputProps={{
                          "aria-label": "Get in Touch",
                          style: {
                            backgroundColor: "#f0f0f0",
                            width: "100%",
                          },
                        }}
                        maxRows={5}
                        minRows={5}
                        fullWidth
                        required
                        value={message}
                      />
                    </Box>
                    </>}
                    <Button
                      variant="outlined"
                      disabled={isDisabled}
                      type="submit"
                      id="contact-us-button"
                      sx={{
                        borderRadius: '30px',
                        backgroundColor: '#4829B2',
                        borderColor: '#4829B2',
                        color: '#ffffff',
                        textTransform: 'none',
                        ':hover': { 
                          borderColor: '#5A3EB9',
                          backgroundColor: '#5A3EB9',
                        },
                        ':disabled': {
                          backgroundColor: '#c4c4c4',
                          color: '#ffffff',
                          borderColor: '#c4c4c4',
                        }
                      }}
                    >
                      {buttonText}
                    </Button>
                  </form>
 
          </Box>
        </Grid>
      </GridContainerMain>
      <Footer />
    </div>
  );
};

export default Billing;