import React, { useState, useEffect, Fragment, forwardRef } from "react";
import { useTopicContext } from "../../hooks/useContext/useTopicContext";
import { useAuthContext } from "../../hooks/useContext/useAuthContext";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import Card from "@mui/material/Card";
import { CardActions } from '@mui/material';
import { CardContent } from '@mui/material';
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import { Collapse } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { MuiChipsInput } from 'mui-chips-input'
import "../../index.css";
import { CircularProgress } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Divider from '@mui/material/Divider';
import { styled } from "@mui/system";
import { useActiveTopicContext } from "../../hooks/useContext/useActiveTopicContext";
import { useUserContext } from "../../hooks/useContext/useUserContext";
import ErrorBox from "../Shared/ErrorBox";
import useUserSubscribed from "../../hooks/useUserSubscribed";
import DraggableChip from "./DragChip";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import LoopIcon from '@mui/icons-material/Loop';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const CustomCardContent = styled(CardContent)({
  overflowWrap: "break-word",
});

const CardRoot = styled(Card)({
  height: "100%",
  backgroundColor: "white",
  p: 2,
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  overflowWrap: "break-word",
  position: "relative",
});

const CustomAccordionContent = styled("div")({
  maxHeight: 200,
  overflowY: "auto",
});

const CustomForm = styled(FormControl)({
  backgroundColor: "white",
  p: 2,
  borderRadius: "8px",
});

const CustomDeleteIcon = styled(DeleteIcon)({
  color: "#b2294d",
});

const CompleteIcon = styled(CheckCircleOutlineIcon)({
  color: "#1EB614",
});

const SolidLine = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "4px",
  backgroundColor: "#b1294e",
});


const TopicDetails = ({ topic, socket }) => {
  const { dispatch } = useTopicContext();
  const { activeTopicsDispatch } = useActiveTopicContext();
  const { user } = useAuthContext();
  let [buttonText, setButtonText] = useState(topic.runningScript ? "Stop" : topic.campaign_completed ? "Completed" : "Start Campaign");
  const [subjectsVisible, setSubjectsVisible] = useState(false);
  const [categoriesVisible, setCategoriesVisible] = useState(false);
  const [articleKeywordVisible, setArticleKeywordVisible] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState(false);
  const [expandedArticleKeyword, setExpandedArticleKeyword] = useState(false);
  const uiClick = "uiClick";
  const subjectCount = topic.subject.length;
  const categoryCount = topic.categories.length;
  const articleKeywordCount = topic.articleKeyword.length;
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedTitle, setTitle] = useState(!topic.title ? "" : topic.title);
  const [editedWpsite, setWpsite] = useState(!topic.wpsite ? "" : topic.wpsite);
  const [editedSubheadingCount, setSubheadingCount] = useState(!topic.subheadingCount ? "" : topic.subheadingCount);
  const [editedPhotoCount, setPhotoCount] = useState(topic.photoCount !== undefined ? topic.photoCount.toString() : "");
  const [editedImgSource, setImgSource] = useState(!topic.imgSource ? "" : topic.imgSource);
  const [editedInterval, setInterval] = useState(!topic.interval ? "" : topic.interval);
  const [editedStatus, setStatus] = useState(!topic.status ? "" : topic.status);
  const [editedCategories, setCategories] = useState(!topic.categories ? [] : topic.categories);
  const [wpCategoriesOptions, setWpCategoriesOptions] = useState([]);
  const [editedSubjects, setSubjects] = useState(!topic.subject ? [] : topic.subject);
  const [editedArticleKeyword, setArticleKeyword] = useState(!topic.articleKeyword ? [] : topic.articleKeyword);
  const [wpSitesOptions, setWpSitesOptions] = useState([]);
  const subheadingOptions = ["0", "2", "3", "4"];
  const photoCountOptions = ["0", "1", "2", "3"];
  const statusOptions = ["draft", "publish"];
  const intervalOptions = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 48, 72];
  const [error, setError] = useState(null);
  const [editedEmptyFields, setEditedEmptyFields] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isSubmitDisabledError, setIsSubmitDisabledError] = useState(false);
  const [isEditDisabled, setIsEditDisabled] = useState(false);
  const { usersDispatch } = useUserContext();
  const userSubscribed = useUserSubscribed();
  const [isReordering, setIsReordering] = useState(false);
  const [editedCampaignType] = useState(!topic.campaignType ? "" : topic.campaignType);

  const handleImgSourceChange = (event) => {
    setImgSource(event.target.value);
  };

  //replace display html to text for & 
  const replaceAmpersand = (text) => {
    if (typeof text === 'string') {
      return text.replace(/&amp;/g, "&");
    } else {
      return text;
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
    setSubjectsVisible(!subjectsVisible)
  };

  const handleExpandArticleKeywordClick = () => {
    setExpandedArticleKeyword(!expandedArticleKeyword);
    setArticleKeywordVisible(!articleKeywordVisible)
  };

  const handleExpandCategoriesClick = () => {
    setExpandedCategories(!expandedCategories);
    setCategoriesVisible(!categoriesVisible)
  };

  const handleEditClick = () => {
    if (editMode === true) {
    setEditMode(false);
    setWpsite(!topic.wpsite ? "" : topic.wpsite);
    setTitle(!topic.title ? "" : topic.title);
    setSubheadingCount(!topic.subheadingCount ? "" : topic.subheadingCount);
    setPhotoCount(topic.photoCount !== undefined ? topic.photoCount.toString() : "");
    setImgSource(!topic.imgSource ? "" : topic.imgSource)
    setStatus(!topic.status ? "" : topic.status);
    setInterval(!topic.interval ? "" : topic.interval);
    setCategories(!topic.categories ? [] : topic.categories);
    setSubjects(!topic.subject ? [] : topic.subject);
    setArticleKeyword(!topic.articleKeyword ? [] : topic.articleKeyword);

    } else if (editMode === false) {
    setEditMode(true);
    }
  };

  const amount = () => {
    if (topic.subject.length === 1) {
      return "1 Subject";
    } else {
      return ` All ${topic.subject.length} Subjects`;
    }
  }

  const amountCategories = () => {
    if (topic.categories.length === 1) {
      return "1 Category";
    } else {
      return ` All ${topic.categories.length} Categories`;
    } 
  }
  
  const amountArticleKeyword = () => {
    if (topic.articleKeyword.length === 1) {
      return "1 Keyword";
    } else {
      return ` All ${topic.articleKeyword.length} Keywords`;
    } 
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
  
    setCategories(value);
  };

  const toggleReorder = () => {
    setIsReordering(!isReordering);
  };

  //handle subject change for subject chips
  const handleSubjectChange = (newSubjectChips) => {
    const uniqueChips = Array.from(new Set(newSubjectChips));
    setSubjects(uniqueChips);
  };

  const moveChip = (dragIndex, hoverIndex) => {
    setSubjects((prevSubjects) => {
      const newSubjects = [...prevSubjects];
      const dragChip = newSubjects[dragIndex];
      newSubjects.splice(dragIndex, 1);
      newSubjects.splice(hoverIndex, 0, dragChip);
      return newSubjects;
    });
  };

  const handleArticleKeywordChange = (newArticleKeywordChips) => {
    setArticleKeyword(newArticleKeywordChips);
  };

  const ForwardedCollapse = forwardRef((props, ref) => (
    <Collapse ref={ref} {...props} />
  ));


  //setWp Categories Options
  useEffect(() => {
    if (!editedWpsite) {
      setWpCategoriesOptions([]);
      setCategories([]);
    }

    if (editMode) {
      setCategories(topic.categories);
    }
  }, [editMode, setCategories, topic.categories, editedWpsite]);


  //setWp Sites Options
  useEffect(() => {
    if (topic.campaign_completed) {
      setButtonText("Completed");
      setIsSubmitDisabled(true);
    }

    if (buttonText === "Completed") {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }

    if (buttonText === "Stop") {
      setIsEditDisabled(true);
    } else {
      setIsEditDisabled(false);
    }

    const fetchWpConfigs = async () => {
      const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/wpconfigs", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      
      if (response.ok) {
        const json = await response.json();
        const wpUrls = json.map((item) => ({
          url: item.WpUrl,
          categories: item.siteCategories,
        }));
        
        setWpSitesOptions(wpUrls.map((site) => site.url));

        if (editedWpsite) {
          setCategories([]);
          const selectedWpSite = wpUrls.find((site) => site.url === editedWpsite);
          if (selectedWpSite) {
            setWpCategoriesOptions(selectedWpSite.categories);
          }
        }
      }
    };

    if (user) {
      fetchWpConfigs();
    }
  }, [dispatch, user, buttonText, editedWpsite, topic.campaign_completed]);



  //create new rooms on each new render for each active topic
  useEffect(() => {
    // Function to join a socket room for a given topic ID
    const joinSocketRoom = (roomId) => {
      socket.emit('joinRoom', roomId);
    };

    // Function to leave a socket room for a given topic ID
    const leaveSocketRoom = (roomId) => {
      socket.emit('leaveRoom', roomId);
    };

    // Join socket rooms on component mount
    if (topic.runningScript) {
      joinSocketRoom(topic._id);
    }

    // Clean up: Leave socket rooms on component unmount
    return () => {
      if (topic.runningScript) {
        leaveSocketRoom(topic._id);
      }
    };
  }, [socket, topic._id, topic.runningScript]);



  // useEffect(() => {
  //   socket.on('status', (data) => {
  //     console.log(`Status ${data}`);
  //   });

  //   return () => {
  //     socket.off('status', (data) => {
  //       console.log(`Status: ${data}`);
  //     }
  //     );
  //   };
  // }, [socket]);


  if (!user) {
    return null;
  }

  const handleDelete = async () => {
    const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/topics/" + topic._id, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();

    if (response.ok) {
      dispatch({ type: "DELETE_TOPIC", payload: json });
    }
  };

  const handleRun = async () => {
    setIsSubmitDisabled(true);
    setIsEditDisabled(true);
    if (buttonText === "Start Campaign") {
      activeTopicsDispatch({ type: "ADD_ACTIVE_TOPIC", payload: topic });
    }
    const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/topics/initiateRunTopic/" + topic._id, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({ uiClick }),
    });
    const json = await response.json();

    if (response.ok) {
      setButtonText(json.topic.scriptStatus);
      // dispatch({ type: "RUN_TOPIC", payload: json });
      if (json.topic.scriptStatus === "Stop") {
        setIsSubmitDisabled(false);
      } else if (json.topic.scriptStatus === "Start Campaign") {
        activeTopicsDispatch({ type: "REMOVE_ACTIVE_TOPIC", payload: topic });
        setIsSubmitDisabled(false);
      } else if (json.topic.scriptStatus === "Campaign Complete") {
        activeTopicsDispatch({ type: "REMOVE_ACTIVE_TOPIC", payload: topic });
      } else {
        activeTopicsDispatch({ type: "REMOVE_ACTIVE_TOPIC", payload: topic });
        setIsSubmitDisabledError(true);
      }
        const fetchUserConfigs = async () => {
          const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/user/", {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          });
          const json = await response.json();
          if (response.ok) {
            usersDispatch({ type: "SET_USERCONFIG", payload: json });
          }
        };

        const fetchTopics = async () => {
          const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/topics", {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          });
          const json = await response.json();
          if (response.ok) {
            dispatch({ type: "SET_TOPICS", payload: json });
            activeTopicsDispatch({ type: "SET_ACTIVE_TOPICS", payload: json });
          }
        };

        fetchTopics();
        fetchUserConfigs();
      }
  };


  const handleSaveClick = async (e) => {
    e.preventDefault();
    
    if (!user) {
      setError("You must be logged in");
      return;
    }

    let emptyFieldsClientSide = [];

    if (!editedWpsite && editedCampaignType !== "multi") {
      emptyFieldsClientSide.push("wpsite");
    }
    if (!editedTitle) {
      emptyFieldsClientSide.push("title");
    }
    if (!editedSubheadingCount) {
      emptyFieldsClientSide.push("subheadingCount");
    }
    if (!editedPhotoCount) {
      emptyFieldsClientSide.push("photoCount");
    }
    if (editedPhotoCount && !editedImgSource && editedPhotoCount !== '0') {
      emptyFieldsClientSide.push("imgSource");
    }
    if (!editedStatus) {
      emptyFieldsClientSide.push("status");
    }
    if (editedSubjects.length === 0) {
      emptyFieldsClientSide.push("subject");
    }
    if (!editedInterval) {
      emptyFieldsClientSide.push("interval");
    }
    if (emptyFieldsClientSide.length > 0) {
      setEditedEmptyFields(emptyFieldsClientSide);
      setError("Please fill in all fields");
      return;
    }

    const categoryIds = editedCategories

    const updatedTopic = {
      title: editedTitle,
      wpsite: editedCampaignType === "multi" ? "" : editedWpsite,
      subheadingCount: editedSubheadingCount,
      photoCount: editedPhotoCount,
      imgSource: editedImgSource,
      interval: editedInterval,
      status: editedStatus,
      categories: categoryIds,
      subject: editedSubjects,
      articleKeyword: editedArticleKeyword,
    };


    const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/topics/update/" + topic._id, {
      method: "PUT",
      body: JSON.stringify(updatedTopic),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();
    if (response.ok) {
      setIsReordering(false);
      setEditMode(false);
      dispatch({ type: "UPDATE_TOPIC", payload: json });
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <CardRoot variant="outlined">
        {isEditDisabled ? (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {topic.runningScript ? (
              <IconButton
                size="large"
                aria-label="Edit"
                aria-haspopup="true"
                color="#343a40"
              >
                <Tooltip title="Campaign is Active" placement="top">
                  <DirectionsRunIcon />
                </Tooltip>
              </IconButton>
            ) : <IconButton
                size="large"
                aria-label="Loading"
                aria-haspopup="true"
                color="#343a40"
              >
                <Tooltip title="Loading..." placement="top">
                  <LoopIcon />
                </Tooltip>
              </IconButton>}
          </div>
        ) : ( 
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {topic.campaign_completed ? (
              <IconButton
                size="large"
                aria-label="Edit"
                aria-haspopup="true"
                color="#343a40"
              >
                <Tooltip title="Campaign Complete" placement="top">
                  <CompleteIcon />
                </Tooltip>
              </IconButton>
            ) : (
              <IconButton
                size="large"
                aria-label="Edit"
                aria-haspopup="true"
                onClick={handleEditClick}
                color="#343a40"
              >
                <Tooltip title="Edit Topic" placement="top">
                  <EditIcon />
                </Tooltip>
              </IconButton>
            )}
              <IconButton
                sx={{'&:hover': {backgroundColor: '#f2eeef' }}}
                size="large"
                aria-label="Delete"
                aria-haspopup="true"
                ariant="outlined"
                onClick={handleClickOpen}
                color="#343a40"
              >
                <Tooltip title="Delete Campaign" placement="top">
                  <CustomDeleteIcon />
                </Tooltip>
              </IconButton>

          </div>
        )}
        <Typography variant="body1" component="p" sx={{ display: 'flex', justifyContent: 'space-between'}}>
          <span style={{padding: '20px' }}> {topic.campaignType === "multi" ? "Multi Site Campaign" : "Single Site Campaign"} </span>
        </Typography>
        <CustomCardContent>
          <SolidLine />
          {editMode ? (
            <>
            <CustomForm fullWidth error={editedEmptyFields && editedEmptyFields.includes("wpsite")} variant="outlined" margin="normal" required={editedCampaignType === "single"}>
              <InputLabel id="wp-site-label">Site</InputLabel>
              <Select
                labelId="wp-site-label"
                id="wp-site"
                value={editedCampaignType === "multi" ? "" : editedWpsite}
                onChange={(e) => setWpsite(e.target.value)}
                disabled={editedCampaignType === "multi" ? true : false}
                label="Site"
              >
                <MenuItem value="">
                  <em>Choose WordPress Site</em>
                </MenuItem>
                {[...wpSitesOptions].sort().map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </CustomForm>

            <TextField
              required={true}
              id="topic-title"
              label="Topic Title"
              value={editedTitle}
              onChange={(e) => setTitle(e.target.value)}
              variant="outlined"
              fullWidth
              margin="normal"
              error={editedEmptyFields && editedEmptyFields.includes("title")}
            />

            <CustomForm fullWidth error={editedEmptyFields && editedEmptyFields.includes("subheadingCount")} variant="outlined" margin="normal" required={true}>
              <InputLabel id="subheading-count-label">Subheading Count</InputLabel>
              <Select
                labelId="subheading-count-label"
                id="subheading-count"
                value={editedSubheadingCount}
                onChange={(e) => setSubheadingCount(e.target.value)}
                label="Subheading Count"
              >
                <MenuItem value="">
                  <em>Choose Subheading Count</em>
                </MenuItem>
                {subheadingOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </CustomForm>

            <CustomForm fullWidth error={editedEmptyFields && editedEmptyFields.includes("photoCount")} variant="outlined" margin="normal" required={false} disabled={!userSubscribed}>
              <InputLabel id="photo-count-label">Photo Count</InputLabel>
              <Select
                labelId="photo-count-label"
                id="photo-count"
                value={editedPhotoCount}
                onChange={(e) => setPhotoCount(e.target.value)}
                label="Photo Count"
              >
                <MenuItem value="">
                  <em>Choose Photo Count</em>
                </MenuItem>
                {photoCountOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </CustomForm>

            <FormControl error={editedEmptyFields && editedEmptyFields.includes("imgSource")} disabled={!userSubscribed}>
              <FormLabel id="image-source-group">Image Source</FormLabel>
              <RadioGroup
                row
                aria-labelledby="image-source-group"
                name="controlled-image-source-group"
                value={editedImgSource}
                onChange={handleImgSourceChange}
              >
                <FormControlLabel value="Stock" control={<Radio />} label="Stock Images" />
                <FormControlLabel value="Google" control={<Radio />} label="Google Images" />
              </RadioGroup>
            </FormControl>

            <CustomForm fullWidth error={editedEmptyFields && editedEmptyFields.includes("status")} variant="outlined" margin="normal" required={true}>
              <InputLabel id="status-label">Publishing Status</InputLabel>
              <Select
                labelId="status-label"
                id="status"
                value={editedStatus}
                onChange={(e) => setStatus(e.target.value)}
                label="Publishing Status"
              >
                <MenuItem value="">
                  <em>Choose a Status</em>
                </MenuItem>
                {statusOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </CustomForm>

            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel id="category-chip-label">Categories</InputLabel>
              <Select
                labelId="category-chip-label"
                id="categories"
                multiple
                value={editedCategories}
                onChange={handleChange}
                disabled={editedCampaignType === "multi" ? true : false}
                input={<OutlinedInput id="select-multiple-chip" label="categories-chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => {
                      const category = wpCategoriesOptions.find((option) => option.id === value);
                      return category ? <Chip key={category.id} label={replaceAmpersand(category.name)} /> : null;
                    })}
                  </Box>
                )}
              >
                <MenuItem disabled value="">
                  <em>Choose a site to view categories</em>
                </MenuItem>
                {wpCategoriesOptions.map((category) => (
                  <MenuItem
                    key={category.id}
                    value={category.id}
                  >
                    {replaceAmpersand(category.name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '20px'}}>
              {!isReordering && (
                <MuiChipsInput
                  key='subjectInput'
                  fullWidth
                  variant="outlined"
                  id="subjects"
                  name="subjects"
                  placeholder="Enter key to add subjects"
                  label="Subjects"
                  error={editedEmptyFields && editedEmptyFields.includes("subject")}
                  required={true}
                  value={editedSubjects}
                  onChange={handleSubjectChange}
                  onPaste={(event) => {
                    const pastedContent = event.clipboardData.getData("text");
                    const pastedSubjects = pastedContent.split(/\r?\n/);
                    setSubjects([...editedSubjects, ...pastedSubjects]);
                    event.preventDefault();
                  }}
                />
              )}
              {isReordering && (
                <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px', border: '1px solid #ccc', borderRadius: '5px', padding: '10px' }}>
                  {editedSubjects.map((chip, index) => (
                    <DraggableChip
                      key={`${chip}-${index}`} 
                      id={chip}
                      text={chip}
                      index={index}
                      moveChip={moveChip}
                    />
                  ))}
                </Box>
              )}
                <Typography variant='body2' onClick={toggleReorder} style={{cursor: 'pointer'}}>
                  {isReordering ? 'Close' : 'Rearrange Subjects'}
                </Typography>
            </div>

            <MuiChipsInput
              sx={{
                width: "100%",
                margin: "15px 0"
              }}
              value={editedArticleKeyword}
              onChange={handleArticleKeywordChange}
              disabled={!userSubscribed}
              fullWidth
              variant="outlined"
              id="articleKeyword"
              name="articleKeyword"
              placeholder="Enter key to add keyword"
              label="Article Keyword"
              onPaste={(event) => {
                const pastedKeywordContent = event.clipboardData.getData("text");
                const pastedkeywords = pastedKeywordContent.split(/\r?\n/);
                setArticleKeyword([...editedArticleKeyword, ...pastedkeywords]);
                event.preventDefault();
              }}
            />

            <CustomForm fullWidth error={editedEmptyFields && editedEmptyFields.includes("interval")} variant="outlined" margin="normal" required={true}>
              <InputLabel id="interval-label">Publishing Interval</InputLabel>
              <Select
                labelId="interval-label"
                id="interval"
                value={editedInterval}
                onChange={(e) => setInterval(e.target.value)}
                label="Publishing Interval"
              >
                <MenuItem value="">
                  <em>Hours:</em>
                </MenuItem>
                {intervalOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </CustomForm>

            {error && <ErrorBox error={error} color='error'/>}

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button variant="contained" type="submit" sx={{ cursor: "pointer", backgroundColor: '#4829B2', ':hover': {bgcolor: '#321d7c'}, marginTop: "1rem" }} onClick={handleSaveClick}>Save Changes</Button>
              <Button variant="contained" type="submit" sx={{ cursor: "pointer", backgroundColor: 'white', color:'black', ':hover': {bgcolor: '#f5f5f5'}, marginTop: "1rem", marginLeft: '20px', marginBottom: '0px' }} onClick={handleEditClick}>Cancel</Button>
            </div>
            </>
            ) : (
            <>
            <Tooltip title="Campaign Title" placement="top">
              <Typography variant="h6" component="h6" sx={{ padding: '5px' }}>
                {topic.title}
              </Typography>
            </Tooltip>
            <Divider sx={{margin:'10px'}} variant="middle" />
            <Typography variant="body2" component="p" sx={{ display: 'flex', justifyContent: 'space-between'}}>
              <span style={{padding: '5px' }}> <strong>Site </strong> </span>
              <span style={{ padding: '5px' }}>
                {topic.campaignType === "multi" ? (
                  topic.wpMultiSites.length === 0 ? "All Sites" :
                  topic.wpMultiSites.slice(0, topic.wpMultiSites.length).map((site, index) => (
                    <span key={index} style={{ display: 'block', paddingBottom: '5px' }}>
                      <strong>{index + 1}. </strong> {site}
                    </span>
                  ))
                  ) : (
                  topic.wpsite
                )}
              </span>
            </Typography>
            <Divider sx={{margin:'10px'}} variant="middle" />
            <Typography variant="body2" component="p" sx={{ display: 'flex', justifyContent: 'space-between'}}>
              <span style={{padding: '5px' }}> <strong>Subheading count </strong> </span>
              <span style={{padding: '5px' }}> {topic.subheadingCount} </span>
            </Typography>
            <Divider sx={{margin:'10px'}} variant="middle" />
            <Typography variant="body2" component="p" sx={{ display: 'flex', justifyContent: 'space-between'}}>
              <span style={{padding: '5px' }}> <strong>Photo Count </strong> </span>
              <span style={{padding: '5px'}}>{topic.photoCount}</span>
            </Typography>
            <Divider sx={{margin:'10px'}} variant="middle" />
            <Typography variant="body2" component="p" sx={{ display: 'flex', justifyContent: 'space-between'}}>
              <span style={{padding: '5px' }}> <strong>Image Source </strong> </span>
              <span style={{padding: '5px'}}>{topic.imgSource}</span>
            </Typography>
            <Divider sx={{margin:'10px'}} variant="middle" />
            <Typography variant="body2" component="p" sx={{ display: 'flex', justifyContent: 'space-between'}}>
              <span style={{padding: '5px' }}> <strong>Interval Time </strong> </span>
              <span style={{padding: '5px' }}> every {topic.interval} hours </span>
            </Typography>
            <Divider sx={{margin:'10px'}} variant="middle" />
            <Typography variant="body2" component="p"  sx={{ display: 'flex', justifyContent: 'space-between'}}>
              <span style={{padding: '5px' }}> <strong>Status </strong> </span>
              <span style={{padding: '5px' }}> {topic.status} </span>
            </Typography>
            <Divider sx={{margin:'10px'}} variant="middle" />

            <Typography variant="body2" component="span" sx={{ padding: '5px' }}>
              <strong>Categories </strong>
            </Typography>
            {topic.categories.length > 0 ? (
              <>
                <ForwardedCollapse in={expandedCategories} sx={{padding: '5px'}}>
                  <CustomAccordionContent>
                    {categoriesVisible ? (
                      topic.categories.map((item, index) => (
                        <Fragment key={index}>
                          <Typography variant="body2" paddingBottom='5px'>
                            <span><strong>{index + 1}. </strong></span>
                              {replaceAmpersand(wpCategoriesOptions.find((option) => option.id === item)?.name)}
                              <br />
                          </Typography>
                        </Fragment>
                      ))
                    ) : (
                      topic.categories.slice(0, categoryCount).map((item, index) => (
                        <Fragment key={index}>
                          <span>{index + 1}. </span>
                          {wpCategoriesOptions.find((option) => option.id === item)?.name}
                          <br />
                        </Fragment>
                      ))
                    )}
                  </CustomAccordionContent>
                </ForwardedCollapse>
                <Button onClick={handleExpandCategoriesClick}>
                  {expandedCategories ? "Close" : "See " + amountCategories()}
                </Button>
              </>
            ) : (
              <Button disabled={true}>No Categories Set</Button>
            )}


          <Divider sx={{margin:'10px'}} variant="middle" />
          <Typography variant="body2" component="span" sx={{ padding: '5px' }}>
            <strong>Article Keywords </strong>
          </Typography>
            {topic.articleKeyword.length > 0 ? (
              <>
                <ForwardedCollapse in={expandedArticleKeyword} sx={{padding: '5px'}}>
                  <CustomAccordionContent>
                    {articleKeywordVisible ? (
                      topic.articleKeyword.map((item, index) => (
                        <Fragment key={index}>
                          <Typography variant="body2" paddingBottom='5px'>
                            <span><strong>{index + 1}. </strong></span>
                            {item}
                          </Typography>
                        </Fragment>
                      ))
                    ) : (
                      topic.articleKeyword.slice(0, articleKeywordCount).map((item, index) => (
                        <Fragment key={index}>
                          <Typography variant="body2">
                            <span>{index + 1}. </span>
                            {item}
                          </Typography>
                        </Fragment>
                      ))
                    )}
                  </CustomAccordionContent>
                </ForwardedCollapse>
                <Button onClick={handleExpandArticleKeywordClick}>
                  {expandedArticleKeyword ? "Close" : 'See ' + amountArticleKeyword()}
                </Button>
              </>
            ) : (
              <Button disabled={true}>No keywords available</Button>
            )}

            <Divider sx={{margin:'10px'}} variant="middle" />
            <Typography variant="body2" component="span" sx={{ padding: '5px' }}>
              <strong>Subjects </strong>
            </Typography>
              {topic.subject.length > 0 ? (
                <>
                  <ForwardedCollapse in={expanded} sx={{padding: '5px'}}>
                    <CustomAccordionContent>
                      {subjectsVisible ? (
                        topic.subject.map((item, index) => (
                          <Fragment key={index}>
                            <Typography variant="body2" paddingBottom='5px'>
                              <span><strong>{index + 1}. </strong></span>
                              {item}
                            </Typography>
                          </Fragment>
                        ))
                      ) : (
                        topic.subject.slice(0, subjectCount).map((item, index) => (
                          <Fragment key={index}>
                            <Typography variant="body2">
                              <span>{index + 1}. </span>
                              {item}
                            </Typography>
                          </Fragment>
                        ))
                      )}
                    </CustomAccordionContent>
                  </ForwardedCollapse>
                  <Button onClick={handleExpandClick}>
                    {expanded ? "Close" : 'See ' + amount()}
                  </Button>
                </>
              ) : (
                <Button disabled={true}>No Subjects available</Button>
              )}
            <Typography variant="subtitle1" align="center" sx={{padding: '10px'}}>
              {isSubmitDisabled && !topic.campaign_completed && "Article in Progress..."}
              {isSubmitDisabled && topic.campaign_completed && "Campaign Completed!"}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '10px'}}>
              {isSubmitDisabled && !topic.campaign_completed && (<CircularProgress color="inherit" />)}
              <br />
              <Tooltip title="Toggle Campaign" placement="top">
                <Button disabled={isSubmitDisabled || isSubmitDisabledError} sx={{ backgroundColor: '#4829B2', ':hover': {bgcolor: '#321d7c'}}} aria-label="center"  variant="contained" onClick={handleRun}>{buttonText}</Button>
              </Tooltip>
            </Box>
            </>
            )
          }
        </CustomCardContent>
        <CardActions style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Delete Topic</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this Topic?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button sx={{ backgroundColor: '#4829B2', color:'white', ':hover': {bgcolor: '#321d7c'}}} onClick={handleDelete} autoFocus>
                Delete
              </Button>
              <Button sx={{ backgroundColor: 'white', color:'black', ':hover': {bgcolor: '#f5f5f5'}}} onClick={handleClose}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <div style={{ display: "flex", justifyContent: "center", margin: 'auto'}}>
            <Typography variant="body2" component="span">
              {formatDistanceToNow(new Date(topic.createdAt), { addSuffix: true })}
            </Typography>
          </div>
        </CardActions>
      </CardRoot>
    </DndProvider>
  );
};

export default TopicDetails;