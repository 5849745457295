import React, {useState} from 'react';
import { AppBar, Toolbar, Typography, Box, Link, IconButton, Drawer } from '@mui/material';
import { useAuthContext } from "../../hooks/useContext/useAuthContext";
import HomeNavNoUserButtons from "./HomeNavNoUserButtons";
import HomeNavDashButton from "./HomeNavDashButton";
import MenuIcon from '@mui/icons-material/Menu';
import DrawerContent from "./DrawerContent";
import Logo from "../Shared/Logo";
import { useTheme } from '@mui/material/styles';



export default function Navbar() {
  const { user } = useAuthContext();
  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
  const theme = useTheme();


  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setHamburgerMenuOpen(open);
  };


  return (
    <AppBar
      position="sticky"
      color='default'
      elevation={1}
      sx={{
        backgroundColor: theme.palette.common.white,
      }}>

      <Toolbar sx={{ justifyContent: 'space-between' }}>

        <Logo logoHeight={'36px'} />

        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'none', lg: 'flex' }, justifyContent: 'flex-end', alignItems: 'center' }}>

          <Link href="/product" sx={{ p: 1, textDecoration: 'none', mr: 3 }}>
            <Typography variant="body1">
              Product
            </Typography>
          </Link>
          <Link href="/pricing" color="inherit" sx={{ p: 1, textDecoration: 'none', mr: 3 }}>
            <Typography variant="body1">
              Pricing
            </Typography>
          </Link>
          <Link href="/blog" color="inherit" sx={{ p: 1, textDecoration: 'none', mr: 3 }}>
            <Typography variant="body1">
              Blog
            </Typography>
          </Link>
          <Link href="/user_manual" color="inherit" sx={{ p: 1, textDecoration: 'none', mr: 3 }}>
            <Typography variant="body1">
              Docs
            </Typography>
          </Link>
          {/* <Link href="/careers" color="inherit" sx={{ p: 1, textDecoration: 'none', mr: 3 }}>
            <Typography variant="body1">
              Careers
            </Typography>
          </Link>
          <Link href="/contact" color="inherit" sx={{ p: 1, textDecoration: 'none', mr: 3 }}>
            <Typography variant="body1">
              Contact
            </Typography>
          </Link> */}
        </Box>

        <Box sx={{ display: { xs: 'none', md: 'none', lg: 'flex' } }}>
          { user ? (
            <HomeNavDashButton mobileView={false}/>
          ) : (
            <HomeNavNoUserButtons mobileView={false} />
          )}
        </Box>

        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ 
            display: { xs: 'block', md: 'block', lg: 'none' },
            borderRadius: '50%', //circular shape
            padding: '10px',
            width: '48px',
            height: '48px', // Set sameas width to maintain circle shape
          }}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon sx={{ color: '#8f8f8f' }} />
        </IconButton>

        <Drawer
          anchor='bottom'
          open={hamburgerMenuOpen}
          onClose={toggleDrawer(false)}
          sx={{
            '& .MuiDrawer-paper': { 
              marginTop: '64px', 
              height: `calc(100% - 64px)`
            },
          }}
        >
          <DrawerContent user={user} toggleDrawer={toggleDrawer} theme={theme} />
        </Drawer>

      </Toolbar>
    </AppBar>
  );
}