import React from "react";
import { styled } from "@mui/system";

const SVGClass = styled("div")({
  zIndex: "1",
  position: "relative",
  top: "2%",
  left: "0%",
  width: "35%",
  "@media (max-width: 900px)": {
    width: "100%",
    height: "auto",
  },
});

const CurvedLineSVG = () => {
  return (
    <SVGClass>
      <svg
        width="178"
        height="42"
        viewBox="0 0 178 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.403955 17.819C-2.02962 22.7059 7.41993 23.8081 11.3464 26.5187C17.9561 31.0798 26.4085 33.999 35.4494 36.2482C54.5321 41.0024 75.0425 42.8356 97.1921 40.9523C113.001 39.6069 128.393 36.1024 142.767 29.1921C155.329 23.1518 165.953 15.7743 174.278 6.94873C175.137 6.0362 175.868 5.07403 176.533 4.10963C176.938 3.52055 177.277 2.85792 177.308 2.27035C177.334 1.71033 177.403 0.873012 176.713 0.676066C176.023 0.47912 174.946 1.10545 174.262 1.49492C173.384 1.99425 172.683 2.65914 171.96 3.2809C168.236 6.48419 164.609 9.742 160.798 12.8999C149.733 22.0728 136.186 28.192 120.531 31.7186C106.496 34.8835 92.685 36.1092 79.0851 35.6474C62.6132 35.0884 47.1244 32.9793 33.3104 28.1382C24.7177 25.127 11.8 15.9276 11.8 15.9276C11.8 15.9276 11.2635 15.4128 10.8783 15.1358C7.45007 12.6706 2.28625 14.0392 0.403955 17.819Z"
          fill="white"
        />
      </svg>
    </SVGClass>
  );
};

export default CurvedLineSVG;
