import React, { useEffect, useState } from "react";
import { useWpConfigContext } from "../../hooks/useContext/useWpConfigContext";
import { useAuthContext } from "../../hooks/useContext/useAuthContext";
import ErrorBox from "../Shared/ErrorBox";
import { Visibility, VisibilityOff, HelpOutline } from "@mui/icons-material";
import { MuiChipsInput } from "mui-chips-input";
import {
  InputAdornment,
  IconButton,
  Box,
  TextField,
  Button,
  Tooltip,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  Link,
} from "@mui/material";
import { styled } from "@mui/system";
import useUserSubscribed from "../../hooks/useUserSubscribed";

const CenteredDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const WpConfigForm = () => {
  const { dispatch } = useWpConfigContext();
  const { user } = useAuthContext();
  const [WpUrl, setWpUrl] = useState("https://");
  const [WpUser, setWpUser] = useState("");
  const [WpPassword, setWpPassword] = useState("");
  const [longTailKeywords, setLongTailKeywords] = useState([]);
  let [internalLinks, setInternalLinks] = useState("");
  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);
  const [emptyFieldsURL, setEmptyFieldsURL] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [imgStatus, setImgStatus] = useState("");
  const internalLinksOptions = ["No", "Yes"];
  const imgStatusOptions = ["No", "Yes"];
  const userSubscribed = useUserSubscribed();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (!WpUrl.startsWith("http://") && !WpUrl.startsWith("https://")) {
      setError(
        "Invalid WordPress URL. It should start with 'http://' or 'https://'."
      );
      setEmptyFieldsURL(["WpUrl"]);
      setIsSubmitDisabled(true);
    } else {
      setEmptyFieldsURL([]);
      setError(null);
      setIsSubmitDisabled(false);
    }
  }, [WpUrl]);

  const handleLongTailChange = (newLongTailChips) => {
     // Regular expression that matches the characters you want to exclude
  const excludeChars = /[.?!</>,;:]/;
  // Filter out any chips that contain the excluded characters
  const filteredChips = newLongTailChips.filter(chip => !excludeChars.test(chip));
  setLongTailKeywords(filteredChips);
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      setError("You must be logged in");
      return;
    }

    let emptyFieldsClientSide = [];
    if (!WpUrl) {
      emptyFieldsClientSide.push("WpUrl");
    }
    if (!WpUser) {
      emptyFieldsClientSide.push("WpUser");
    }
    if (!WpPassword) {
      emptyFieldsClientSide.push("WpPassword");
    }
    if (!imgStatus) {
      emptyFieldsClientSide.push("imgStatus");
    }
    // if (!internalLinks) {
    //   emptyFieldsClientSide.push("internalLinks");
    // }
    if (internalLinks === "Yes" && longTailKeywords.length === 0) {
      emptyFieldsClientSide.push("internalLinks");
      setError(
        "Please add at least one link insertion keyword to enable internal links"
      );
      return;
    }
    if (emptyFieldsClientSide.length > 0) {
      setEmptyFields(emptyFieldsClientSide);
      setError("Please fill in all fields");
      return;
    }
    if (!internalLinks || internalLinks === "") {
      internalLinks = 'No';
    }

    const wpconfig = {
      WpUrl,
      WpUser,
      WpPassword,
      imgStatus,
      longTailKeywords,
      internalLinks,
    };
    setLoading(true);
    const response = await fetch(
      process.env.REACT_APP_SERVER_URL + "/api/wpconfigs",
      {
        method: "POST",
        body: JSON.stringify(wpconfig),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const json = await response.json();
    setLoading(false);
    if (!response.ok) {
      if (
        response.status === 400 &&
        json.error.includes("Site already configured")
      ) {
        setError("This site is already in use by a user");
      } else if (
        response.status === 400 &&
        json.error.includes("A subscription is required")
      ) {
        setError(
          "A subscription is required to connect any additional WordPress Site Configuration"
        );
      } else if (response.status === 400 || json.error.includes("ENOTFOUND")) {
        setError(
          "Error establishing connection. Please review your credentials."
        );
      } else {
        setError(json.error);
      }
      setEmptyFields(json.emptyFields);
    } else if (response.ok) {
      setWpUrl("https://");
      setWpUser("");
      setWpPassword("");
      setImgStatus("");
      setLongTailKeywords([]);
      setInternalLinks("");
      setError(null);
      setEmptyFields([]);
      dispatch({ type: "CREATE_WPCONFIG", payload: json });
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": {
          m: 1,
          width: "100%",
        },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "white",
        p: 2,
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="h3">
          WordPress Configuration
        </Typography>
        <Link href="/user_manual" target="_blank" rel="noopener noreferrer">
          <Tooltip title="User Manual" placement="top">
            <HelpOutline sx={{ color: "action.active" }} />
          </Tooltip>
        </Link>
      </div>
      <TextField
        required={true}
        label="WordPress URL"
        type="text"
        onChange={(e) => setWpUrl(e.target.value.toLowerCase())}
        value={WpUrl}
        error={emptyFieldsURL && emptyFieldsURL.includes("WpUrl")}
        sx={{ marginBottom: "0.5rem" }}
      />
      <TextField
        required={true}
        label="WordPress User"
        autoComplete="username"
        type="text"
        onChange={(e) => setWpUser(e.target.value)}
        value={WpUser}
        error={emptyFields && emptyFields.includes("WpUser")}
        sx={{ marginBottom: "0.5rem" }}
      />
      <TextField
        required={true}
        name="password"
        id="password"
        label="WordPress API key"
        type={showPassword ? "text" : "password"}
        autoComplete="current-password"
        onChange={(e) => setWpPassword(e.target.value)}
        value={WpPassword}
        error={emptyFields && emptyFields.includes("WpPassword")}
        sx={{ marginBottom: "0.5rem" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormControl error={emptyFields && emptyFields.includes("imgStatus")} required={true} fullWidth variant="outlined" margin="normal">
        <InputLabel id="image-status">Include Image?</InputLabel>
        <Select
          labelId="imgStatus"
          id="imgStatus"
          value={imgStatus}
          onChange={(e) => setImgStatus(e.target.value)}
          label="Image Status"
        >
          <MenuItem value="">
            <em>Include Image in Articles?</em>
          </MenuItem>
          {imgStatusOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl required={false} fullWidth variant="outlined" margin="normal" disabled={!userSubscribed}>
        <InputLabel id="internalLinks">Internal Links</InputLabel>
        <Select
          labelId="internalLinks"
          id="internalLinks"
          value={internalLinks}
          onChange={(e) => setInternalLinks(e.target.value)}
          label="Internal Links Status"
          error={emptyFields && emptyFields.includes("internalLinks")}
        >
          <MenuItem value="">
            <em>Include Internal Links in Articles?</em>
          </MenuItem>
          {internalLinksOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <MuiChipsInput
        fullWidth
        disabled={!userSubscribed}
        variant="outlined"
        id="longTailKeywords"
        name="longTailKeywords"
        placeholder="Enter key to add keywords"
        label="Link Insertion Keywords"
        value={longTailKeywords}
        onChange={handleLongTailChange}
        onPaste={(event) => {
          const pastedContent = event.clipboardData.getData("text");
          const pastedLongTailKeywords = pastedContent.split(/\r?\n/);
          setLongTailKeywords([...longTailKeywords, ...pastedLongTailKeywords]);
          event.preventDefault();
        }}
        sx={{ marginBottom: "0.5rem" }}
      />
      <CenteredDiv>{loading ? <CircularProgress /> : null}</CenteredDiv>
      {error && <ErrorBox error={error} color="error" />}
      <Button
        variant="contained"
        type="submit"
        sx={{
          marginTop: "1rem",
          backgroundColor: "#4829B2",
          ":hover": { bgcolor: "#321d7c" },
        }}
        disabled={isSubmitDisabled}
      >
        Add WordPress Site
      </Button>
    </Box>
  );
};

export default WpConfigForm;
