import React, { useState, useEffect } from "react";
import { useLogin } from "../hooks/useLogin";
import { useResendEmail } from "../hooks/useResendEmail";
import Navbar from "../components/NavBar/Navbar";
import Footer from "../components/Shared/Footer";
import ErrorBox from "../components/Shared/ErrorBox";
import { Https, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Avatar,
  InputAdornment,
  IconButton,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Paper,
  Box,
  Grid,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

const Root = styled(Grid)(({ theme }) => ({
  minHeight: "100vh",
  height: "100%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundColor: theme.palette.common.white,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const SizeContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
  border: `1px solid ${theme.palette.component.componentBackground}`,
  "@media (max-width: 900px)": {
    border: "none",
    borderRadius: "none",
    boxShadow: "none",
  },
}));

const StyledPaper = styled("div")({
  margin: "16px 48px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: "0",
  backgroundColor: theme.palette.secondary.main,
}));

const StyledForm = styled("form")({
  width: "100%", // Fix IE 11 issue.
  marginTop: "8px",
});

const CenterContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, error, isLoading } = useLogin();
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoadingReset, setIsLoadingReset] = useState(false);
  const { resendEmail } = useResendEmail();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const savedEmail = localStorage.getItem("email");
    const savedRememberMe = localStorage.getItem("rememberMe");

    if (savedRememberMe && savedEmail) {
      setEmail(savedEmail);
      setRememberMe(true);
    }
  }, []);

  useEffect(() => {
    if (error === "Email not confirmed, new confirmation email sent") {
      resendEmail(email);
    }
  }, [error, email, resendEmail]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingReset(true);
    if (rememberMe) {
      localStorage.setItem("email", email);
      localStorage.setItem("rememberMe", true);
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("rememberMe");
    }

    setTimeout(async () => {
      await login(email, password);
      setIsLoadingReset(false);
    }, 0);
  };

  return (
    <div>
      <Navbar />
      <Root container component="main">
        <SizeContainer
          item
          xs={12}
          sm={8}
          md={6}
          lg={5}
          xl={3}
          component={Paper}
          elevation={1}
          square
        >
          <StyledPaper>
            <StyledAvatar>
              <Https />
            </StyledAvatar>
            <br />
            <Typography component="h1" variant="h5">
              Log in
            </Typography>
            <StyledForm onSubmit={handleSubmit} noValidate>
              <TextField
                onChange={(event) => setEmail(event.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={email}
                autoFocus
                autoComplete="username"
              />
              <TextField
                onChange={(event) => setPassword(event.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Link underline="hover" href="/password_recovery">
                <Typography variant="subtitle1">
                  {"Forgot password?"}
                </Typography>
              </Link>
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    color="primary"
                  />
                }
                label="Remember me"
              />
              <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                {isLoadingReset ? (
                  <CenterContainer>
                    <CircularProgress />
                  </CenterContainer>
                ) : (
                  <></>
                )}

                {error && <ErrorBox error={error} color="error" />}
                <br />
                <Button
                  sx={{
                    borderRadius: "50px",
                    height: "45px",
                    backgroundColor: "#4829B2",
                    ":hover": { bgcolor: "#321d7c" },
                  }}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                >
                  Login
                </Button>
                <Divider sx={{ padding: "15px" }}>or</Divider>
                <Button
                  sx={{
                    borderColor: "#4829B2",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderRadius: "50px",
                    height: "45px",
                    color: "#4829B2",
                    backgroundColor: "#ffffff",
                    ":hover": { bgcolor: "#f8f7fc" },
                    "&.Mui-disabled": {
                      borderColor: "#ffffff",
                    },
                  }}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  href="/signup"
                >
                  {"Sign Up"}
                </Button>
              </div>
            </StyledForm>
          </StyledPaper>
        </SizeContainer>
      </Root>

      <Footer />
    </div>
  );
};

export default Login;
