import { useState, useEffect } from 'react';
import { useAuthContext } from './useContext/useAuthContext';

const useUserSubscribed = () => {
    const { user } = useAuthContext();
    const [userSubscribed, setUserSubscribed] = useState(false);

    useEffect(() => {
        const fetchUserConfigs = async () => {
            const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/user/status/", {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            const json = await response.json();

            if (json.plan === 'premium' || json.plan === 'standard' || json.isAdmin === true || json.freeTrial === true) {
                setUserSubscribed(true);
            } else {
                setUserSubscribed(false);
            }
        };

        fetchUserConfigs();
    }, [user]);

    return userSubscribed;
};

export default useUserSubscribed;
