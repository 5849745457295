import React, { useEffect, useState, Fragment, forwardRef } from "react";
import { useWpConfigContext } from "../../hooks/useContext/useWpConfigContext";
import { useAuthContext } from "../../hooks/useContext/useAuthContext";
import { styled } from "@mui/system";
import {
  Card,
  Collapse,
  CardContent,
  Typography,
  Tooltip,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  FormControl,
} from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import { Edit, Delete, HelpOutline } from "@mui/icons-material";
import useUserSubscribed from "../../hooks/useUserSubscribed";

const RootCard = styled(Card)({
  height: "100%",
  backgroundColor: "white",
  p: 2,
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  overflowWrap: "break-word",
  position: "relative",
});

const CustomDelete = styled(Delete)({
  color: "#b2294d",
});

const SolidLineDiv = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "4px",
  backgroundColor: "#b1294e",
});

const WpConfigDetails = ({ wpconfig }) => {
  const { dispatch } = useWpConfigContext();
  const { user } = useAuthContext();
  const [editMode, setEditMode] = useState(false);
  const [editedLongTailKeywords, setLongTailKeywords] = useState(
    !wpconfig.longTailKeywords ? "" : wpconfig.longTailKeywords
  );
  const [editedInternalLinks, setInternalLinks] = useState(
    !wpconfig.internalLinks ? "" : wpconfig.internalLinks
  );
  const [open, setOpen] = useState(false);
  const [expandedLongTail, setExpandedLongTail] = useState(false);
  const longTailCount = wpconfig.longTailKeywords.length;
  const [editedImgStatus, setEditedImgStatus] = useState(wpconfig.imgStatus);
  const imgStatusOptions = ["No", "Yes"];
  const internalLinksOptions = ["No", "Yes"];
  const [emptyFields, setEmptyFields] = useState([]);
  const [imgStatusError, setImgStatusError] = useState(null);
  const [longTailError, setLongTailError] = useState(null);
  const [internalLinkError, setInternalLinkError] = useState(null);
  const userSubscribed = useUserSubscribed();

  useEffect(() => {
    if (!editedImgStatus) {
      setImgStatusError("Please select an image status.");
      setEmptyFields((emptyFields) => [...emptyFields, "imgStatus"]);
    } else {
      setImgStatusError(null);
      setEmptyFields((emptyFields) =>
        emptyFields.filter((field) => field !== "imgStatus")
      );
    }
    
    if (!editedInternalLinks) {
      setInternalLinkError("Internal links status is required.");
      setEmptyFields((emptyFields) => [...emptyFields, "internalLinks"]);
    } else {
      setInternalLinkError(null);
      setEmptyFields((emptyFields) =>
        emptyFields.filter((field) => field !== "internalLinks")
      );
    }

    if (editedInternalLinks === "Yes" && editedLongTailKeywords.length === 0) {
      setLongTailError(
        "Please add at least one long-tail keyword to enable internal links"
      );
      setEmptyFields((emptyFields) => [...emptyFields, "internalLinks"]);
    } else {
      setLongTailError(null);
      setEmptyFields((emptyFields) =>
        emptyFields.filter((field) => field !== "internalLinks")
      );
    }
  }, [editedImgStatus, editedInternalLinks, editedLongTailKeywords]);

  if (!user) {
    return null;
  }

  const handleExpandLongTailClick = () => {
    setExpandedLongTail(!expandedLongTail);
  };

  const longTailamount = () => {
    if (wpconfig.longTailKeywords.length === 1) {
      return "1 Long Tail Keyword";
    } else {
      return ` All ${wpconfig.longTailKeywords.length} Long Tail Keywords`;
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLongTailChange = (newLongTailChips) => {
    // Regular expression that matches the characters you want to exclude
    const excludeChars = /[.?!</>,;:]/;
    // Filter out any chips that contain the excluded characters
    const filteredChips = newLongTailChips.filter(chip => !excludeChars.test(chip));
    setLongTailKeywords(filteredChips);
  };


  const handleDelete = async () => {
    const response = await fetch(
      process.env.REACT_APP_SERVER_URL + "/api/wpconfigs/" + wpconfig._id,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const json = await response.json();

    if (response.ok) {
      dispatch({ type: "DELETE_WPCONFIG", payload: json });
    }
  };

  //cancel button on edit
  const handleEditClick = () => {
    if (!userSubscribed) {
      return;
    }
    if (editMode === true) {
      setEditMode(false);
      setInternalLinks(!wpconfig.internalLinks ? "" : wpconfig.internalLinks);
      setLongTailKeywords(
        !wpconfig.longTailKeywords ? "" : wpconfig.longTailKeywords
      );
      setEditedImgStatus(wpconfig.imgStatus);
    } else if (editMode === false) {
      setEditMode(true);
    }
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();

    if (!editedImgStatus) {
      setImgStatusError("Image status is required.");
      return;
    }

    if (!editedInternalLinks) {
      setLongTailError("Internal links status is required.");
      return;
    }

    if (editedInternalLinks === "Yes" && editedLongTailKeywords.length === 0) {
      setLongTailError(
        "Please add at least one long-tail keyword to enable internal links"
      );
      return;
    }

    const updatedWpConfig = {
      imgStatus: editedImgStatus,
      longTailKeywords: editedLongTailKeywords,
      internalLinks: editedInternalLinks,
    };

    const response = await fetch(
      process.env.REACT_APP_SERVER_URL +
        "/api/wpconfigs/update/" +
        wpconfig._id,
      {
        method: "PUT",
        body: JSON.stringify(updatedWpConfig),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const json = await response.json();

    if (response.ok) {
      setEditMode(false);
      dispatch({ type: "UPDATE_WPCONFIG", payload: json });
    }
  };

  const ForwardedCollapse = forwardRef((props, ref) => (
    <Collapse ref={ref} {...props} />
  ));

  return (
    <RootCard variant="outlined">
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton
          size="large"
          aria-label="Edit"
          aria-haspopup="true"
          onClick={handleEditClick}
          color="#343a40"
        >
          <Tooltip title={userSubscribed ? "Edit WordPress Configuration" : "Subscription Required"} placement="top">
            <Edit />
          </Tooltip>
        </IconButton>
        <IconButton
          sx={{ "&:hover": { backgroundColor: "#f2eeef" } }}
          size="large"
          aria-label="Delete"
          aria-haspopup="true"
          ariant="outlined"
          onClick={handleClickOpen}
          color="#343a40"
        >
          <Tooltip title="Delete WordPress Configuration" placement="top">
            <CustomDelete />
          </Tooltip>
        </IconButton>
      </div>

      <CardContent>
        <SolidLineDiv />
        {editMode ? (
          <>
            <Typography variant="body2" component="p">
              <strong>WordPress URL: </strong>
              {wpconfig.WpUrl}
            </Typography>
            <Typography variant="body2" component="p">
              <strong>WordPress User: </strong>
              {wpconfig.WpUser}
            </Typography>
            <br />

            <FormControl
              required={true}
              fullWidth
              variant="outlined"
              margin="normal"
              error={emptyFields && emptyFields.includes("imgStatus")}
            >
              <InputLabel id="image-status">Include Image?</InputLabel>
              <Select
                labelId="imgStatus"
                id="imgStatus"
                value={editedImgStatus}
                onChange={(e) => setEditedImgStatus(e.target.value)}
                label="Image Status"
              >
                <MenuItem value="">
                  <em>Include Image in Articles?</em>
                </MenuItem>
                {imgStatusOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              {imgStatusError && (
                <FormHelperText>{imgStatusError}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              required={true}
              fullWidth
              variant="outlined"
              margin="normal"
              error={Boolean(internalLinkError)}
            >
              <InputLabel id="internalLinks">Internal Links</InputLabel>
              <Select
                labelId="internalLinks"
                id="internalLinks"
                value={editedInternalLinks}
                onChange={(e) => setInternalLinks(e.target.value)}
                label="Internal Links"
                error={emptyFields && emptyFields.includes("internalLinks")}
              >
                <MenuItem value="">
                  <em>Include Internal Links in Articles?</em>
                </MenuItem>
                {internalLinksOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              {internalLinkError && (
                <FormHelperText>{internalLinkError}</FormHelperText>
              )}
              {longTailError && (
                <FormHelperText>{longTailError}</FormHelperText>
              )}
            </FormControl>

            <MuiChipsInput
              sx={{ marginTop: ".7rem" }}
              value={editedLongTailKeywords}
              onChange={handleLongTailChange}
              onPaste={(event) => {
                const pastedContent = event.clipboardData.getData("text");
                const pastedLongTailKeywords = pastedContent.split(/\r?\n/);
                setLongTailKeywords([
                  ...editedLongTailKeywords,
                  ...pastedLongTailKeywords,
                ]);
                event.preventDefault();
              }}
              fullWidth
              variant="outlined"
              placeholder="Enter key to add keywords"
              id="longTailKeywords"
              name="longTailKeywords"
              label="Long-Tail Keywords"
            />

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{
                  marginTop: "1rem",
                  backgroundColor: "#4829B2",
                  ":hover": { bgcolor: "#321d7c" },
                }}
                onClick={handleSaveClick}
              >
                Save Changes
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  ":hover": { bgcolor: "#f5f5f5" },
                  marginTop: "1rem",
                  marginLeft: "20px",
                  marginBottom: "0px",
                }}
                onClick={handleEditClick}
              >
                Cancel
              </Button>
              <Tooltip
                title="To edit WordPress URL, user, or password: delete configuration and re-enter"
                placement="right"
              >
                <HelpOutline
                  sx={{
                    color: "#3f51b5",
                    fontSize: 20,
                    marginLeft: "20px",
                    marginTop: "25px",
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                />
              </Tooltip>
            </div>
          </>
        ) : (
          <>
            <Typography variant="h6" component="h2" sx={{ padding: '5px' }}>
              {wpconfig.WpUrl}
            </Typography>
            <Divider sx={{ margin: "10px" }} variant="middle" />
            <Typography variant="body2" component="p" sx={{ display: 'flex', justifyContent: 'space-between'}}>
              <span style={{padding: '5px' }}> <strong>WordPress User </strong> </span>
              <span style={{padding: '5px' }}> {wpconfig.WpUser} </span>
            </Typography>
            <Divider sx={{ margin: "10px" }} variant="middle" />
            <Typography variant="body2" component="p" sx={{ display: 'flex', justifyContent: 'space-between'}}>
              <span style={{padding: '5px' }}> <strong>Include Image? </strong> </span>
              <span style={{padding: '5px' }}> {wpconfig.imgStatus ? wpconfig.imgStatus : "Not Set"} </span>
            </Typography>
            <Divider sx={{ margin: "10px" }} variant="middle" />
            <Typography variant="body2" component="p" sx={{ display: 'flex', justifyContent: 'space-between'}}>
              <span style={{padding: '5px' }}> <strong>Internal Links? </strong> </span>
              <span style={{padding: '5px' }}>{wpconfig.internalLinks ? wpconfig.internalLinks : "Not Set"}</span>
            </Typography>
            <Divider sx={{ margin: "10px" }} variant="middle" />
            <Typography variant="body2" component="span" sx={{ padding: '5px' }}>
              <strong>WordPress Long Tail Keywords </strong>
            </Typography>
            {wpconfig.longTailKeywords.length > 0 ? (
              <>
                <ForwardedCollapse in={expandedLongTail} sx={{padding: '5px'}}>
                  <div>
                    {wpconfig.longTailKeywords
                      .slice(0, longTailCount)
                      .map((keyword, index) => (
                        <Fragment key={index}>
                          <span>{index + 1}. </span>
                          {keyword}
                          <br />
                        </Fragment>
                      ))}
                  </div>
                </ForwardedCollapse>
                <Button onClick={handleExpandLongTailClick}>
                  {expandedLongTail ? "Close" : "See " + longTailamount()}
                </Button>
              </>
            ) : (
              <Button disabled={true}>No LongTail keywords set</Button>
            )}
          </>
        )}
      </CardContent>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete WordPress Configuration
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All related Topics from <strong>{wpconfig.WpUrl}</strong> will be
            deleted as well.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: "#4829B2",
              color: "white",
              ":hover": { bgcolor: "#321d7c" },
            }}
            onClick={handleDelete}
            autoFocus
          >
            Delete
          </Button>
          <Button
            sx={{
              backgroundColor: "white",
              color: "black",
              ":hover": { bgcolor: "#f5f5f5" },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </RootCard>
  );
};

export default WpConfigDetails;
