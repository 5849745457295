import { React, useEffect } from "react";
import WpConfigDetails from "../components/WP_Configuration_Page/wpconfigDetails";
import WpConfigForm from "../components/WP_Configuration_Page/WpConfigForm";
import DashboardNavBar from "../components/NavBar/DashboardNavBar";
import Sidebar from "../components/Shared/Sidebar";
import Footer from "../components/Shared/Footer";
import { useWpConfigContext } from "../hooks/useContext/useWpConfigContext";
import { useAuthContext } from "../hooks/useContext/useAuthContext";
import { Grid, Box } from "@mui/material";
import { styled } from "@mui/system";

const GridContainerMain = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.common.background,
  paddingLeft: "140px",
  paddingRight: "80px",
  paddingTop: "70px",
  width: "100%",
  minHeight: "100vh",
  "@media (max-width: 960px)": {
    paddingLeft: "90px",
    paddingRight: "15px",
    paddingTop: "35px",
  },
}));

const WpConfigPage = () => {
  const { wpconfigs, dispatch } = useWpConfigContext();
  const { user } = useAuthContext();

  useEffect(() => {
    const fetchWpConfigs = async () => {
      const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/wpconfigs", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        dispatch({ type: "SET_WPCONFIG", payload: json });
      } else {
        window.location.reload();
      }
    };

    if (user) {
      fetchWpConfigs();
    }
  }, [dispatch, user]);
  return (
    <div>
      <DashboardNavBar />
      <Sidebar />
      <GridContainerMain container spacing={{xs:3, sm:3, md:3, lg:4, xl:6}}>
        <Grid item xs={12} sm={12} md={12} lg={7} xl={8} order={{ xs: 2, lg: 1 }}>
          <Grid container spacing={3}>
          {wpconfigs &&
            [...wpconfigs].sort((a, b) => a.WpUrl.localeCompare(b.WpUrl)).map((wpconfig) => (
              <Grid item xs={12} sm={6} md={6} lg={6} xl={4} key={wpconfig._id}>
                <WpConfigDetails key={wpconfig._id} wpconfig={wpconfig} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5} xl={4} order={{ xs: 1, lg: 2 }}>
          <WpConfigForm />
        </Grid>
      </GridContainerMain>

      <Box style={{ paddingLeft: "64px" }}>
        <Footer />
      </Box>

    </div>
  );
};

export default WpConfigPage;