import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const ErrorBox = ({ error, color }) => {
  const theme = useTheme();

  return (
    <Box sx={{
      borderRadius: '8px',
      color: color === 'success' ? theme.palette.common.success : theme.palette.common.error,
      border: `1px solid ${color === 'success' ? theme.palette.common.success : theme.palette.common.error}`,
      textAlign: 'center',
      paddingTop: '10px',
      paddingBottom: '10px',
      margin: 'auto',
      maxWidth: '100%',
      minWidth: '100%',
      marginTop: '10px',
    }}>
      {error}
    </Box>
)};

export default ErrorBox;
