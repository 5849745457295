import React from "react";
import { Typography, Box, Avatar, Button } from "@mui/material";
import { styled } from "@mui/system";
import CheckIcon from "@mui/icons-material/Check";
import { useTheme } from "@mui/material/styles";

const FeatureContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  marginTop: "2rem",
});

function FeatureCard(props) {
  const { title, subheading, textColor, pricing, features, backgroundColor } = props;
  const theme = useTheme();

  return (
    <div>
      <Box
        sx={{
          border: `1px solid ${theme.palette.common.offBlack}`,
          borderRadius: "10px",
          padding: "1.5rem",
          marginTop: "2rem",
          backgroundColor: backgroundColor,
        }}
      >
        <Typography variant="h2" gutterBottom sx={{ textAlign: "center", color: textColor, padding: '2rem 0rem 0rem 0rem'}}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ textAlign: "center", color: theme.palette.component.darkBorderColor, paddingBottom: '2rem'}}>
          {subheading}
        </Typography>

        <Box sx={{ textAlign: "center", display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}}>
          <Typography variant="h1" component="span" sx={{color: textColor, paddingRight: '5px'}}>
            {pricing}
          </Typography>
          <Typography variant="body2" component="span" sx={{ color: theme.palette.component.borderColor, position: 'relative', bottom: '0' }}>
            / month
          </Typography>
        </Box>

        <Button href="/signup" variant="primaryButton" sx={{ width: "100%", marginTop: "2rem", backgroundColor: textColor, color: backgroundColor}}>
          Select Plan
        </Button>
        <Box sx={{paddingBottom: '2rem'}}>
          {features.map((feature, index) => {
            const isLineThrough = feature.style?.textDecoration === 'line-through';
            const avatarColor = isLineThrough ? theme.palette.grey[500] : textColor;
            const iconColor = isLineThrough ? theme.palette.common.white : backgroundColor;

            return (
            <FeatureContainer key={index}>
            <Avatar sx={{marginRight: "1rem", width: 20, height: 20, bgcolor: avatarColor }}>
              <CheckIcon
                sx={{
                  color: iconColor,
                  width: 15, height: 15,
                }}
              />
              </Avatar>
              {typeof feature === 'object' && feature.text ? (
                <Typography variant="subtitle1" noWrap sx={{ ...feature.style, color: textColor }}>
                  {feature.text}
                </Typography>
              ) : (
                <Typography variant="subtitle1" noWrap sx={{ color: textColor }}>
                  {feature}
                </Typography>
              )}
            </FeatureContainer>
            ); 
          })}
        </Box>
      </Box>
    </div>
  );
}

export default FeatureCard;
