import React from "react";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";

const CustomImage = styled("img")({
  width: "auto",
});

export default function Logo({ logoHeight }) {
  return (
    <Link to="/" style={{ textDecoration: "none" }}>
      <CustomImage
        src={process.env.REACT_APP_CLIENT_URL + "/images/AutoArticleLogo.png"}
        alt="Home"
        style={{ height: logoHeight }}
      />
    </Link>
  );
}
