import { Card, CardContent, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";

const Root = styled(Card)({
  height: "100%",
  backgroundColor: "white",
  p: 2,
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  position: "relative",
  overflow: "hidden",
});

const SolidLine = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "4%",
  backgroundColor: theme.palette.primary.lighter,
}));

export const OverviewActiveCampaigns = (props) => {
  const { amount, sx } = props;

  return (
    <Root sx={sx}>
      <SolidLine />
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={0}
        >
          <Typography
            variant="subtitle1"
            component="p"
            gutterBottom
            sx={{ lineHeight: 2.5 }}
          >
            Active Campaigns
          </Typography>
          <Typography variant="h4" component="h4">
            {amount}
          </Typography>
        </Stack>
      </CardContent>
    </Root>
  );
};
