import React from "react";
import { Button } from "@mui/material";

export default function HomeNavNoUserButtons({ mobileView }) {
  return (
    <>
      <Button
        href="/signup"
        variant="primaryButton"
        sx={{
          marginRight: mobileView ? 0 : 2,
          height: mobileView ? "60px" : null,
          marginTop: mobileView ? "10px" : "0px",
        }}
      >
        Get Started
      </Button>

      <Button
        href="/login"
        variant="secondaryButton"
        sx={{
          height: mobileView ? "60px" : null,
          marginTop: mobileView ? "10px" : "0px",
        }}
      >
        Sign in
      </Button>
    </>
  );
}
