import { useState } from "react";

export const useResetPassword = () => {
  const [error, setError] = useState(null);

  const resetPassword = async (email) => {
    try {
      
      const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/user/resetPassword", {
        method: "POST",
        body: JSON.stringify({ email }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();
      if (!response.ok) {
        setError(json.error);
      }
      if (response.ok) {
        setError(json.message);
      }
    } catch (error) {
      setError("An error occurred, please try again");
      console.error(error);
    }
  };
  return { resetPassword, error };
};
