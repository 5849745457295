import React from "react";
import { styled } from "@mui/system";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../../hooks/useContext/useAuthContext";
import Drawer from "@mui/material/Drawer";
import {
  SpaceDashboardRounded,
  DisplaySettingsRounded,
  RoomPreferencesRounded,
  ArticleRounded,
} from "@mui/icons-material";

import SidebarButton from "./SidebarButton";

const CustomDrawer = styled(Drawer)(({theme}) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.component.drawer,
    top: "auto",
  },
}));

const Sidebar = () => {
  const location = useLocation();
  const { user } = useAuthContext();

  const isActiveRoute = (path) => {
    return location.pathname === path;
  };

  return user ? (
    <CustomDrawer anchor="left" variant="permanent">
      <SidebarButton
        title="Dashboard"
        to="/dashboard"
        isActive={isActiveRoute("/dashboard")}
        IconComponent={SpaceDashboardRounded}
      />
      <SidebarButton
        title="Articles"
        to="/articles"
        isActive={isActiveRoute("/articles")}
        IconComponent={ArticleRounded}
      />
      <SidebarButton
        title="WordPress Configuration"
        to="/wp_configuration"
        isActive={isActiveRoute("/wp_configuration")}
        IconComponent={DisplaySettingsRounded}
      />
      <SidebarButton
        title="AI Configuration"
        to="/ai_configuration"
        isActive={isActiveRoute("/ai_configuration")}
        IconComponent={RoomPreferencesRounded}
      />
    </CustomDrawer>
  ) : null;
};

export default Sidebar;