import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar } from '@mui/material';
import { useAuthContext } from "../../hooks/useContext/useAuthContext";
import DashboardNavButton from "./DashboardNavButton";
import Logo from "../Shared/Logo";
import { useTheme } from '@mui/material/styles';

export default function DashboardNavbar() {
  const { user } = useAuthContext();
  const theme = useTheme();
  const [userIsAdmin, setUserIsAdmin] = useState(false);

  
  //check if logged in user is admin
  useEffect(() => {
    const fetchUserConfigs = async () => {
      const response = await fetch(
        process.env.REACT_APP_SERVER_URL + "/api/user/status/",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();
      if (response.ok) {
        if (json.isAdmin === true) {
          setUserIsAdmin(true);
        } else {
          setUserIsAdmin(false);
        }
      }
    };

    fetchUserConfigs();
  }, [user]);


  return (
    <AppBar
      position="sticky"
      color='default'
      elevation={1}
      sx={{
        backgroundColor: theme.palette.common.white,
      }}>

      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Logo logoHeight={'36px'} />
        <DashboardNavButton isAdmin={userIsAdmin} email={user.email} theme={theme} />
      </Toolbar>

    </AppBar>
  );
}