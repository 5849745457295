import { Card, CardContent, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const Root = styled(Card)({
  height: "100%",
  backgroundColor: "white",
  p: 2,
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  position: "relative",
  overflow: "hidden",
});

const SolidLine = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "4%",
  backgroundColor: theme.palette.primary.lighter,
}));

export const RecentlyPublished = (props) => {
  const theme = useTheme();
  const { linksList, sx } = props;
  let mostRecentLink = "";

  if (
    linksList &&
    linksList.length > 0 &&
    linksList[linksList.length - 1].link
  ) {
    mostRecentLink = linksList[linksList.length - 1].link;
  } else {
    mostRecentLink = "";
  }

  return (
    <Root sx={sx}>
      <SolidLine />
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={0}
        >
          <Typography
            variant="subtitle1"
            component="p"
            gutterBottom
            sx={{ lineHeight: 2.5 }}
          >
            Latest Post
          </Typography>
          {mostRecentLink ? (
            <Link
              variant="subtitle1"
              component="p"
              to={mostRecentLink}
              underline="hover"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: theme.palette.common.offBlack, cursor: "pointer" }}
            >
              {mostRecentLink}
            </Link>
          ) : (
            <Typography variant="subtitle1" component="p" noWrap>
              No posts yet
            </Typography>
          )}
        </Stack>
      </CardContent>
    </Root>
  );
};
