import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Shared/Footer";
import BlogBox from "../components/Article_Display_Page/BlogBox";
import Navbar from "../components/NavBar/Navbar";
import { Grid, Breadcrumbs, Link, Typography } from "@mui/material";
import { styled } from "@mui/system";

const MainDiv = styled("div")(({theme}) => ({
  backgroundColor: theme.palette.common.white,
  paddingLeft: "15%",
  paddingRight: "15%",
  fontFamily: "Helvetica Neue, sans-serif",
  lineHeight: "1.5",
  alignItems: "center",
  paddingTop: "10px",
  paddingBottom: "100px",
  minHeight: "100vh",
  justifyContent: "center",
  "@media (max-width: 960px)": {
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: "20px",
  },
  "@media (max-width: 600px)": {
    paddingLeft: "10%",
    paddingRight: "7%",
    paddingTop: "10px",
  },
}));

const ImageContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const CustomImage = styled("img")(({theme}) => ({
  width: "100%",
  border: `1px solid ${theme.palette.component.borderColor}`,
  borderRadius: "20px",
  marginTop: "40px",
  marginBottom: "20px",
  "@media (max-width: 960px)": {
    width: "100%",
  },
}));

const Blog = () => {
  const [listOfBlogArticles, setListOfBlogArticles] = useState([]);
  const navigate = useNavigate();

  const fetchBlogArticles = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_SERVER_URL + "/api/blog/onlyBlog",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const json = await response.json();
      setListOfBlogArticles(json.reverse());
    } catch (error) {
      navigate("/");
    }
  };

  useEffect(() => {
    fetchBlogArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Navbar />
      <MainDiv>
        <Grid container>
          <Grid item xs={12} md={12}>
            <ImageContainer>
              <CustomImage
                src={process.env.REACT_APP_CLIENT_URL + "/images/blog_hero.jpg"}
                alt={"Blog Hero"}
              />
            </ImageContainer>

            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/">
                Home
              </Link>
              <Typography color="text.primary">Blog</Typography>
            </Breadcrumbs>
          </Grid>

          {listOfBlogArticles.map((article, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <BlogBox key={index} article={article} />
            </Grid>
          ))}
        </Grid>
      </MainDiv>

      <Footer />
    </div>
  );
};

export default Blog;
