import { TopicsContext } from "../../context/TopicContext"
import { useContext } from "react"

export const useTopicContext = () => {
  const context = useContext(TopicsContext)

  if(!context) {
    throw Error('useTopicsContext must be used inside an TopicsContextProvider')
  }

  return context
}