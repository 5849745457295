import React from "react";
import { Typography, Grid, Avatar, Link, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { Build, Code, CalendarToday, Message } from "@mui/icons-material";

const h2Title = "Our Solution For Your Business";
const body1Subtitle = "40-hour work week in 20 minutes";

const featuredElements = (theme) => [
  {
    title: "WordPress Integration",
    description:
      "Integrates with WordPress API keys, enabling you to manage your WordPress site directly from our platform.",
    icon: <Build sx={{ color: theme.palette.primary.main }} />,
    buttonText: "Learn More",
  },
  {
    title: "Scheduled Publishing",
    description:
      "Full control over when your articles go live. Set an interval time and schedule your content to be published automatically",
    icon: <CalendarToday sx={{ color: theme.palette.primary.main }} />,
    buttonText: "Learn More",
  },
  {
    title: "Content Creation",
    description:
      "Our software utilizes advanced AI algorithms to generate original and engaging articles tailored to your specific requirements.",
    icon: <Message sx={{ color: theme.palette.primary.main }} />,
    buttonText: "Learn More",
  },
  {
    title: "Keyword Insertion",
    description:
      "Easily incorporate targeted keywords into your content to improve search engine visibility and attract organic traffic.",
    icon: <Code sx={{ color: theme.palette.primary.main }} />,
    buttonText: "Learn More",
  },
];


const boxStyle = (theme) => ({
  border: `1px solid ${theme.palette.common.offWhite}`,
  borderRadius: "10px",
  backgroundColor: theme.palette.common.white,
  height: "350px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const AvatarStyled = (theme) => ({
  bgcolor: theme.palette.common.offWhite,
  marginTop: "30px",
  marginLeft: "20px",
  marginBottom: "10px",
});

const FeaturesSection = () => {
  const theme = useTheme();
  const elements = featuredElements(theme);
  return (
    <Grid container spacing={0}>

      <Grid item xs={12} style={{ padding: "30px" }}>
        <Box>
          <Typography variant="h2" sx={{ textAlign: "center" }}>
            {h2Title}
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            {body1Subtitle}
          </Typography>
        </Box>
      </Grid>

      {elements.map((eachFeature, index) => (
        <Grid item xs={6} sm={6} md={3} lg={3} xl={3} style={{ display: "flex", padding: "10px" }} key={index}>
          <Box sx={boxStyle}>
            <Avatar variant="rounded" sx={AvatarStyled}>
              {eachFeature.icon}
            </Avatar>
            <Box padding="0px 20px 20px 20px" >
              <Typography variant="h4">{eachFeature.title}</Typography>
            </Box>
            <Box padding="0px 20px 0px 20px" >
              <Typography variant="body2">{eachFeature.description}</Typography>
            </Box>
            <Link href="/product" sx={{ textDecoration: "none" }}>
              <Box padding="0px 0px 30px 20px" >
                <Typography variant="button">{eachFeature.buttonText}</Typography>
              </Box>
            </Link>
          </Box>
        </Grid>
      ))}

    </Grid>
  );
};

export default FeaturesSection;
