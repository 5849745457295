import React, { useRef, useState, useEffect } from "react";
import { styled } from "@mui/system";
import { Grid, Zoom } from "@mui/material";
import Footer from "../components/Shared/Footer";
import Navbar from "../components/NavBar/Navbar";
import CircleSVG from "../components/Home_Page/CircleSVG";
import HeroLeft from "../components/Home_Page/HeroLeft";
import FeaturedSection from "../components/Home_Page/FeaturedSection";
// import HowItWorks from "../components/Home_Page/HowItWorks";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const GridContainerMain = styled(Grid)({
  paddingLeft: "0px",
  paddingRight: "0px",
  paddingTop: "0px",
  background: "linear-gradient(to bottom, #d1cfcf, #FFFFFF)",
});

function Home() {
  const [gridHeight, setGridHeight] = useState(0);
  const gridRef = useRef(null);

  const updateHeights = () => {
    if (gridRef.current) {
      setGridHeight(gridRef.current.clientHeight);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateHeights);

    updateHeights();

    return () => {
      window.removeEventListener("resize", updateHeights);
    };
  }, []);

  const CustomImage = styled("img")({
    position: "absolute",
    top: "400px",
    maxWidth: "100vw",
    right: "0",
    width: "55%",
    height: "auto",
    "@media (max-width: 900px)": {
      width: "95%",
      height: "auto",
      top: `${gridHeight}px`,
      marginTop: "25%",
    },
  });

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>AutoArticle | AI-Powered WordPress Ghostwriter</title>
          <meta name="description" content="Automate Your WordPress Content with Smart AI Writing" />
        </Helmet>
        <Navbar />
        <GridContainerMain
          container
          sx={{
            paddingBottom: {
              xs: "50px",
              sm: "50px",
              md: "50px",
              lg: "50px",
              xl: "150px",
            },
          }}
        >
          {/* HeroLeft */}
          <Grid item ref={gridRef} xs={12} sm={12} md={6} lg={6} xl={6}>
            <HeroLeft />
          </Grid>

          {/* HeroRight */}
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CircleSVG />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Zoom in={true} timeout={600}>
                  <CustomImage
                    src={
                      process.env.REACT_APP_CLIENT_URL + "/images/AnimationAA.png"
                    }
                    alt="Home Page"
                  />
                </Zoom>
              </Grid>
            </Grid>
          </Grid>

          {/* Feature Section */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
            sx={{
              paddingLeft: { xs: "5%", sm: "10%", md: "10%", lg: "15%", xl: "20%" },
              paddingRight: { xs: "5%", sm: "10%", md: "10%", lg: "15%", xl: "20%" },
              paddingTop: "25%",
              "@media (max-width: 900px)": {
                paddingTop: "95%",
              },
            }}
          >
            <FeaturedSection />
          </Grid>

          {/* How it works section */}
          {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <HowItWorks />
          </Grid> */}
        </GridContainerMain>
        <Footer />
      </div>
    </HelmetProvider>
  );
}

export default Home;
