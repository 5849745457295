import React from "react";
import Footer from "../components/Shared/Footer";
import Navbar from "../components/NavBar/Navbar";
import ProductSection from "../components/Product_Page/ProductSection";


function Product() {

  return (
    <div>
      <Navbar />
      <ProductSection />
      <Footer />
    </div>
  );
}

export default Product;