import React from "react";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  useTheme,
  Link,
  useMediaQuery,
  Divider,
} from "@mui/material";
import {
  Mail,
  YouTube,
  LinkedIn,
  Instagram
} from "@mui/icons-material";
import Logo from "./Logo";

const socialIcons = [
  {name:'Mail', icon: <Mail />, url: "mailto:contact@autoarticle.io"},
  {name:'LinkedIn', icon:<LinkedIn />, url: 'https://www.linkedin.com/company/swift-miller-llc'},
  {name:'Instagram', icon: <Instagram />, url: 'https://www.instagram.com/autoarticle.io'},
  {name:'YouTube', icon:<YouTube />, url: 'https://www.youtube.com/@AutoArticleio'}
];


function Footer() {
  const isSmallScreen = useMediaQuery('(max-width:899px)');
  const theme = useTheme();

  return (
    <div>
      <Grid container sx={{paddingTop: {xs: '40px', sm: '50px', md:'50px', lg:'50px'}, paddingBottom: '10px', paddingLeft:'10%', paddingRight: '10%', backgroundColor: theme.palette.component.componentBackground, height: "100%"}}>

        {isSmallScreen ? null : 
          <Grid item xs={12} md={3} lg={3} sx={{ textAlign: { xs: 'center', sm: 'center', md: 'left', lg: 'left' } }}>
            <Logo logoHeight={'29px'} />
          </Grid>
        }

        <Grid item xs={12} md={9} lg={9} sx={{ textAlign: { xs: 'center', sm: 'center', md: 'right', lg: 'right' } }}>
          {socialIcons.map((info, index) => (
            <Box sx={{display:'inline', align:'center'}} mb={1} key={index}>
              <a href={info.url} id={info.name} title={'button' + info.name} target="_blank" rel="noopener noreferrer" style={{ color: theme.palette.common.offGrey }}>
                <IconButton
                  style={{ color: theme.palette.common.offGrey }}
                  size="largemen"
                >
                  {info.icon}
                </IconButton>
              </a>
            </Box>
          ))}
        </Grid>

      </Grid>

      {isSmallScreen ?
        <Grid item xs={12} sx={{ backgroundColor: theme.palette.component.componentBackground, textAlign: 'center'}}>
        <Logo logoHeight={'29px'} />
        </Grid>
       : 
        <Grid container sx={{ backgroundColor: theme.palette.component.componentBackground, height: "100%" }}>
          <Grid sx={{ paddingLeft:'10%', paddingRight: '10%'}} item xs={12} md={12} lg={12}>
            <Divider />
          </Grid>
        </Grid>
      }

      <Grid container sx={{ paddingTop: '10px', paddingBottom: {xs: '30px', md: '70px', lg: '70px'}, paddingLeft:'10%', paddingRight:'10%', backgroundColor: theme.palette.component.componentBackground, height: "100%" }}>

        <Grid item xs={12} md={4} lg={4} sx={{ textAlign: { xs: 'center', sm: 'center',  md: 'left', lg: 'left' } }}>
          <Typography variant="subtitle1">
            © {new Date().getFullYear()} Swift Miller, LLC
          </Typography>
        </Grid>

        <Grid item xs={12} md={8} lg={8} sx={{ textAlign: { xs: 'center', sm: 'center', md: 'right', lg: 'right' }, paddingTop: {xs: '10px', md: '0px', lg: '0px'}}}>
          <Link variant="subtitle1" href="/terms_and_conditions" sx={{color: theme.palette.common.offBlack}}  underline="hover">
            Terms & Conditions
          </Link>
          <span style={{padding: "5px", color: theme.palette.common.offBlack}} >|</span>
          <Link variant="subtitle1" href="/privacy_policy" sx={{color: theme.palette.common.offBlack}}  underline="hover">
            Privacy Policy
          </Link>
        </Grid>

      </Grid>
    </div>
  );
}

export default Footer;