import { useEffect, useState } from "react";
import { useTopicContext } from "../hooks/useContext/useTopicContext";
import { useAuthContext } from "../hooks/useContext/useAuthContext";
import { useActiveTopicContext } from "../hooks/useContext/useActiveTopicContext";
import { useUserContext } from "../hooks/useContext/useUserContext";
import TopicDetails from "../components/Dashboard_Page/topicDetails"
import TopicForm from "../components/Dashboard_Page/TopicForm";
import { OverviewPosts } from "../components/Dashboard_Page/PostsAmount";
import { OverviewActiveCampaigns } from "../components/Dashboard_Page/ActiveCampaignsAmount";
import { RecentlyPublished } from "../components/Dashboard_Page/RecentlyPublished";
import { SitesLinked } from "../components/Dashboard_Page/SitesLinkedAmount";
import { DashboardController } from "../components/Dashboard_Page/DashboardController";
import DashboardNavBar from "../components/NavBar/DashboardNavBar";
import Sidebar from "../components/Shared/Sidebar";
import Footer from "../components/Shared/Footer";
import { Card, CardContent, Typography, Box, Grid, Tab, Tabs }  from "@mui/material";
import { styled } from "@mui/system";

const GridContainerMain = styled(Grid)(({ theme }) => ({
  paddingLeft: "90px",
  paddingRight: "24px",
  paddingTop: "30px",
  backgroundColor: theme.palette.common.background,
}));

const ActiveCampaignsCard = styled(Card)({
  height: "100%",
  width: "100%",
  backgroundColor: "white",
  padding: 2,
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
});

const ActiveCampaignsCardContent = styled(CardContent)({
  overflowWrap: "break-word",
});

const DashboardControllerDiv = styled("div")({
  overflowY: 'auto',
  height: 'auto',
  width: '100%',
  maxHeight: '200px',
});

const Dashboard = ({socket}) => {
  const { topics, dispatch } = useTopicContext();
  const { filteredTopics, activeTopicsDispatch } = useActiveTopicContext();
  const { usersArticles, usersDispatch } = useUserContext();
  const { user } = useAuthContext();
  let [wpSiteAmount, setWpSiteAmount] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [uniqueWpSites, setUniqueWpSites] = useState([]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Fetch topics and set unique wpsites and create tabs
  useEffect(() => {
    const wpSiteTopicCount = new Map();

    topics?.forEach(topic => {
      wpSiteTopicCount.set(
        topic.wpsite,
        (wpSiteTopicCount.get(topic.wpsite) || 0) + 1
      );
    });
    const sortedWpSites = Array.from(wpSiteTopicCount)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map(([site, count]) => `${site} (${count})`);

    const totalTopics = topics?.length || 0;
      setUniqueWpSites(["All (" + totalTopics + ")", ...sortedWpSites]);
  }, [topics]);


  //fetch topics on first render
  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/topics", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        const json = await response.json();
        if (response.ok) {
          dispatch({ type: "SET_TOPICS", payload: json });
          activeTopicsDispatch({ type: "SET_ACTIVE_TOPICS", payload: json });
        } else {
          window.location.reload();
        }
      } catch (error) {
        console.error("Error fetching topics:", error);
      }
    };

    if (user) {
      fetchTopics();
    }
  }, [dispatch, activeTopicsDispatch, user]);


  //fetch topics on socket event
  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/topics", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        const json = await response.json();
        if (response.ok) {
          dispatch({ type: "SET_TOPICS", payload: json });
          activeTopicsDispatch({ type: "SET_ACTIVE_TOPICS", payload: json });
        } else {
          window.location.reload();
        }
      } catch (error) {
        console.error("Error fetching topics:", error);
      }
    };

    const fetchUserConfigs = async () => {
      const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/user/", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        usersDispatch({ type: "SET_USERCONFIG", payload: json });
      }
    };
   
    //data is the returning url from server
    // const handleTopicId = (data) => {
    //   console.log(`Received 'topic_id' event for topic ${data}`);

    const handleTopicId = () => {
      fetchTopics();
      fetchUserConfigs();
    };

    socket.on('topic_id', handleTopicId);

    return () => {
      socket.off('topic_id', handleTopicId);
    };
  }, [dispatch, activeTopicsDispatch, user, usersDispatch, socket]);



  //fetch user configs on initial render
  useEffect(() => {
    const fetchUserConfigs = async () => {
      const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/user/", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        usersDispatch({ type: "SET_USERCONFIG", payload: json });
      }
    };
   
    if (user) {
      fetchUserConfigs();
    } 
    else {
      localStorage.removeItem("user");
    }
  }, [usersDispatch, user]);



  //fetch user configs on initial render
  useEffect(() => {
    const fetchWpConfigs = async () => {
      const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/wpconfigs", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (response.ok) {
        const json = await response.json();
        const wpUrls = json.map((item) => item.WpUrl);
        setWpSiteAmount(wpUrls.length);
        dispatch({ type: "SET_WPCONFIG", payload: json });
      }
    };

    if (user) {
      fetchWpConfigs();
    }
  }, [dispatch, user]);


  return (
    <div>
      <DashboardNavBar />
      <Sidebar />
      <GridContainerMain container spacing={3} sx={{paddingBottom: {xs: "50px", sm: "50px", md: "50px", lg: "50px", xl: "150px"}}}>

        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={{xs:3, sm:3, md:3, lg:4, xl:6}}>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3} >
              <OverviewPosts linksList={usersArticles ? usersArticles : []} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3} >
              <OverviewActiveCampaigns amount={filteredTopics?.length ?? 0} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3} >
              <SitesLinked amount={wpSiteAmount} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3} >
              <RecentlyPublished linksList={usersArticles ? usersArticles : []}/>
            </Grid>
            {filteredTopics && filteredTopics.length > 0 && (
              <Grid item xs={12} sm={12} md={12} display={{ lg: 'none', xl: 'none' }}>
                <ActiveCampaignsCard variant="outlined">
                  <ActiveCampaignsCardContent>
                    <Typography variant="h6" component="h3">
                      Active Campaigns
                    </Typography>
                    <br />
                    <DashboardControllerDiv>
                      {filteredTopics && filteredTopics.map((topic) => (
                        <DashboardController topic={topic} key={topic._id}/>
                      ))}
                    </DashboardControllerDiv>
                  </ActiveCampaignsCardContent>
                </ActiveCampaignsCard>
              </Grid>
            )}
          </Grid>
        </Grid>
        
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={6}>

            <Grid item xs={12} sm={12} md={12} lg={7} xl={8} order={{ xs: 2, lg: 1 }}>
              <Grid container spacing={3}>
                {/* only show this if there are active topics */}
                {filteredTopics && filteredTopics.length > 0 && (
                  <Grid item xs={12} lg={12} xl={12} display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' }}>
                    <ActiveCampaignsCard variant="outlined">
                      <ActiveCampaignsCardContent>
                        <Typography variant="h6" component="h3">
                          Active Campaigns
                        </Typography>
                        <br />
                        <DashboardControllerDiv>
                          {filteredTopics && 
                            filteredTopics.map((topic) => (
                          <DashboardController topic={topic} key={topic._id}/>
                            ))}
                        </DashboardControllerDiv>
                      </ActiveCampaignsCardContent>
                    </ActiveCampaignsCard>
                  </Grid>
                )}


                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable site tabs"
                  >
                    {uniqueWpSites.map((site, index) => (
                      <Tab label={site} key={index} />
                    ))}
                  </Tabs>
                </Grid>

                {topics && [...topics]
                  .filter(topic => {
                    if (tabValue === 0) {
                      return true;
                    } else {
                      const selectedWpSite = uniqueWpSites[tabValue].split(" (")[0];
                      return topic.wpsite === selectedWpSite;
                    }
                  })
                  .sort((a, b) => {
                    if (a.runningScript !== b.runningScript) {
                      return a.runningScript ? -1 : 1;
                    }
                    if (a.campaign_completed !== b.campaign_completed) {
                      return a.campaign_completed ? 1 : -1;
                    }
                    return new Date(b.createdAt) - new Date(a.createdAt);
                  })
                  .map(topic => (
                    <Grid item xs={12} sm={6} lg={4} key={topic._id}>
                      <TopicDetails topic={topic} socket={socket} />
                    </Grid>
                  ))
                }
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={5} xl={4} order={{ xs: 1, lg: 2 }}>
              <TopicForm />
            </Grid>

          </Grid>
        </Grid>

      </GridContainerMain>

      <Box style={{ paddingLeft: "64px" }}>
        <Footer />
      </Box>

    </div>
  );
};

export default Dashboard;