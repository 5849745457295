import { createContext, useReducer } from "react";

// contex to render the updated data without refresh
// which means all components now have an access to the WP Sitecontext
export const WpConfigsContext = createContext();

export const wpconfigReducer = (state, action) => {
  switch (action.type) {
    case "SET_WPCONFIG":
      return {
        wpconfigs: action.payload,
      };
    case "CREATE_WPCONFIG":
      return {
        wpconfigs: [action.payload, ...state.wpconfigs],
      };
    case "UPDATE_WPCONFIG":
      return {
        wpconfigs: state.wpconfigs.map((wpconfig) =>
          wpconfig._id === action.payload._id ? action.payload : wpconfig
        ),
      };
    case "DELETE_WPCONFIG":
      return {
        wpconfigs: state.wpconfigs.filter(
          (wpconfig) => wpconfig._id !== action.payload._id
        ),
      };
    default:
      return state;
  }
};

export const WpConfigsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(wpconfigReducer, {
    wpconfigs: null,
  });
  return (
    <WpConfigsContext.Provider value={{ ...state, dispatch }}>
      {children}
    </WpConfigsContext.Provider>
  );
};