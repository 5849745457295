import React from "react";
import { Tooltip, Button, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

const SidebarButton = ({ title, to, isActive, IconComponent }) => {
  const theme = useTheme();

  return (
    <Tooltip title={title} placement="right">
      <Button
        component={Link}
        to={to}
        variant="contained"
        sx={{
          width: "100%",
          paddingTop: "16px",
          paddingBottom: "16px",
          backgroundColor: theme.palette.component.drawer,
          "&:hover": {
            backgroundColor: theme.palette.component.drawerHover,
          },
        }}
      >
        <IconComponent style={{ color: isActive ? theme.palette.secondary.main : theme.palette.common.white }} />
      </Button>
    </Tooltip>
  );
};

export default SidebarButton;