import { styled } from "@mui/system";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ImageContainer from "./ImageContainerBlogBox";
// import AutoArticle_Logo from "../images/AutoArticle_Logo.png";

const ArticlePreviewContainer = styled("div")({
  backgroundColor: "#fff",
  borderRadius: "8px",
  margin: "3rem",
  "@media (max-width: 850px)": {
    marginBottom: "1rem",
    marginTop: "1rem",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
});

const LogoImage = styled("img")({
  width: "30px",
  height: "30px",
  marginTop: "10px",
  marginRight: "20px",
});

const LogoContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  paddingTop: "2.5rem",
});

const StyledLink = styled(Link)({
  textDecoration: "none",
  color: "inherit",
  "&:hover": {
    textDecoration: "none",
    color: "primary",
  },
});

function formatDate(dateString) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = new Date(dateString).toLocaleDateString(
    "en-US",
    options
  );
  return formattedDate;
}

function BlogBox(article) {
  const formattedCreatedAt = formatDate(article.article.createdAt);

  return (
    <ArticlePreviewContainer>
      <StyledLink to={"/blog/" + article.article.formattedTitle}>
        <ImageContainer article={article} />

        <Typography variant="h3" gutterBottom>
          {article.article.blogTitle}
        </Typography>
        <Typography variant="p" sx={{ lineHeight: 1 }}>
          {article.article.blogPreview}
        </Typography>

        <LogoContainer>
          <LogoImage
            src={
              process.env.REACT_APP_CLIENT_URL + "/images/AutoArticle_Logo.png"
            }
            alt="Logo"
          />
          <Typography variant="subtitle1" sx={{ flex: 1, textAlign: "left" }}>
            {article.article.blogAuthor} <br />
            {formattedCreatedAt}
          </Typography>
        </LogoContainer>
      </StyledLink>
    </ArticlePreviewContainer>
  );
}

export default BlogBox;
