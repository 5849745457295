import React, { useEffect, useState } from "react";
import Navbar from "../components/NavBar/Navbar";
import Footer from "../components/Shared/Footer";
import ErrorBox from "../components/Shared/ErrorBox";
import { useResetPassword } from "../hooks/useResetPassword";
import {
  Paper,
  Box,
  Grid,
  Link,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";

const RootContainer = styled(Grid)(({ theme }) => ({
  minHeight: "100vh",
  height: "100%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundColor: theme.palette.common.white,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const SizeContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
  border: `1px solid ${theme.palette.component.borderColor}`,
  "@media (max-width: 900px)": {
    border: "none",
    borderRadius: "none",
    boxShadow: "none",
  },
}));

const PaperContainer = styled("div")({
  margin: "16px 48px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledForm = styled("form")({
  width: "100%", // Fix IE 11 issue.
  marginTop: "8px",
});

const LogoImage = styled("img")({
  width: "70px",
  height: "70px",
  marginBottom: "10px",
  marginTop: "10px",
});

const CenterContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const PasswordRecovery = () => {
  const [verificationMessage, setVerificationMessage] = useState("");
  const [email, setEmail] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const { resetPassword, error } = useResetPassword();
  const [isLoadingReset, setIsLoadingReset] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingReset(true);
    await resetPassword(email, error);
    setIsLoadingReset(false);
  };

  useEffect(() => {
    if (error === "We have emailed you a link to reset your password") {
      setVerificationMessage(
        "We have emailed you a link to reset your password"
      );
      setIsDisabled(true);
    } else if (error === "Email not found") {
      setVerificationMessage("Email not found");
      setIsDisabled(false);
    } else {
      setIsDisabled(false);
    }
  }, [error]);

  return (
    <div>
      <Navbar />
      <RootContainer container component="main">
        <SizeContainer
          item
          xs={12}
          sm={8}
          md={5}
          lg={3}
          xl={3}
          component={Paper}
          elevation={1}
          square
        >
          <PaperContainer>
            <LogoImage
              src={
                process.env.REACT_APP_CLIENT_URL +
                "/images/AutoArticle_Logo.png"
              }
              alt="Logo"
            />
            <br />
            <div>
              <Typography component="h1" variant="h5">
                <span style={{ display: "flex", justifyContent: "center" }}>
                  Need help with your Passsword?
                </span>
              </Typography>
              <Typography component="h5" variant="subtitle1">
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "1.5rem",
                  }}
                >
                  Enter the email you use for AutoArticle, and we’ll help you
                  create a new password.
                </span>
              </Typography>

              <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                <StyledForm onSubmit={handleSubmit}>
                  <TextField
                    onChange={(event) => setEmail(event.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    value={email}
                    autoFocus
                    autoComplete="username"
                    type="email"
                  />
                  {isLoadingReset ? (
                    <CenterContainer>
                      <CircularProgress />
                    </CenterContainer>
                  ) : (
                    <></>
                  )}
                  {error && (
                    <ErrorBox
                      error={error}
                      color={
                        verificationMessage ===
                        "We have emailed you a link to reset your password"
                          ? "success"
                          : "error"
                      }
                    />
                  )}
                  <Button
                    sx={{
                      borderRadius: "50px",
                      marginTop: "15px",
                      height: "45px",
                      backgroundColor: "#4829B2",
                      ":hover": { bgcolor: "#321d7c" },
                    }}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isDisabled}
                  >
                    Send Email Password Reset Link
                  </Button>
                </StyledForm>
              </div>
            </div>

            <Box mt={5}>
              <Typography variant="body2" color="textSecondary" align="center">
                {"Copyright © "}
                <Link color="inherit">AutoArticle</Link>{" "}
                {new Date().getFullYear()}
                {"."}
              </Typography>
            </Box>
          </PaperContainer>
        </SizeContainer>
      </RootContainer>
      <Footer />
    </div>
  );
};

export default PasswordRecovery;
