import React from "react";
import { Grid, Typography, Button, Box, Link} from "@mui/material";
import { styled } from '@mui/system';
import CurvedLineSVG from "./CurvedLineSVG";
import EastIcon from '@mui/icons-material/East';
import { useTheme } from '@mui/material/styles';

const h1Title = "AI-Powered WordPress Ghost Writer";
const pSubtitle = "Experience Next-Gen Blogging: AI-Enabled WordPress Posts";
const buttonText = "See how it works";
const arrowButton = "Get Started Free";

  const SloganClass = styled(Box)({
    position: "relative",
    zIndex: "2",
    paddingTop: "10%",
    left: "0%",
    width: '100%',
    "@media (max-width: 900px)": {
      width: '100%',
      height: 'auto',
    },
  });

  const ArrowText = styled(Box)({
    display: "flex",
    alignItems: "center",
    marginTop: '5%',
  });


const HeroLeft = () => {
  const theme = useTheme();

    return (
      <Grid container spacing={0}>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ paddingTop: '20%', paddingLeft: '10%' }}>
          <Typography variant='customFont'>
            {h1Title}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ paddingLeft: '10%' }}>
          <CurvedLineSVG />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ paddingLeft: '10%' }}>
          <SloganClass>
            <Typography variant='p' >
              {pSubtitle}
            </Typography>
          </SloganClass>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ paddingLeft: '10%'}}>
          <Button href="/product" variant="primaryButton" sx={{height: "4rem", paddingLeft:'3rem', paddingRight:'3rem', marginTop: '3.75rem'}}>
              {buttonText}
          </Button>
          <ArrowText>
            <Link variant="h5" href="/signup" sx={{color: theme.palette.common.black, paddingLeft:'2.5rem'}} underline="none">
              {arrowButton}
            </Link>
            <EastIcon sx={{ marginLeft: '10px', color: theme.palette.primary.dark }} />
          </ArrowText>
        </Grid>

      </Grid>
    )
}

export default HeroLeft;