import React, { useState, useEffect } from "react";
import Footer from "../components/Shared/Footer";
import Navbar from "../components/NavBar/Navbar";
import ErrorBox from "../components/Shared/ErrorBox";
import { useSignup } from "../hooks/useSignup";
import { HowToReg, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControlLabel,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Checkbox,
  Typography,
  TextField,
  Button,
  Avatar,
  InputAdornment,
  IconButton,
  Link,
} from "@mui/material";
import { styled } from "@mui/system";

const RootContainer = styled(Grid)(({ theme }) => ({
  minHeight: "100vh",
  height: "100%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundColor: theme.palette.common.white,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const SizeContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
  border: `1px solid ${theme.palette.component.componentBackground}`,
  "@media (max-width: 900px)": {
    border: "none",
    borderRadius: "none",
    boxShadow: "none",
  },
}));

const PaperContainer = styled("div")({
  margin: "16px 48px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledForm = styled("form")({
  width: "100%", // Fix IE 11 issue.
  marginTop: "8px",
});

const CenterContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: "0",
  backgroundColor: theme.palette.secondary.main,
}));

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [isLoadingReset, setIsLoadingReset] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsError, setTermsError] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const { signup, error, isLoading } = useSignup();

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!termsAccepted) {
      setTermsError("You must agree to the terms and conditions to sign up.");
      return;
    }
    setIsLoadingReset(true);
    await signup(email, password, repeatPassword);
    setIsLoadingReset(false);
  };

  useEffect(() => {
    if (
      error ===
      "Please confirm your email by clicking the link we just sent to your inbox"
    ) {
      setEmail("");
      setPassword("");
      setRepeatPassword("");
    }
  }, [error]);

  return (
    <div>
      <Navbar />
      <RootContainer container component="main">
        <SizeContainer
          item
          xs={12}
          sm={8}
          md={6}
          lg={5}
          xl={3}
          component={Paper}
          elevation={1}
          square
        >
          <PaperContainer>
            <StyledAvatar>
              <HowToReg />
            </StyledAvatar>
            <br />
            <Typography component="h1" variant="h5">
              Sign Up
            </Typography>
            <StyledForm onSubmit={handleSubmit} noValidate>
              <TextField
                onChange={(event) => setEmail(event.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoFocus
                autoComplete="username"
                value={email}
              />
              <TextField
                onChange={(event) => setPassword(event.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword1 ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword1}
                      >
                        {showPassword1 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                onChange={(event) => setRepeatPassword(event.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="repeatPassword"
                label="Repeat Password"
                type={showPassword2 ? "text" : "password"}
                id="repeatPassword"
                autoComplete="current-password"
                value={repeatPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                      >
                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {termsError && <ErrorBox error={termsError} color={"error"} />}
              <FormControlLabel
                control={
                  <Checkbox
                    value="agree"
                    color="primary"
                    onChange={(e) => {
                      setTermsAccepted(e.target.checked);
                      if (e.target.checked) {
                        setTermsError("");
                      }
                    }}
                  />
                }
                label="I agree to the terms and conditions"
              />
              {isLoadingReset ? (
                <CenterContainer>
                  <CircularProgress />
                </CenterContainer>
              ) : (
                <></>
              )}

              {error && (
                <ErrorBox
                  error={error}
                  color={
                    error ===
                    "Please confirm your email by clicking the link we just sent to your inbox"
                      ? "success"
                      : "error"
                  }
                />
              )}
              {error ===
                "Please confirm your email by clicking the link we just sent to your inbox" && (
                <Link underline="hover" href="/confirmation">
                  <Typography
                    variant="subtitle1"
                    sx={{ padding: "10px 0px 10px 0px" }}
                  >
                    Didn't recieve an email? Click here to resend email
                  </Typography>
                </Link>
              )}

              <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                <Button
                  sx={{
                    borderRadius: "50px",
                    height: "45px",
                    backgroundColor: "#4829B2",
                    ":hover": { bgcolor: "#321d7c" },
                  }}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                >
                  Sign Up
                </Button>
                <Divider sx={{ padding: "15px" }}>or</Divider>
                <Button
                  sx={{
                    borderColor: "#4829B2",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderRadius: "50px",
                    height: "45px",
                    color: "#4829B2",
                    backgroundColor: "#ffffff",
                    ":hover": { bgcolor: "#f8f7fc" },
                    "&.Mui-disabled": {
                      borderColor: "#ffffff",
                    },
                  }}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  href="/login"
                >
                  Login
                </Button>
              </div>
            </StyledForm>
          </PaperContainer>
        </SizeContainer>
      </RootContainer>

      <Footer />
    </div>
  );
};

export default Signup;
