import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { TopicsContextProvider } from "./context/TopicContext";
import { UsersContextProvider } from "./context/UserContext";
import { WpConfigsContextProvider } from "./context/WpConfigContext";
import { AiConfigsContextProvider } from "./context/AiConfigContext";
import { AuthContextProvider } from "./context/AuthContext";
import { ActiveTopicsContextProvider } from "./context/ActiveTopicContext";


createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthContextProvider>
        <TopicsContextProvider>
          <ActiveTopicsContextProvider>
            <WpConfigsContextProvider>
              <AiConfigsContextProvider>
                <UsersContextProvider>
                  <App />
                </UsersContextProvider>
              </AiConfigsContextProvider>
            </WpConfigsContextProvider>
          </ActiveTopicsContextProvider>
        </TopicsContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);