import React from 'react';
import { Box, Link, Typography, Divider } from '@mui/material';
import HomeNavDashButton from './HomeNavDashButton';
import HomeNavNoUserButtons from './HomeNavNoUserButtons';
import Footer from '../Shared/Footer';

const DrawerContent = ({ user, toggleDrawer, theme }) => {
  return (
    <div>
    <Box
      sx={{
        height: '70vh',
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        overflow: 'auto',
        backgroundColor: theme.palette.component.componentBackground,
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      { user ? (
        <HomeNavDashButton mobileView={true}/>
      ) : (
        <HomeNavNoUserButtons mobileView={true}/>
      )}
      <Box sx={{ border: `1px solid ${theme.palette.component.borderColor}`, borderRadius: '10px', m: 1,  backgroundColor: theme.palette.common.white, marginTop: '40px'}}>
        <Link href="/product" sx={{ p: 1, textDecoration: 'none', mr: 3, ml: 12}}>
          <Typography variant="body1" align='center'>
            Product
          </Typography>
        </Link>
        <Divider />
        <Link href="/pricing" color="inherit" sx={{ p: 1, textDecoration: 'none', mr: 3 }}>
          <Typography variant="body1" align='center'>
            Pricing
          </Typography>
        </Link>
        <Divider />
        <Link href="/blog" color="inherit" sx={{ p: 1, textDecoration: 'none', mr: 3 }}>
          <Typography variant="body1" align='center'>
            Blog
          </Typography>
        </Link>
        <Divider />
        <Link href="/user_manual" color="inherit" sx={{ p: 1, textDecoration: 'none', mr: 3 }}>
          <Typography variant="body1" align='center'>
            Docs
          </Typography>
        </Link>
        {/* <Divider />
        <Link href="/careers" color="inherit" sx={{ p: 1, textDecoration: 'none', mr: 3 }}>
          <NavText variant="body1" align='center'>
            Careers
          </NavText>
        </Link>
        <Link href="/contact" color="inherit" sx={{ p: 1, textDecoration: 'none', mr: 3 }}>
          <NavText variant="body1" align='center'>
            Contact
          </NavText>
        </Link> */}
      </Box>
    </Box>

    <Footer />

    </div>
  );
};

export default DrawerContent;