import { ActiveTopicsContext } from "../../context/ActiveTopicContext";
import { useContext } from "react"

export const useActiveTopicContext = () => {
  const context = useContext(ActiveTopicsContext)

  if(!context) {
    throw Error('useActiveTopicsContext must be used inside an ActiveTopicsContextProvider')
  }

  return context
}