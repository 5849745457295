import { styled } from "@mui/system";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const CustomImage = styled("img")(({ theme }) => ({
  width: "100%",
  border: `1px solid ${theme.palette.component.borderColor}`,
  borderRadius: "10px",
  marginTop: "20px",
  marginBottom: "20px",
  "@media (max-width: 960px)": {
    width: "100%",
  },
}));

function ImageContainer({ article }) {
  const theme = useTheme();

  return (
    <div sx={{ display: "flex", justifyContent: "center" }}>
      {article.article.blogCoverImage !== null ? (
        <CustomImage
          src={
            process.env.REACT_APP_SERVER_URL +
            "/" +
            article.article.blogCoverImage
          }
          alt={article.article.blogTitle}
        />
      ) : (
        <Typography
          variant="subtitle1"
          sx={{
            width: "100%",
            textAlign: "center",
            paddingTop: "56.25%",
            paddingBottom: "56.24%",
            marginTop: "20px",
            marginBottom: "20px",
            border: `1px solid ${theme.palette.component.borderColor}`,
            borderRadius: "10px",
            "@media (max-width: 960px)": {
              width: "100%",
            },
          }}
        >
          {" "}
          <b>Article Type:</b> <br /> {article.article.articleType}{" "}
        </Typography>
      )}
    </div>
  );
}

export default ImageContainer;
