import React from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import ShareButton from "./ShareButton";

const GridContainerMain = styled("div")({
  padding: "0 31% 100px 31%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  "& img": {
    maxWidth: "100%",
    height: "auto",
  },
  "@media (max-width: 1600px)": {
    paddingLeft: "20%",
    paddingRight: "20%",
  },
  "@media (max-width: 960px)": {
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  "@media (max-width: 600px)": {
    paddingLeft: "10%",
    paddingRight: "7%",
  },
});

const LogoImage = styled("img")({
  width: "30px",
  height: "30px",
  marginTop: "10px",
});

const LogoContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
});

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = new Date(dateString).toLocaleDateString(
    "en-US",
    options
  );
  return formattedDate;
};

const ArticleView = ({ blogArticleDetails }) => {
  const formattedCreatedAt = formatDate(blogArticleDetails.createdAt);

  return (
    <div>
      <Grid container>
        <GridContainerMain>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h1" sx={{ padding: "30px 0 30px 0" }}>
              {blogArticleDetails.blogTitle}
            </Typography>

            <LogoContainer>
              <LogoImage
                src={
                  process.env.REACT_APP_CLIENT_URL +
                  "/images/AutoArticle_Logo.png"
                }
                alt="Logo"
              />
              <Typography
                variant="subtitle1"
                sx={{ paddingLeft: "17px", flex: 1 }}
              >
                {" "}
                {blogArticleDetails.blogAuthor} — {formattedCreatedAt}
              </Typography>
              <ShareButton />
            </LogoContainer>

            <Divider sx={{ padding: "5px" }} />
            <div
              dangerouslySetInnerHTML={{
                __html: blogArticleDetails.blogContent,
              }}
            />
          </Grid>
        </GridContainerMain>
      </Grid>
    </div>
  );
};

export default ArticleView;
