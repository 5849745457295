import { useEffect, React, useState } from "react";
import { useAuthContext } from "../hooks/useContext/useAuthContext";
import { useActiveTopicContext } from "../hooks/useContext/useActiveTopicContext";
import DashboardNavBar from "../components/NavBar/DashboardNavBar";
import Sidebar from "../components/Shared/Sidebar";
import Footer from "../components/Shared/Footer";
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/system';
import { Box } from '@mui/material';

const GridContainerMain = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.background,
  minHeight: "100vh",
  paddingLeft: "140px",
  paddingRight: "80px",
  paddingTop: "70px",
  marginRight: 'auto',
  paddingBottom: "50px",
  "@media (max-width: 960px)": {
    paddingLeft: "90px",
    paddingRight: "15px",
    paddingTop: "35px",
    marginRight: 'auto',
  },
}));


const Articles = ({socket}) => {
  const { user } = useAuthContext();
  const [rows, setRows] = useState([]);
  const { filteredTopics, activeTopicsDispatch } = useActiveTopicContext();

  //initial fetch topics
  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/topics", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        const json = await response.json();
        if (response.ok) {
          activeTopicsDispatch({ type: "SET_ACTIVE_TOPICS", payload: json });
        } else {
          window.location.reload();
        }
      } catch (error) {
        console.error("Error fetching topics:", error);
      }
    };

    if (user) {
      fetchTopics();
    }
  }, [activeTopicsDispatch, user]);



  //set up socket rooms for active topics
  useEffect(() => {
    // Function to join a socket room for a given topic ID
    const joinSocketRoom = (roomId) => {
      socket.emit('joinRoom', roomId);
    };
  
    // Function to leave a socket room for a given topic ID
    const leaveSocketRoom = (roomId) => {
      socket.emit('leaveRoom', roomId);
    };
  
    // Join socket rooms on component mount and when filteredTopics change
    filteredTopics &&
    filteredTopics.forEach((topic) => {
      if (topic.runningScript) {
        joinSocketRoom(topic._id);
      }
    });
  
    // Clean up: Leave socket rooms on component unmount
    return () => {
      // Leave all socket rooms when the component unmounts
      filteredTopics &&
        filteredTopics.forEach((topic) => {
          if (topic.runningScript) {
            leaveSocketRoom(topic._id);
          }
        });
    };
  }, [socket, filteredTopics]);

  //initial fetch topics
  useEffect(() => {
    const fetchUserConfigs = async () => {
      const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/user/", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        const updatedRows = [];
        json.forEach((item) => {
         updatedRows.push({
           id: item.link,
           link: item.link,
           title: item.title,
           createdAt: item.createdAt,
         });
       });
       updatedRows.reverse();
       setRows(updatedRows);
      }
    };
  
    if (user) {
      fetchUserConfigs();
    }
  }, [user]);



  //fetch topics on socket event
  useEffect(() => {
    const fetchUserConfigs = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/user/", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        const json = await response.json();
        if (response.ok) {
          const updatedRows = [];
          json.forEach((item) => {
          updatedRows.push({
            id: item.link,
            link: item.link,
            title: item.title,
            createdAt: item.createdAt,
          });
        });
        updatedRows.reverse();
        setRows(updatedRows);
        }
      } catch (error) {
        console.error("Error fetching topics:", error);
      }
    };
   
    //data is the returning url from server
    // const handleTopicId = (data) => {
    //   console.log(`Articles.js useEffect ${data}`);

    const handleTopicId = () => {
      fetchUserConfigs();
    };

    socket.on('topic_id', handleTopicId);
    return () => {
      socket.off('topic_id', handleTopicId);
    };
  }, [user, socket]);



  const columns = [
    { 
      field: 'link',
      headerName: 'Links',
      width: 400, 
      renderCell: (params) => (
        <a href={params.value} target="_blank" rel="noopener noreferrer">
          {params.value}
        </a>
      ),
    },
    { 
      field: 'title',
      headerName: 'Title',
      width: 400, 
      renderCell: (params) => (
        <span>
          {params.value}
        </span>
      ),
    },
    { 
      field: 'createdAt',
      headerName: 'Created At',
      width: 200,
      valueGetter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString() + ', ' + date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
      },
    },
  ];

  return (
    <div>
      <DashboardNavBar />
      <Sidebar />

      <GridContainerMain>
        <div className="Container">
          <div className="Grid">
            {/* <DataGrid rows={usersArticles ? usersArticles : []} columns={columns} /> */}
            <DataGrid rows={rows} columns={columns} />
          </div>
        </div>
      </GridContainerMain>

      <Box style={{ paddingLeft: "64px" }}>
        <Footer />
      </Box>

    </div>
  );
};

export default Articles;