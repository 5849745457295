import { Snackbar } from "@mui/material";
import { useState } from "react";
import ShareIcon from "@mui/icons-material/Share";
import { styled } from "@mui/system";
import Tooltip from "@mui/material/Tooltip";

const StyledShareIcon = styled(ShareIcon)(({ theme }) => ({
  "&:hover": {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}));

const ShareButton = () => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(window.location.toString());
  };

  return (
    <>
      <Tooltip title="Share" placement="top">
        <StyledShareIcon onClick={handleClick} />
      </Tooltip>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="Copied to clipboard"
      />
    </>
  );
};

export default ShareButton;
