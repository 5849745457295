import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import ProductCard from "./ProductCard";
import {
  Build,
  Code,
  Computer,
  BarChart,
  CalendarToday,
  Message,
  Toc,
  Style,
  Psychology,
} from "@mui/icons-material";
import ProductFAQ from "./ProductFAQ";

const iconSize = 30;
const h1Title = "Automate Your WordPress Content with Smart AI Writing";
const subtitle = "We make it easy to create and publish content for your WordPress site";

const features = [
  {
    color: "#00C853",
    headline: "WordPress API Integration",
    text: "Our software seamlessly integrates with the WordPress API, enabling you to effortlessly manage and control your WordPress website directly from our platform. Enjoy a streamlined workflow, easy content publishing, and efficient management of your WordPress site.",
    icon: <Build style={{ fontSize: iconSize }} />,
  },
  {
    color: "#6200EA",
    headline: "Scheduled Publishing",
    text: "With our scheduled publishing feature, you have full control over when your articles go live. Plan ahead, set an interval time and schedule your content to be published automatically. Say goodbye to manual publishing and embrace the convenience of scheduled releases.",
    icon: <CalendarToday style={{ fontSize: iconSize }} />,
  },
  {
    color: "#0091EA",
    headline: "Article Content Creation",
    text: "Generate high-quality and unique article content effortlessly. Our software utilizes advanced AI algorithms to generate original and engaging articles tailored to your specific requirements. Leave the time-consuming task of content creation to our intelligent system while you focus on other aspects of your business.",
    icon: <Message style={{ fontSize: iconSize }} />,
  },
  {
    color: "#d50000",
    headline: "Article Image Integration",
    text: "Enhance the visual appeal of your articles with our article image integration feature. Easily incorporate relevant and eye-catching images into your content to captivate your readers. Save time searching for images separately and have everything conveniently integrated within our software.",
    icon: <Computer style={{ fontSize: iconSize }} />,
  },
  {
    color: "#DD2C00",
    headline: "Article monitoring",
    text: "Stay on top of your published articles with our article monitoring feature. Track the URL and publish/draft date and time, of each article in real-time. Optimize your content strategy, and make data-driven decisions to improve your website's performance.",
    icon: <BarChart style={{ fontSize: iconSize }} />,
  },
  {
    color: "#64DD17",
    headline: "WordPress Category Selection",
    text: "Effortlessly categorize your articles within WordPress using our category selection feature. Choose from a range of predefined categories syndicated from your site to organize your content effectively. Streamline your website's structure and enhance user experience with well-organized article categories.",
    icon: <Style style={{ fontSize: iconSize }} />,
  },
  {
    color: "#304FFE",
    headline: "OpenAI API Integration",
    text: "Leverage the power of the OpenAI API with our seamless integration. Access cutting-edge natural language processing capabilities to enhance the quality, coherence, and fluency of your generated content. Unlock the full potential of AI-driven writing and elevate the standard of your articles.",
    icon: <Psychology style={{ fontSize: iconSize }} />,
  },
  {
    color: "#C51162",
    headline: "Keyword Insertion",
    text: "Optimize your articles for search engines with our keyword insertion feature. Easily incorporate targeted keywords into your content to improve search engine visibility and attract organic traffic. Boost your website's ranking and drive more visitors with strategically placed keywords.",
    icon: <Code style={{ fontSize: iconSize }} />,
  },
  {
    color: "#00B8D4",
    headline: "Subheading Count Selection",
    text: "Tailor your article structure to your specific needs with our subheading count selection feature. Choose the desired number of subheadings to break down your content and improve readability. Create well-structured articles that are visually appealing and easy for readers to navigate.",
    icon: <Toc style={{ fontSize: iconSize }} />,
  },
];

function ProductSection() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box paddingTop="100px" sx={{ textAlign: "center", paddingLeft:'20px', paddingRight: '20px' }}>
          <Typography variant="customFont" gutterBottom>
            {h1Title}
          </Typography>

          <Typography variant="subtitle1" sx={{ padding: "0 10%" }}>
            {subtitle}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          sx={{
            width: "70%",
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
            padding: "150px 0px",
          }}
        >
          {features.map((eachFeature, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={4}
              xl={4}
              key={index}
              sx={{ padding: "15px" }}
            >
              <ProductCard
                Icon={eachFeature.icon}
                color={eachFeature.color}
                headline={eachFeature.headline}
                text={eachFeature.text}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid
          container
          sx={{
            width: "70%",
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
            paddingBottom: "150px",
          }}
        >
        <Grid item xs={12}>
          <ProductFAQ />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ProductSection;
